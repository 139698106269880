import { useState } from 'react'
import styled from 'styled-components/macro'

import { useLanguage } from '@/context/language'
import { supabase } from '@/db/supabase'
import { logError } from '@/utils/error'

interface TranslateHelperParams {
  contentL1?: string
  contentL2?: string
  onNewTranslation: (translation: string) => void
}

export default ({ contentL1, contentL2, onNewTranslation }:TranslateHelperParams) => {

  const { startingLanguage, currentLanguage } = useLanguage()

  const [translation, setTranslation] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [copied, setCopied] = useState(false)

  let sourceLanguage, targetLanguage, sourcePhrase
  if (contentL2) {
    sourceLanguage = currentLanguage
    targetLanguage = startingLanguage
    sourcePhrase = contentL2
  } else if (contentL1) {
    sourceLanguage = startingLanguage
    targetLanguage = currentLanguage
    sourcePhrase = contentL1
  }

  const runTranslation = async () => {
    try {
      if (!sourceLanguage || !targetLanguage) {
        setError('Missing language')
        return
      }
      setLoading(true)
      setCopied(false)

      // only target lang uses EN-US or EN-GB, source is always EN for english
      // https://www.deepl.com/docs-api/translate-text/translate-text/
      const source_lang = sourceLanguage.iso639_1.toUpperCase()
      const target_lang = targetLanguage.iso639_1 === 'en' ? 'EN-US' : targetLanguage.iso639_1.toUpperCase()
      const body = {
        text: sourcePhrase,
        source_lang,
        target_lang,
      }
      console.log('🏃 triggering edge function deepl_translate', body)
      const { data, error } = await supabase.functions.invoke('deepl_translate', {
        body,
      })
      setError(null)
      if (error) throw error
      const cleanedTranslation = data?.translations[0].text
      setTranslation(cleanedTranslation)
      onNewTranslation(cleanedTranslation)
      setLoading(false)
    } catch (error) {
      setError(error.message)
      setLoading(false)
      logError('translate word in reference panel', error, true)
    }
  }

  const copyTranslation = async () => {
    // https://stackoverflow.com/a/52033479/1061063
    navigator.clipboard.writeText(translation)
    setCopied(true)
  }

  if (!sourcePhrase || sourcePhrase.length < 1) return null

  return <TranslateHelperWrapper>
    {
      error ? `Error: ${error}` : 
      loading ? '...' :
      translation ? <>{translation} <button type="button" onClick={copyTranslation}>{copied ? 'Copied' : 'Copy'}</button></> :
      <button type="button" onClick={runTranslation} disabled={!sourcePhrase}>Click to translate "{sourcePhrase}"</button>
    }
  </TranslateHelperWrapper>
}

const TranslateHelperWrapper = styled.div`
  font-size: var(--s);
  margin: -0.5rem 0 0.5rem;
`
