import styled from 'styled-components/macro'

import { useReferencePanel } from '@/context/reference'
import { Badge } from '@/styles/Badge'
import Definable from '@/views/_components/Definable'

export default () => {
  const {
    searchHistory,
    removeSearchHistoryItem,
    clearEntireSearchHistory,
  } = useReferencePanel()

  return <div style={{minHeight: '10rem'}}>
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <h4>Recent searches</h4>
      <button onClick={clearEntireSearchHistory} style={{fontSize: 'var(--s)'}}>Clear all</button>
    </div>
    {searchHistory?.map((item, index) => (
      <SearchHistoryItemWrapper key={item.id+'_'+index}>
        <span>
          <Badge>{item.query_language_id?.iso639_1 || '?'}</Badge>
          <Definable wordString={item.content} language={item.query_language_id} />
        </span>
        <button style={{textDecoration: 'none'}} onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          removeSearchHistoryItem(item.id)
        }}>✕</button>
      </SearchHistoryItemWrapper>
    ))}
  </div>
}

const SearchHistoryItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 0;
  text-decoration: none;
  cursor: pointer;
`
