import moment from 'moment'
import { Link } from 'react-router-dom'

import { useLanguage } from '@/context/language'
import { supabase, useSupabaseQuery } from '@/db/supabase'
import AdminId from '@/styles/AdminId'
import { logError } from '@/utils/error.js'
import SupabaseAdminLink from '@/views/admin/SupabaseAdminLink'
import ErrorPage from '@/views/errors/ErrorPage'

export default () => {
  const { currentLanguage } = useLanguage()

  const query = supabase
    .from('lessons')
    .select('*, current_edit_id(*), created_by(*)')
    .eq('language_id', currentLanguage.id)
    .order('sort_order',  { ascending: true })

  const [lessons, loading, error] = useSupabaseQuery(query, [currentLanguage.id])

  const sortLesson = async (lessonId, direction) => {

    try {
      const currentLessonIndex = lessons.findIndex(l => l.id === lessonId)
      const currentLesson = lessons.find(l => l.id === lessonId)
      const lessonBefore = currentLessonIndex > 0 && lessons[currentLessonIndex - 1]
      const lessonAfter = currentLessonIndex < lessons.length - 1 && lessons[currentLessonIndex + 1]

      const { error: lessonError } = await supabase
        .from('lessons')
        .update({ sort_order: currentLesson.sort_order + direction })
        .eq('id', lessonId)
      if (lessonError) throw new Error(`Could not update lesson ${lessonId} - ${lessonError.message}`)

      const lessonToChange = direction === -1 ? lessonBefore : lessonAfter
      if (!lessonToChange) return

      const { error: lessonToChangeError } = await supabase
        .from('lessons')
        .update({ sort_order: currentLesson.sort_order + (-1 * direction) })
        .eq('id', lessonToChange.id)
      if (lessonToChangeError) throw new Error(`Could not update lesson ${lessonId} - ${lessonError.message}`)

      location.reload()

    } catch (error) {
      logError('sort lesson', error)
    }
  }

  const changeLessonLevel = async (lessonId, direction) => {
    try {
      const currentLesson = lessons.find(l => l.id === lessonId)

      const { error: lessonError } = await supabase
        .from('lessons')
        .update({ proficiency_level: currentLesson.proficiency_level + direction })
        .eq('id', lessonId)
      if (lessonError) throw new Error(`Could not update lesson ${lessonId} - ${lessonError.message}`)

      location.reload()

    } catch (error) {
      logError('change lesson proficiency_level', error)
    }
  }

  if (error) return <ErrorPage error={error} />
  if (loading) return <div>loading...</div>
  if (!lessons || lessons.length <= 0) return <div>no lessons</div>

  return <>
    <h1>Lessons</h1>
    {lessons.length} lesson{lessons.length === 1 ? '' : 's'}

    <hr />

    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Title</th>
          <th>Title L2</th>
          <th>Slug</th>
          <th>Level</th>
          <th>Sort order</th>
          <th>Topics</th>
          <th>Phrase count</th>
          <th>Chars</th>
          <th>Vocab lines</th>
          <th>License</th>
          <th>Created</th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          lessons.map(lesson => {
            return <tr key={lesson.id}>
              <td>
                <AdminId>{lesson.id}</AdminId>
              </td>
              <td>
                <Link to={`/${currentLanguage.slug_eng}/lessons/${lesson.random_slug}`}>{lesson.title_l1}</Link>
              </td>
              <td>
                {lesson.title_l2}
              </td>
              <td>
                {lesson.random_slug}
              </td>
              <td>
                <span style={{display: 'flex'}}>
                  <span>{lesson.proficiency_level}</span>
                  <button onClick={() => changeLessonLevel(lesson.id,  1)}>+</button>
                  <button onClick={() => changeLessonLevel(lesson.id, -1)}>-</button>
                </span>
              </td>
              <td>
                <span style={{display: 'flex'}}>
                  <span>{lesson.sort_order}</span>
                  <button onClick={() => sortLesson(lesson.id, -1)}>↑</button>
                  <button onClick={() => sortLesson(lesson.id,  1)}>↓</button>
                </span>
              </td>
              <td>
                {lesson.current_edit_id?.topics?.join(', ') || '-'}
              </td>
              <td>
                {lesson.current_edit_id?.phrase_slugs_l2?.length || 0}
              </td>
              <td>
                {lesson.current_edit_id?.content_l1?.length || 0}
              </td>
              <td>
                {lesson.current_edit_id?.vocab_l1?.split('\n').length || 0}
              </td>
              <td>
                {lesson.current_edit_id?.license || 'No license'}
              </td>
              <td>
                { lesson.created_by?.username ?
                  <Link to={`/u/${lesson.created_by.username}`}>{lesson.created_by.username}</Link> :
                  '❌ no username'
                }
              </td>
              <td>
                {moment(lesson.created_at).fromNow()}
              </td>
              <td>
                <Link to={`/${currentLanguage.slug_eng}/lessons/${lesson.random_slug}/edit`}>Edit</Link>
              </td>
              <td>
                <Link to={`/${currentLanguage.slug_eng}/lessons/${lesson.random_slug}/history`}>History</Link>
              </td>
              <td>
                <SupabaseAdminLink table="lessons" id={lesson.id} />
              </td>
            </tr>
          })
        }
      </tbody>
    </table>

  </>
}
