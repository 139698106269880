import Icon from '@/styles/Icon'
import * as Diff from 'diff'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactTextareaAutosize from 'react-textarea-autosize'
import styled from 'styled-components/macro'

import Licenses from '@/consts/licenses'
import { useLanguage } from '@/context/language'
import { useUser } from '@/context/user'
import { supabase, useSupabaseQuery } from '@/db/supabase'
import { BreadcrumbItem, BreadcrumbSeparator, BreadcrumbWrapper } from '@/styles/Breadcrumbs'
import { CheckboxGroup } from '@/styles/RadioCheckbox'
import ToggleGroup from '@/styles/ToggleGroup'
import { logError } from '@/utils/error.js'
import { parsePhrasesFromLessonContent } from '@/utils/parseMarkdown'
import LessonContent from '@/views/lessons/LessonContent'
import LessonPhraseList from '@/views/lessons/LessonPhraseList'
import EditorOnboarding from '@/views/onboarding/EditorOnboarding.js'

// TODO - users can publish lessons!

export default () => {
  const { userId, userProfile, isAdmin } = useUser()
  const { lessonSlug } = useParams()
  const { currentLanguage } = useLanguage()

  const random_slug = lessonSlug.split('-').pop()

  // lesson attrs
  const [titleL1, setTitleL1] = useState('')
  const [titleL2, setTitleL2] = useState('')
  const [descriptionL1, setDescriptionL1] = useState('')
  const [descriptionL2, setDescriptionL2] = useState('')
  const [level, setLevel] = useState(0)
  const [sortOrder, setSortOrder] = useState(0)

  // lesson edit attrs
  const [topics, setTopics] = useState([])
  const [attributions, setAttributions] = useState([])
  const [content, setContent] = useState('')
  const [vocab, setVocab] = useState('')
  const [keyPhrases, setKeyPhrases] = useState('')
  const [license, setLicense] = useState(Licenses[0].id)
  
  // form state
  const [currentTab, setCurrentTab] = useState('info')
  const [saving, setSaving] = useState(false)
  const navigate = useNavigate()

  const lessonQuery = supabase
    .from('lessons')
    .select('*, current_edit_id(*)')
    .eq('random_slug', random_slug)
    .single()
  const [lesson, lessonLoading, lessonError] = useSupabaseQuery(lessonQuery, [random_slug])

  const topicsQuery = supabase
    .from('topics')
    .select()
  const [topicsList, topicsLoading, topicsError] = useSupabaseQuery(topicsQuery, [random_slug])

  const lessonEdit = lesson?.current_edit_id

  const diffContent = lessonEdit && content && Diff.diffLines(lessonEdit.content_l1, content) 
  const diffVocab = lessonEdit && vocab && vocab.length && Diff.diffLines(lessonEdit.vocab_l1 || '', vocab) 

  // all this crap should go away when we start using a backend
  useEffect(() => {
    if (lesson) {
      setTitleL1(lesson.title_l1)
      setTitleL2(lesson.title_l2)
      setDescriptionL1(lesson.description_l1)
      setDescriptionL2(lesson.description_l2)
      setLevel(lesson.proficiency_level)
      setSortOrder(lesson.sort_order)

      setContent(!lesson.current_edit_id ? '' : lesson.current_edit_id.content_l1)
      setVocab(!lesson.current_edit_id ? '' : lesson.current_edit_id.vocab_l1 || '')
      setKeyPhrases(!lesson.current_edit_id ? '' : lesson.current_edit_id.key_phrases_l2 || '')
      setTopics( lesson.current_edit_id?.topics || [])
      setAttributions( lesson.current_edit_id?.attributions || [])
      setLicense( lesson.current_edit_id?.license || Licenses[0].id)
    }
  }, [lesson])

  const submit = async event => {
    event.preventDefault()

    setSaving(true)
    if (isAdmin || lesson.created_by === userId) {
      try {
        const { error } = await supabase
          .from('lessons')
          .update({
            title_l1: titleL1,
            title_l2: titleL2,
            description_l1: descriptionL1,
            description_l2: descriptionL2,
            proficiency_level: level,
            sort_order: sortOrder,
          })
          .eq('id', lesson.id)
        if (error) throw error
      } catch (error) {
        logError('edit admin lesson', error)
      }
    }

    try {
      const { phrase_slugs_l2, phrase_slugs_l1, num_phrases } = await parsePhrasesFromLessonContent(content)
      const { phrase_slugs_l2: vocab_slugs_l2, phrase_slugs_l1: vocab_slugs_l1, num_phrases: vocab_num_phrases } = await parsePhrasesFromLessonContent(vocab)

      // create a lesson_edit
      const { data: lessonEdit, error: lessonEditError } = await supabase
        .from('lesson_edits')
        .insert([{
          language_id: currentLanguage.id,
          lesson_id: lesson.id,
          content_l1: content,
          vocab_l1: vocab,
          key_phrases_l2: keyPhrases,
          phrase_slugs_l2: [...phrase_slugs_l2, ...vocab_slugs_l2],
          phrase_slugs_l1: [...phrase_slugs_l1, ...vocab_slugs_l1],
          num_phrases: num_phrases + vocab_num_phrases,
          topics,
          attributions,
          license,
          created_by: userId,
        }])
        .select()
      if (lessonEditError) {
        throw lessonEditError
      }

      // update lesson
      const lessonUpdates = {
        current_edit_id: lessonEdit[0].id,
      }
      const { data: newLesson, error: lessonError } = await supabase
        .from('lessons')
        .update(lessonUpdates)
        .eq('id', lesson.id)
        .select()
      if (lessonError) {
        throw lessonError
      }
      // alert('Saved!')
      navigate(`/${currentLanguage.slug_eng}/lessons/${random_slug}`)
    } catch (error) {
      logError('update lesson', error)
    }
    setSaving(false)
  }

  if (lessonLoading || topicsLoading) {
    return 'Loading...'
  }

  if (!lesson || lessonError || topicsError) {
    return 'Error - could not load lesson ' + (topicsError?.message || lessonError?.message)
  }

  if (!userProfile?.has_agreed_to_editor_terms || !userProfile.username) return <EditorOnboarding />

  return <>
    <BreadcrumbWrapper>
      <BreadcrumbItem to={`/${currentLanguage.slug_eng}`}>Home</BreadcrumbItem>
      <BreadcrumbSeparator />
      <BreadcrumbItem to={`/${currentLanguage.slug_eng}/lessons/${random_slug}`}>{lesson?.title_l1}</BreadcrumbItem>
      <BreadcrumbSeparator />
      Edit
    </BreadcrumbWrapper>

    <h1>Edit lesson: {lesson.title_l1}</h1>
    <hr />

    <form onSubmit={submit}>
      <LessonThreeColumnLayout>
        <div>
          <StickySidebarWrapper>
            <ToggleGroup
              $vertical
              label="Lesson tab"
              items={[
                { value: 'info', label: 'Info' },
                { value: 'content', label: 'Content' },
                { value: 'vocab', label: 'Vocab' },
                { value: 'preview', label: 'Preview' },
                { value: 'diff', label: 'Changes' },
              ]}
              value={currentTab}
              setValue={setCurrentTab}
            />
          </StickySidebarWrapper>
        </div>
        <div>
          {
          currentTab === 'info' ?
          <>

            { lesson && (isAdmin || lesson.createdBy === userId) && <>
              <label htmlFor="titleL1">Title</label>
              <input
                id="titleL1"
                type="text"
                value={titleL1}
                placeholder=""
                onChange={e => setTitleL1(e.target.value)}
                required
              />

              <label htmlFor="titleL2">{currentLanguage.name_eng} title (optional)</label>
              <input
                id="titleL2"
                type="text"
                value={titleL2}
                placeholder=""
                onChange={e => setTitleL2(e.target.value)}
              />

              <label htmlFor="descriptionL1">Description</label>
              <ReactTextareaAutosize
                id="descriptionL1"
                type="text"
                value={descriptionL1}
                placeholder=""
                onChange={e => setDescriptionL1(e.target.value)}
                required
              />

              <label htmlFor="descriptionL2">{currentLanguage.name_eng} description (optional)</label>
              <ReactTextareaAutosize
                id="descriptionL2"
                type="text"
                value={descriptionL2}
                placeholder=""
                onChange={e => setDescriptionL2(e.target.value)}
              />

              <label htmlFor="keyPhrases">Key phrases (optional)</label>
              <input
                id="keyPhrases"
                type="text"
                value={keyPhrases}
                placeholder=""
                onChange={e => setKeyPhrases(e.target.value)}
              />

              <label htmlFor="sortOrder">Sort order</label>
              <input
                id="sortOrder"
                type="number"
                value={sortOrder}
                placeholder="Sort order"
                onChange={e => setSortOrder(e.target.value)}
                required
              />

              <label htmlFor="level">Level</label>
              <input
                id="level"
                type="number"
                value={level}
                min="0"
                max="7"
                placeholder="Level"
                onChange={e => setLevel(e.target.value)}
                required
              />
              <br />
            </> }

            <hr />

            <label>License</label>
            <select
              value={license?.id}
              onChange={e => setLicense(e.target.value)}
              required
              disabled
            >
              {Licenses.map(license => {
                return <option key={license.id} value={license.id}>{license.name}</option>
              })}
            </select>

            <label>Attribution</label>
            {attributions?.map((alt, index) => {
              return <div key={index} style={{display: 'flex'}}>
                <ReactTextareaAutosize
                  style={{height: '50px'}}
                  type="text"
                  value={alt}
                  minRows={1}
                  onChange={e => {
                    const newArray = [...attributions]
                    newArray[index] = e.target.value
                    setAttributions(newArray)
                  }}
                />
                {/* https://stackoverflow.com/a/47024021 */}
                <button type="button" className="button button-tertiary" onClick={() => setAttributions([...attributions.slice(0, index), ...attributions.slice(index + 1)])}>
                  <Icon name="delete" />
                </button>
              </div>
            })}
            <button type="button" onClick={() => setAttributions([...attributions, ''])}>Add attribution</button>

            <br />
            <br />

            <label>Topics</label>
            <CheckboxGroup
              groupName="topics"
              values={topics}
              setValues={setTopics}
              options={!topicsList ? [] : topicsList.map(topic => ({id: topic.name_slug, title: topic.title_eng}))}
            />
          </>
          : currentTab === 'content' ?
          <>
            <EditTextarea
              id="content"
              type="text"
              value={content}
              placeholder=""
              minRows={20}
              onChange={e => setContent(e.target.value)}
              required
            />
            <a href="/support#formatting-help" target="_blank">Formatting help</a>
          </>
          : currentTab === 'vocab' ?
          <>
            <EditTextarea
              id="vocab"
              type="text"
              value={vocab}
              placeholder=""
              minRows={20}
              onChange={e => setVocab(e.target.value)}
              required
            />
            <a href="/support#formatting-help" target="_blank">Formatting help</a>
          </>
          : currentTab === 'preview' ?
          <>
            <div style={{padding: '.5rem 1rem', background: "var(--badge)", margin: '0 0 1rem'}}>
              This is how your lesson will look when published
            </div>
            <LessonContent content={content} />
            <br />
            <hr />
            <br />
            <h2 style={{margin: 0}}>Phrases</h2>
            <hr />
            <LessonPhraseList lesson={lesson} />
          </>
          : currentTab === 'diff' ?
          <div style={{fontFamily: 'monospace', whiteSpace: 'break-spaces'}}>
            {diffContent?.map((part, i) => <div key={i}>
              {
                part.added ? <span style={{ color: 'green', background: 'hsla(120, 60%, 60%, .1)'}}>{part.value}</span> :
                part.removed ? <span style={{ color: 'red', background: 'hsla(0, 80%, 60%, .1)'}}>{part.value}</span> :
                part.value
              }
            </div>)}
            <br />
            {/* ERRORS */}
            {/* <hr />
            <br />
            <h3>Phrases</h3>
            {diffVocab?.map((part, i) => <div key={i}>
              {
                part.added ? <span style={{ color: 'green', background: 'hsla(120, 60%, 60%, .1)'}}>{part.value}</span> :
                part.removed ? <span style={{ color: 'red', background: 'hsla(0, 80%, 60%, .1)'}}>{part.value}</span> :
                part.value
              }
            </div>)} */}
          </div>
          : null
          }
        </div>
        <div>
          <StickySidebarWrapper>
            <button
              className="button button-full-width"
              type="submit"
              disabled={saving}
            >
              {saving ? 'Saving...' : 'Save lesson'}
            </button>
          </StickySidebarWrapper>
        </div>
      </LessonThreeColumnLayout>
    </form>
  </>
}

const EditTextarea = styled(ReactTextareaAutosize)`
  font-family: monospace;
  width: 100%;
`
const StickySidebarWrapper = styled.div`
  position: sticky;
  top: 2rem;
  z-index: 200;
`
const LessonThreeColumnLayout = styled.div`
  display: grid;
  grid-template-columns: 200px auto 200px;
  grid-gap: 4rem;
  /* margin-bottom: 2rem; */
`
