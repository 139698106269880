import * as Popover from '@radix-ui/react-popover'
import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { useUser } from '@/context/user'
import { supabase } from '@/db/supabase'
import useMediaQuery from '@/hooks/useMediaQuery'
import { logError } from '@/utils/error'

const DISABLE_ONBOARDING = true

export default ({
  overlay = false,
  delaySeconds = .5,
  align = 'center',
  side = 'bottom',
  minWidth = 'auto',
  popoverContent,
  hide = false,
  flagName,
  buttonText = 'OK',
  children,
}) => {
  const isDesktop = useMediaQuery('(min-width: 800px)')

  const { userId, userProfile } = useUser()
  const [visible, setVisible] = useState(false)
  const [isClosing, setIsClosing] = useState(false)

  const onClose = async () => {
    console.log('on close')
    setIsClosing(true)
    try {
      if (!userId) return

      const { error } = await supabase
        .from('user_profiles')
        .update({
          [flagName]: true,
        })
        .eq('id', userId)
      if (error) throw error
    } catch (error) {
      logError('finish tour', error)
    }
  }

  const isOpen = !DISABLE_ONBOARDING && userProfile && !userProfile[flagName] && !isClosing && !hide

  useEffect(() => {
    setTimeout(() => setVisible(true), delaySeconds * 1000)
  }, [])

  return (
    <Popover.Root open={isOpen} modal={overlay} onOpenChange={isOpen => !isOpen && onClose()}>
      <OnboardingTourPopoverAnchor $isOpen={isOpen} $padded={overlay} onClick={event => event.stopPropagation()}>
        {children}
      </OnboardingTourPopoverAnchor>
      {isOpen && overlay && <OnboardingTourOverlay $visible={visible} />}
      <Popover.Portal>
        <OnboardingTourPopoverContent
          align={align}
          side={side}
          style={{ minWidth }}
          $isMobile={!isDesktop}
          $visible={visible}
          onClick={event => event.stopPropagation()}
        >
          {popoverContent}
          <button
            className="button button-primary"
            style={{ background: '#333', outline: 'none', border: '1px solid' }}
            onClick={onClose}
          >
            {buttonText}
          </button>
          <OnboardingTourPopoverClose>✕</OnboardingTourPopoverClose>
          <OnboardingTourPopoverArrow />
        </OnboardingTourPopoverContent>
      </Popover.Portal>
    </Popover.Root>
  )
}

const OnboardingTourOverlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: hsl(0deg 0% 100% / 0.95);
  background: hsl(0deg 0% 95% / 0.95);
  z-index: 1000;
  backdrop-filter: blur(1px);
  opacity: ${p => p.$visible ? 1 : 0};
  transition: all 0.4s ease-out;
`
const OnboardingTourPopoverAnchor = styled(Popover.Anchor)`
  position: ${p => p.$isOpen && 'relative'};
  background: ${p => p.$isOpen && 'var(--bg)'};
  padding: ${p => p.$isOpen && p.$padded && '1.5rem 2rem'};
  margin: ${p => p.$isOpen && p.$padded && '-1.5rem -2rem'};
  z-index: ${p => p.$isOpen && 1100};
`
const OnboardingTourPopoverContent = styled(Popover.Content)`
  position: relative;
  z-index: 1100;
  background: #222;
  color: #ddd;
  font-weight: 500;
  padding: 1.5rem 2rem 1.5rem 1.5rem;
  width: ${p => p.$isMobile && '100vw'};
  box-shadow: ${p => p.$isMobile && '0 4px var(--shadow)'};
  opacity: ${p => p.$visible ? 1 : 0};
  transition: all 0.4s ease-out;

  h3 {
    color: white;
  }
  & > *:first-child {
    margin-top: 0;
  }
`
const OnboardingTourPopoverClose = styled(Popover.Close)`
  font-family: var(--font-family-sans);
  height: 2.5rem;
  width: 2.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.8;
  text-decoration: none;

  &:hover {
    opacity: 1;
  }
  &:focus {
    opacity: 1;
  }
`
const OnboardingTourPopoverArrow = styled(Popover.Arrow)`
  fill: var(--text);
`
