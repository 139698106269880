import styled from 'styled-components/macro'

export const Badge = styled.span`
  padding: 2px 5px;
  margin: 0 0.5rem 0 0;

  font-size: 13px;
  font-weight: 500;

  /* background: ${p => p.$primary ? 'var(--text)' : 'var(--badge)'};
  color: ${p => p.$primary ? 'var(--bg)' : 'var(--text)'}; */
  background: var(--bg);
  color: var(--text);
  border: 1px solid;
  text-shadow: none;

  letter-spacing: 0.03rem;
  text-decoration: none;
  white-space: nowrap;
`
