import * as Accordion from '@radix-ui/react-accordion'
import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { useReferencePanel } from '@/context/reference'
// import DefinitionReference from '@/views/dictionary/DefinitionReference'
import { useLanguage } from '@/context/language'
import { useUser } from '@/context/user'
import Definable from '@/views/_components/Definable'
import PhrasesThatUseWord from '@/views/_components/PhrasesThatUseWord'
import RepeatableMic from '@/views/_components/RepeatableMic'
import SpeakWord from '@/views/_components/SpeakWord'
import ConjugatePane from '@/views/reference/ConjugatePane'
import PronunciationPane from '@/views/reference/PronunciationPane'
import TranslatePane from '@/views/reference/TranslatePane'
import SaveButton from '@/views/saved/SaveButton'
// import MnemonicsList from '@/views/mnemonics/MnemonicsList'
import { useSoundFx } from '@/context/soundEffects'
import Icon from '@/styles/Icon'
import ExamplesPane from '@/views/reference/ExamplesPane'
import Ngram from '@/views/reference/Ngram'

const MAX_READABLE_STRING_LENGTH = 50

export default () => {
  const {
    queryText,
    queryLanguage,
    setQuery,
  } = useReferencePanel()

  const { userProfile } = useUser()
  const { currentLanguage, startingLanguage } = useLanguage()
  const { repeatOn } = useSoundFx()

  const [ accordionItem, setAccordionItem ] = useState(null)

  const isOnboarding = !userProfile?.has_completed_dashboard_onboarding || !userProfile

  const referencePanelComponents = [
    // {
    //   value: 'definition',
    //   label: 'Full definition',
    //   component: <DefinitionReference wordString={queryText} key={'definition_'+queryText} />,
    //   test: () => !queryText.split(' ').length <= 1,
    // },
    // {
    //   value: 'mnemonics',
    //   label: 'Mnemonics',
    //   component: <>
    //     <MnemonicsList wordString={queryText} key={'mnemonics_'+queryText} />
    //   </>,
    // },
    {
      value: 'pronunciation',
      label: 'Pronunciation',
      component: <PronunciationPane wordString={queryText} key={'pronunciation_'+queryText} />,
    },
    {
      value: 'conjugations',
      label: 'Conjugations',
      component: <ConjugatePane wordString={queryText} key={'conjugate_'+queryText} />,
      test: () => queryText.split(' ').length <= 1,
    },
    {
      value: 'examples',
      label: 'Examples',
      component: <ExamplesPane wordString={queryText} key={'examples_'+queryText} />,
      test: () => queryText.split(' ').length <= 2,
    },
    {
      value: 'phrases',
      label: 'Related phrases',
      component: <PhrasesThatUseWord wordString={queryText} key={'phrases_'+queryText} />,
      test: () => !isOnboarding,
    },
    {
      value: 'frequency',
      label: 'Frequency',
      component: <Ngram wordString={queryText} key={'frequency_'+queryText} />,
    },
  ]

  useEffect(() => {
    setAccordionItem(null)
  }, [queryText])

  const queryLangIsL2 = queryLanguage.id === currentLanguage.id
  const nonQueryLanguage = queryLangIsL2 ? startingLanguage : currentLanguage

  return <ResultWrapper>
    <button
      className="button button-tertiary"
      style={{margin: '0 -1rem'}}
      onClick={ ()=> setQuery({text: '', language: queryLanguage}) }
    >
      <Icon name="arrow_back" />
      {' '}
      {/* Back */}
    </button>
    <h2 style={{marginTop: '1rem'}}>
      <Definable wordString={queryText} splitByWord />
      {' '}
      {queryLangIsL2 && <SpeakWord
        wordString={queryText}
        autoPlay={repeatOn && queryLangIsL2 && queryText?.length < MAX_READABLE_STRING_LENGTH}
      />}
      <RepeatableMic correctAnswer={queryText} />
      <SaveButton wordString={queryText} key={'speak'+queryText} />
    </h2>
    <TranslatePane wordString={queryText} key={'translate'+queryText} queryLanguage={queryLanguage} />
    <div style={{height: '.5rem'}} />
    <ReferenceAccordionRoot value={accordionItem} onValueChange={setAccordionItem} type="single" collapsible="true">
      {referencePanelComponents.map(({value, label, component, test}) => {
        if (test && !test()) return null
        return <ReferenceAccordionItem key={value} value={value} disabled={!queryLangIsL2}>
          <ReferenceAccordionHeader style={{opacity: !queryLangIsL2 && '.5'}}>
            <ReferenceAccordionTrigger>
              <span style={{fontSize: 'var(--xs)'}}>▶</span>
              {label}
            </ReferenceAccordionTrigger>
          </ReferenceAccordionHeader>
          <ReferenceAccordionContent>
            {queryLangIsL2 && component}
          </ReferenceAccordionContent>
        </ReferenceAccordionItem>
      })}
    </ReferenceAccordionRoot>
    <div style={{padding: '.5rem 0 0 1.5rem'}}>
      <a
        href={`https://translate.google.com/?sl=${queryLanguage.iso639_1}&tl=${nonQueryLanguage.iso639_1}&text=${queryText}&op=translate`}
        target="_blank"
      >
        Open in Google Translate ↗
      </a>
      <br />
      <a
        href={`https://en.wiktionary.org/wiki/${queryText}#${queryLanguage.name_eng}`}
        target="_blank"
      >
        Open in Wiktionary ↗
      </a>
      <br />
      <a
        href={`https://forvo.com/word/${queryText}/#${queryLanguage.iso639_1}`}
        target="_blank"
      >
        Pronounce in Forvo ↗
      </a>
    </div>
  </ResultWrapper>
}

const ResultWrapper = styled.div`
  padding-bottom: 1rem;
`
const ReferenceAccordionRoot = styled(Accordion.Root)`
`
const ReferenceAccordionItem = styled(Accordion.Item)`
  margin: 0 0 .5rem;
`
const ReferenceAccordionHeader = styled(Accordion.Header)`
  margin: 0;
  font-size: var(--m);
  font-weight: 500;

  // triangles
  span {
    display: inline-block;
    width: 1rem;
    text-align: center;
    margin-right: .5rem;
    transition: all .2s ease;
  }
  &[data-state='open'] span {
    transform: rotate(90deg);
  }
  &[data-state='closed'] span {
    transform: rotate(0deg);
  }
`
const ReferenceAccordionTrigger = styled(Accordion.Trigger)`
  width: 100%;
  padding: .5rem 0 0;
  text-decoration: none;
  font-weight: 500;
  font-size: var(--m);
`
const ReferenceAccordionContent = styled(Accordion.Content)`
  overflow: hidden;
  margin: .5rem 0;
  padding: 0 0 0 1.5rem;

  &[data-state='open'] {
    animation: slideDown 200ms ease-in-out;
  }
  &[data-state='closed'] {
    animation: slideUp 200ms ease-in-out;
  }
`
