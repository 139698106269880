import Popover from '@/styles/Popover'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components/macro'

import ipaSounds from '@/consts/ipa_sounds'
import PronouncableBlock from '@/views/_components/PronouncableBlock'

const IGNORE_CHARS = [' ', 'ˈ', 'ˌ', '/', '.', '͡', 'ː']

export default ({ ipa }) => {
  if (!ipa) return null

  // https://chat.openai.com/chat/2b824a9b-95ae-42a2-98d6-a1fc667aa94b
  const sounds = ipa.match(/dz|dʒ|ts|tʃ|\S/g)
    .filter( x => !IGNORE_CHARS.includes(x) )

  if (!sounds || sounds.length < 1) return null

  return <SoundsWrapper>
    {sounds.map( (sound, index) => {
      const soundInfo = ipaSounds[sound]
      return <Popover
        key={sound+index}
        trigger={<div><PronouncableBlock soundInfo={soundInfo} /></div>}
      >
        <div>
          <p style={{fontSize: 'var(--l)'}}>{sound}</p>
          {soundInfo && <>
            <ReactMarkdown children={`As in the english ${soundInfo.examples_eng}`} />
            {/* <p>Sound: {soundInfo.sound}</p> */}
          </>}
        </div>
      </Popover>
    })}
  </SoundsWrapper>
}

export const SoundsWrapper = styled.span`
  display: inline-flex;
  gap: .25rem;
  width: 100%;
  overflow-x: auto;
`
