
import { Header, HeaderFooterLayoutWrapper, Main } from '@/styles/Layout'
import Footer from '@/views/_layout/components/Footer'
import Logo from '@/views/_layout/components/Logo'
import { useEffect } from 'react'

export default ({children}) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [window.location.href])

  return <HeaderFooterLayoutWrapper>

    <Header>
      <nav>
        <Logo to="/" />
      </nav>
      <div>
        {/* keep these divs for the layout */}
      </div>
    </Header>

    <Main>
      {children}
    </Main>

    <Footer />

  </HeaderFooterLayoutWrapper>
}
