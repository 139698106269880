import moment from 'moment'
import { Link } from 'react-router-dom'

import { supabase, useSupabaseQuery } from '@/db/supabase'
import AdminId from '@/styles/AdminId'
import { logError } from '@/utils/error'
import SupabaseAdminLink from '@/views/admin/SupabaseAdminLink'
import ErrorPage from '@/views/errors/ErrorPage'

export default () => {

  const query = supabase
    .from('languages')
    .select('*, language_votes(count)')
    .order('name_eng', { ascending: true })
  const [languages, loading, error] = useSupabaseQuery(query)

  const setPublishStatus = async (languageId, publish_status) => {
    try {
      const { error: languageError } = await supabase
        .from('languages')
        .update({ publish_status })
        .eq('id', languageId)
      if (languageError) throw new Error(`Could not update language ${languageId} - ${languageError.message}`)
      location.reload()
    } catch (error) {
      logError('change language status', error)
    }
  }

  if (error) return <ErrorPage error={error} />
  if (loading) return <div>loading...</div>
  if (!languages || languages.length <= 0) return <div>no languages</div>

  return <>
    <h1>Languages</h1>
    {languages.length} language{languages.length === 1 ? '' : 's'}

    <hr />

    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>ISO 639-1</th>
          <th>ISO 639-3</th>
          <th>Name</th>
          <th>Endonym</th>
          <th>Status</th>
          <th>Votes</th>
          <th>Created</th>
          <th>Updated</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          languages.map(language => {
            return <tr key={language.id}>
              <td>
                <AdminId>{language.id}</AdminId>
              </td>
              <td>
                {language.iso639_1}
              </td>
              <td>
                {language.iso639_3}
              </td>
              <td>
                <Link to={`/${language.slug_eng}`}>
                  {language.name_eng}
                </Link>
              </td>
              <td>
                {language.endonym}
              </td>
              <td>
                <select
                  value={language.publish_status}
                  onChange={e => setPublishStatus(language.id, e.target.value)}
                  style={{width: 'auto'}}
                >
                  <option value="not_started">not started</option>
                  <option value="in_development">🔧 wip</option>
                  <option value="beta_testing">🚧 beta</option>
                  <option value="general_availability">✅ live</option>
                </select>
              </td>
              <td>
                {language.language_votes && language.language_votes[0].count}
              </td>
              <td>
                {moment(language.created_at).fromNow()}
              </td>
              <td>
                {language.updated_at ? moment(language.updated_at).fromNow() : '-'}
              </td>
              <td>
                <Link to={`/${language.slug_eng}`}>View</Link>
              </td>
              <td>
                <SupabaseAdminLink table="languages" id={language.id} />
              </td>
            </tr>
          })
        }
      </tbody>
    </table>

  </>
}
