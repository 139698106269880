// https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary

import { Component } from "react"

import LayoutSimple from "@/views/_layout/LayoutSimple"
import ErrorPage from "@/views/errors/ErrorPage"

export default class ErrorBoundary extends Component {

  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
    }
  }

  // Update state so the next render will show the fallback UI.
  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    }
  }

  componentDidCatch(error: Error, info: any) {
    // We're already logging it in the ErrorPage component
    console.log('caught error boundary error')
  }

  render() {
    if (this.state.hasError) {
      return <LayoutSimple>
        <ErrorPage error={this.state.error} />
      </LayoutSimple>
    }
    return this.props.children
  }
}
