import { useDebug } from '@/context/debug'
import { useUser } from '@/context/user'
import { supabase } from '@/db/supabase'
import { logError } from '@/utils/error'

export default () => {
  const { userId, isAlphaTester } = useUser()
  const { isDebugging, toggleDebugMode } = useDebug()

  async function resetUserOnboarding() {
    try {
      if (!userId) return

      const { error: errorUser } = await supabase
        .from('user_profiles')
        .update({
          has_agreed_to_editor_terms: false,
          has_completed_dashboard_onboarding: false,
          has_completed_reference_onboarding: false,
          has_completed_convo_input_onboarding: false,
          has_completed_convo_message_onboarding: false,
          has_completed_situation_onboarding: false,
        })
        .eq('id', userId)
      if (errorUser) throw errorUser

      const { error: errorUserLang } = await supabase
        .from('user_languages')
        .delete()
        .eq('created_by', userId)
      if (errorUserLang) throw errorUserLang

      location.reload()
    } catch (error) {
      logError('reset admin onboarding flags', error)
    }
  }

  if (!isAlphaTester) return null

  return <>
    <hr />
    <h3>Admin only</h3>
    <button
      type="button"
      onClick={resetUserOnboarding}
    >
      Reset user onboarding
    </button>
    <br />
    <button
      type="button"
      onClick={toggleDebugMode}
    >
      Debug mode is {isDebugging ? 'ON' : 'off'}
    </button>
  </>
}
