import * as ToggleGroup from '@radix-ui/react-toggle-group'
import styled from 'styled-components/macro'

interface ToggleGroupParams {
  label: string
  value: string
  items: {
    label: string
    value: string
  }[]
  setValue: (value: string) => void
  $vertical?: boolean
}

export default ({
  label,
  value,
  items,
  setValue,
  $vertical,
}:ToggleGroupParams) => (
  <ToggleGroupRoot
    type="single"
    value={value}
    onValueChange={value => value && setValue(value)}
    aria-label={label}
    $vertical={$vertical}
    orientation={$vertical ? 'vertical' : 'horizontal'}
  >
    {items.map(item => {
      return (
        <ToggleGroupItem
          key={item.value}
          value={item.value}
          aria-label={item.label}
          $vertical={$vertical}
        >
          {item.label}
        </ToggleGroupItem>
      )
    })}
  </ToggleGroupRoot>
)

const ToggleGroupRoot = styled(ToggleGroup.Root)`
  display: grid;
  grid-template-columns: ${p => p.$vertical ? 'auto' : '1fr 1fr'};
  margin: 0 0 1rem;
  border-style: solid;
  border-width: ${p => p.$vertical ? '1px' : '0 0 1px 0'};
  `

const ToggleGroupItem = styled(ToggleGroup.Item)`
  text-decoration: none;
  text-align: ${p => p.$vertical ? 'left' : 'center'};
  padding: ${p => p.$vertical ? '1rem' : '.5rem'};
  border-style: solid;
  border-width: ${p => p.$vertical ? '0 2px 0 0' : '2px 0'};
  border-color: transparent;

  &[data-state='on'] {
    border-bottom-color: inherit;
    border-right-color: inherit;
    font-weight: 500;
  }
`
