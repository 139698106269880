import styled from 'styled-components/macro'

export default ({ children }) => {
  return <div style={{position: 'relative'}}>
    <FakeContentWrapper>
      <FakeContentImage src="/images/empty-states/fake_lang_bg.jpg" />
      <FakeContentFade />
    </FakeContentWrapper>
    <PromoCoverWrapper>
      <PromoModal>
        {children}
      </PromoModal>
    </PromoCoverWrapper>
  </div>
}

const FakeContentWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  overflow: hidden;
  max-height: 100%;
`
const FakeContentImage = styled.img`
  display: none;

  @media (min-width: 900px) {
    display: block;
    width: 100%;
  }
`
const FakeContentFade = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  background: linear-gradient(hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 1));
  `
const PromoCoverWrapper = styled.div`
  position: relative;
  z-index: 200;
`
const PromoModal = styled.div`
  max-width: 50rem;
  background: var(--bg);

  @media (min-width: 900px) {
    margin: 0 auto;
    padding: 2rem 4rem;
    border: 1px solid;
    box-shadow: 3px 3px var(--shadow);
  }
`
