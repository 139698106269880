import { Link } from 'react-router-dom'

import { useLanguage } from '@/context/language'
import Icon from '@/styles/Icon'
import Popover from '@/styles/Popover'

export default () => {
  const { currentLanguage, userLanguages } = useLanguage()

  if (userLanguages?.length < 1 || !currentLanguage?.id) return null

  const triggerComponent = <button className="button button-small">
    {currentLanguage?.name_eng || 'Choose language'}
    <Icon name="expand_more" size="x-small" />
  </button>
  return <Popover trigger={triggerComponent} align="center">
    {userLanguages?.map( userLanguage => {
      const { id, name_eng, slug_eng } = userLanguage.language_id
      return <Link key={id} to={`/${slug_eng}`} style={{display: 'block'}}>{name_eng}</Link>
    })}
    <br />
    <Link to={`/languages`}>Browse all languages...</Link>
  </Popover>
}
