import styled from 'styled-components/macro'

import { supabase, useSupabaseQuery } from '@/db/supabase'

export default ({ selectedTopics, setSelectedTopics }) => {
  const topicsQuery = supabase
    .from('topics')
    // .eq('user_selectable', true)
    .select()
  const [topicsList, topicsLoading, topicsError] = useSupabaseQuery(topicsQuery)

  const toggleTopic = topicSlug => {
    setSelectedTopics(prevTopics => {
      if (prevTopics.includes(topicSlug)) {
        return prevTopics.filter(slug => slug !== topicSlug)
      } else {
        return [...prevTopics, topicSlug]
      }
    })
  }

  if (topicsLoading) return <div>Loading...</div>
  if (topicsError) return <div>Error: {topicsError.message}</div>

  return <TopicsWrapper>
    {topicsList?.map(topic => {
      return <TopicsItem
        key={topic.name_slug}
        onClick={() => toggleTopic(topic.name_slug)}
        $selected={selectedTopics.includes(topic.name_slug)}
      >
        {topic.title_eng}
      </TopicsItem>
    })}
    {selectedTopics.length > 0 && <div><button onClick={() => setSelectedTopics([])}>✕ clear</button></div>}
  </TopicsWrapper>
}

const TopicsWrapper = styled.div`
  margin: 0 0 2rem;
  line-height: 1;
`
const TopicsItem = styled.button`
  text-decoration: none;
  font-size: var(--s);
  display: inline-block;
  padding: .15rem 0;
  margin: 0 .5rem 0 0;
  font-weight: ${({$selected}) => $selected ? 'bold' : 'normal'};

  &:hover {
    text-decoration: underline;
  }
`
