import { useState } from 'react'

import Radio from '@/views/media/Radio'

export default () => {

  const [stationIndex, setStationIndex] = useState(null)

  // https://tunein.com/radio/Stream-RAI-Italy-a34032/
  const stations = [
    {
      name: 'RAI Radio 1',
      url: 'https://icecdn-19d24861e90342cc8decb03c24c8a419.msvdn.net/icecastRelay/S18493106/rCMKqdMFKPBB/icecast',
    },
    {
      name: 'RAI Radio 2',
      url: 'https://icecdn-19d24861e90342cc8decb03c24c8a419.msvdn.net/icecastRelay/S35942484/yp5F67151K92/icecast',
    },
    {
      name: 'RAI Radio 3',
      url: 'https://icecdn-19d24861e90342cc8decb03c24c8a419.msvdn.net/icecastRelay/S56630579/yEbkcBtIoSwd/icecast',
    },

    // 5
    {
      name: 'RAI Radio Classica',
      url: 'https://icecdn-19d24861e90342cc8decb03c24c8a419.msvdn.net/icecastRelay/S53422322/bTdUFhYMnnra/icecast',
    },

    // 7
    {
      name: 'RAI Radio GR Parlamento',
      url: 'https://icecdn-19d24861e90342cc8decb03c24c8a419.msvdn.net/icecastRelay/S32576004/GxPKuXIdZBcL/icecast',
    },
  ]

  console.log('📻 stationIndex', stationIndex)

  return <Radio
    stations={stations}
    currentStation={stations && (stationIndex || stationIndex === 0) && stations[stationIndex]}
    setStationIndex={setStationIndex}
  />
}
