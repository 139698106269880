
import { useUser } from '@/context/user'
import { supabase } from '@/db/supabase'
import { logError } from '@/utils/error'

export default ({ children }) => {
  const { userId, userProfile } = useUser()

  const toggleAlphaAccess = async () => {
    if (!userId) return
    if (userProfile?.is_alpha_tester) {
      if (!window.confirm('Are you sure you want to leave the alpha? You won\'t be able to access lessons, conversations, flashcards etc')) return
      try {
        const { error } = await supabase
          .from('user_profiles')
          .upsert({
            id: userId,
            is_alpha_tester: false,
          })
        if (error) throw error
        window.alert('Alpha access removed')
        location.reload()
      } catch (error) {
        logError('grant alpha access', error)
      }
      return
    }
    if (window.prompt(`Welcome to Protolang alpha access! What's the secret word?`) == 'bravo') {
      try {
        const { error } = await supabase
          .from('user_profiles')
          .upsert({
            id: userId,
            is_alpha_tester: true,
          })
        if (error) throw error
        location.reload()
      } catch (error) {
        logError('grant alpha access', error)
      }
    } else {
      window.alert('Sorry, that\'s not right--try again?')
    }
  }

  return <span className="hover-link" onClick={toggleAlphaAccess}>
    {userProfile?.is_alpha_tester ? 'Leave alpha' : children || 'Join alpha'}
  </span>
}
