import { useParams } from 'react-router-dom'

import { useLanguage } from '@/context/language'
import { useUser } from '@/context/user'
import { supabase, useSupabaseQuery } from '@/db/supabase'
import ErrorPage from '@/views/errors/ErrorPage'
import Practice from '@/views/practice/Practice'

export default () => {
  const { currentLanguage } = useLanguage()
  const { phraseSource } = useParams()
  const { userId } = useUser()

  const lessonsQuery = supabase
    .from('lessons')
    .select('*, current_edit_id(*)')
    .eq('language_id', currentLanguage.id)
    .order('sort_order',  { ascending: true })
  const [lessons, lessonsLoading, lessonsError] = useSupabaseQuery(lessonsQuery, [currentLanguage.id])

  const savedWordsQuery = supabase
    .from('saved_words')
    .select()
    .eq('language_id', currentLanguage.id)
  const [savedWords, savedWordsLoading, savedWordsError] = useSupabaseQuery(savedWordsQuery, [currentLanguage.id, userId], !userId)

  const phraseScoresQuery = supabase
    .from('user_phrase_scores')
    .select('*, phrase_id(*)')
    .eq('created_by', userId)
  const [phraseScores, phraseScoresLoading, phraseScoresError] = useSupabaseQuery(phraseScoresQuery, [currentLanguage.id, userId], !userId)

  if (lessonsLoading || (phraseScoresLoading && userId) || (savedWordsLoading && userId)) return 'Loading...'
  const error = lessonsError || phraseScoresError || savedWordsError
  if (error) return <ErrorPage error={error} />

  return <Practice
    lessons={lessons}
    savedWords={savedWords}
    phraseScores={phraseScores}
    phraseSource={phraseSource}
  />
}
