import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

import { MainWrapper, OneCol } from '@/styles/Layout'

export default () => {
  const navigate = useNavigate()

  return <MainWrapper>
    <Helmet>
      <title>Not found (404) | Protolang</title>
    </Helmet>
    <OneCol $cool>
      <h1 data-testid="404-page">Page not found</h1>
      <p>Sorry, we couldn't find this page</p>
      <hr />
      <p>Think you hit an error?</p>
      <button className="button" onClick={() => navigate('/contact')}>Contact us</button>
    </OneCol>
  </MainWrapper>
}
