import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { useLanguage } from '@/context/language'
import { supabase } from '@/db/supabase'
import Definable from '@/views/_components/Definable'
import SpeakWord from '@/views/_components/SpeakWord'
import ErrorComponent from '@/views/errors/ErrorComponent'

const NEWS_ITEMS_LEVEL_CUTOFF = 3
const MAX_NEWS_ITEMS = 3

export default () => {
  const { currentLanguage, currentUserLanguage } = useLanguage()

  const [headlines, setHeadlines] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const oneHeadline = currentUserLanguage?.proficiency_level <= NEWS_ITEMS_LEVEL_CUTOFF

  const getNews = async () => {
    try {
      if (!currentLanguage.id) {
        setError('Missing language')
        return
      }
      setLoading(true)
      console.log('🏃 triggering edge function get_news')
      const { data, error } = await supabase.functions.invoke('get_news', {
        body: { lang_code: currentLanguage.iso639_1 },
      })
      setError(null)
      if (error) throw error
      console.log('🗞️ news data', data)
      const fewerHeadlines = data?.feed.items.slice(0, MAX_NEWS_ITEMS) || []
      const headlinesWithoutSource = fewerHeadlines.map(h => ({
        title: h.title.split(' - ')[0],
        link: h.link,
      }))
      setHeadlines(headlinesWithoutSource)
      setLoading(false)
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    getNews()
  }, [])

  if (loading) return '...'
  if (error) return <ErrorComponent error={error} />

  return <div>
    {/* <h4><Definable wordString="Notizia" /></h4> */}
    <h4>News</h4>
    {oneHeadline ?
      <div>
        <Definable wordString={headlines[0]?.title} splitByWord />
        <SpeakWord wordString={headlines[0]?.title} />
        <a href={headlines[0]?.link || '#'} target="_blank" rel="noreferrer" style={{fontSize: 'var(--s)'}}>
          link
        </a>
      </div>
      :
      <NewsList>
        {headlines?.map((headline, index) => {
          return <NewsItem key={index}>
            <Definable wordString={headline.title} splitByWord />
            <SpeakWord wordString={headline.title} />
            <a href={headline?.link || '#'} target="_blank" rel="noreferrer" style={{fontSize: 'var(--s)'}}>
              link
            </a>
          </NewsItem>
        })}
      </NewsList>
    }
  </div>
}

const NewsList = styled.ul`
  margin: 0;
  padding: 0;
`
const NewsItem = styled.li`
  margin-bottom: 1rem;
  list-style: none;
  padding: 0;
`
