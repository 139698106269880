import styled from 'styled-components/macro'

export default () => {
  return <StampImage
    src="/images/hero_illustrations/stamp.svg"
    alt="Call to adventure passport stamp"
  />
}
export const StampImage = styled.img`
  position: absolute;
  top: 40%;
  left: 40%;
  width: 180px;
  height: 180px;
  object-fit: contain;
  z-index: 1;
  transform: rotate(20deg) translate(-50%, -50%);
  mix-blend-mode: hard-light;
  opacity: .4;
  pointer-events: none;

  @media (max-width: 800px) {
    width: 160px;
    height: 160px;
    top: 15%;
    left: 60%;
  }
  @media (max-width: 500px) {
    width: 130px;
    height: 130px;
    top: 15%;
    left: 70%;
  }
`
