import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { useState } from 'react'
import yaml from 'yaml'

import { supabase, useSupabaseQuery } from '@/db/supabase'

const phrasesReadmeContent = `# Protolang phrases

This is a collection of phrases from [Protolang.com](https://protolang.com).

Generated ${new Date().toString()}

License information coming soon.
`

export default () => {

  // ready, working, done
  const [downloadingState, setDownloadingState] = useState('ready')

  const languagesQuery = supabase
    .from('languages')
    .select()
  const [languages, languagesLoading, languagesError] = useSupabaseQuery(languagesQuery)

  const downloadAll = async () => {

    setDownloadingState('working')

    // get phrases
    console.log('⬇️ getting phrases')
    const { data: phrases, error } = await supabase
      .from('phrases')
      .select('*')
      .order('language_id, id', { ascending: true })

    if (error) {
      alert('Sorry, could not download phrases right now. Try again later?')
      console.error(error)
      return
    }

    // generate object of markdown files
    console.log('⬇️ generating phraseFiles')
    const phraseFiles = phrases.map((phrase, index) => {
      const language = languages.find(lang => lang.id === phrase.language_id)
      const languageSlug = language.slug_eng
      const { random_slug, content_l2, content_l1, context_l1 } = phrase
      let metadata = { language: languageSlug, content_l2, content_l1, context_l1 }
      if (phrase.content_l2_alts) {
        metadata.content_l2_alts = phrase.content_l2_alts
      }
      if (phrase.content_l1_alts) {
        metadata.content_l1_alts = phrase.content_l1_alts
      }
      metadata.link = `https://protolang.com/${languageSlug}/phrase/${random_slug}`
      const yamlContent = yaml.stringify(metadata, {sortMapEntries: true})
      const indexPadded = index.toString().padStart(6, '0')
      return {
        name: `${indexPadded} - ${phrase.content_l2 || '[No content]'}.yaml`,
        content: yamlContent,
      }
    })

    // TODO - append CC license here
    phraseFiles.push({
      name: 'README.md',
      content: phrasesReadmeContent,
    })

    // make zip file
    console.log('⬇️ zipping phraseFiles', phraseFiles)
    const zip = new JSZip()
    phraseFiles.forEach( (file, index) => zip.file(file.name, file.content))
    const zipFile = await zip.generateAsync({ type: 'blob' })

    // save file
    console.log('⬇️ saving phraseFiles')
    saveAs(zipFile, `Protolang phrases.zip`)

    setDownloadingState('done')
  }

  if (languagesLoading) return <p>Loading...</p>
  if (languagesError) return <p>Error: {languagesError.message}</p>

  return <button disabled={downloadingState === 'working' || downloadingState === 'done'} onClick={downloadAll}>
    {
      downloadingState === 'ready' ? 'Download all phrases' :
      downloadingState === 'working' ? 'Downloading...' :
      downloadingState === 'done' ? 'Done!' :
      'Error'
    }
  </button>
}
