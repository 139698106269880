import { useState } from 'react'

import { useUser } from '@/context/user'
import { supabase, useSupabaseQuery } from '@/db/supabase'
import { logError } from '@/utils/error.js'

export default ({ userProfile }) => {
  const { userId } = useUser()

  const [saving, setSaving] = useState(false)

  const profileFollowsQuery = supabase
    .from('profile_follows')
    .select()
    .eq('followed_profile_id', userProfile?.id)
    .eq('created_by', userId)
  const [profileFollows, loading, error] = useSupabaseQuery(profileFollowsQuery, [userProfile?.id, userId], (!userProfile?.id || !userId))

  const profileFollow = profileFollows?.length > 0 && profileFollows[0]

  async function followProfile() {
    if (!userId) {
      alert('You must be logged in to follow other users')
      return
    }

    try {
      setSaving(true)
      const { error } = await supabase
        .from('profile_follows')
        .upsert([{
          followed_profile_id: userProfile?.id,
        }])
        .eq('followed_profile_id', userProfile?.id)
        .eq('created_by', userId)
        .select()
      if (error) throw error
      setSaving(false)
      location.reload() // TODO - realtime or save state
    } catch (error) {
      logError('follow user', error)
    }
  }

  async function unfollowProfile() {
    if (!userId) { return }

    try {
      setSaving(true)
      const { error } = await supabase
        .from('profile_follows')
        .delete()
        .eq('followed_profile_id', userProfile?.id)
        .eq('created_by', userId)
      if (error) throw error
      setSaving(false)
      location.reload() // TODO - realtime or save state
    } catch (error) {
      logError('unfollow user', error)
    }
  }

  if (!userProfile?.id) return null
  if (userProfile?.id === userId) return null

  return <>
    {profileFollow && <div>✓ Following</div>}
    <button onClick={ profileFollow ? unfollowProfile : followProfile } disabled={saving || loading || error}>
      {!userId ? 'Log in to follow' : profileFollow ? 'Unfollow' : `Follow ${userProfile.username}`}
    </button>
  </>
}
