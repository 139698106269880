import styled from 'styled-components/macro'

import { useLanguage } from '@/context/language'
import { supabaseDictionaries, useSupabaseQuery } from '@/db/supabase'
import Definable from '@/views/_components/Definable'
import Pronouncable from '@/views/_components/Pronouncable'
import SpeakWord from '@/views/_components/SpeakWord'
import MnemonicSuggested from '@/views/mnemonics/MnemonicSuggested'
import SaveButton from '@/views/saved/SaveButton'

// TODO
// play pronunciation of each syllable
// For verbs, show conjugation tables
// more mnemonics?

export default ({ wordString }) => {
  const { currentLanguage } = useLanguage()

  const query = supabaseDictionaries
    .from(currentLanguage.iso639_1)
    .select()
    .eq('word', wordString)
  const [definitions, loading, error] = useSupabaseQuery(query, [wordString], !wordString)

  return <WordWrapper>
    <Definable wordString={wordString} />
    <SpeakWord wordString={wordString} />
    <SaveButton wordString={wordString} />
    <MnemonicSuggested wordString={wordString} />

    {
      error ? <div>Error getting definitions: {error.message}</div> :
      loading ? <div>...</div> :
      (!definitions || definitions.length === 0) ? <div>(Not in our dictionary yet)</div> :
      <>
        <ul>
          {definitions.map(word => {
            const sounds = word._wiktionary_data?.sounds
            const ipaSounds = sounds?.filter(sound => sound.ipa)
            const ipa = ipaSounds?.length > 0 ? ipaSounds[0].ipa : null
            const rhymeSounds = sounds?.filter(sound => sound.rhyme)
            const rhyme = rhymeSounds?.length > 0 ? rhymeSounds[0].rhyme : null
            return <li key={word.id}>
              {ipa && <div>Pronunciation: <Pronouncable ipa={ipa} /></div>}
              {' '}
              {word._wiktionary_data?.senses[0].glosses?.join('\n')}
              {rhyme && <div>rhyme: {rhyme}</div>}
            </li>
          })}
        </ul>
      </>
    }

  </WordWrapper>
}

export const WordWrapper = styled.div`
  border-top: 1px solid;
  padding: .5rem 0;
  /* background: var(--bg); */
`
