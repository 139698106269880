import styled from 'styled-components/macro'

export default ({ id }) => {
  if (!id) return null
  return <VideoWrapper>
    <div style={{position: 'relative', paddingBottom: '56.25%', height: 0}}>
      <iframe src={`https://www.loom.com/embed/${id}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`}
        title="Protolang demo video"
        allowFullScreen
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 0 }}
      />
    </div>
  </VideoWrapper>
}

const VideoWrapper = styled.div`
  min-width: 140px;
  width: 100%;
  max-width: 100%;
`