import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { useLanguage } from '@/context/language'
import SelectionProvider from '@/context/selection'
import { Badge } from '@/styles/Badge'
import { TwoColumns } from '@/styles/Layout'
import SpeakWord from '@/views/_components/SpeakWord'

export default () => {
  const { currentLanguage } = useLanguage()

  return <TwoColumns $tall>
    <div>
      <SelectionProvider>
        <StoryWrapper>
          <div style={{position: 'absolute', left: '1rem', top: '1rem'}}>
            <SpeakWord word="Nel mezzo del cammin di nostra vita, mi ritrovai per una selva oscura, ché la diritta via era smarrita." />
          </div>
          <p>
            Nel mezzo del cammin di nostra vita
            <br />
            mi ritrovai per una selva oscura,
            <br />
            ché la diritta via era smarrita.
          </p>
          <p>
            Ahi quanto a dir qual era è cosa dura
            <br />
            esta selva selvaggia e aspra e forte
            <br />
            che nel pensier rinova la paura!
          </p>
          <p>
            Tant’ è amara che poco è più morte;
            <br />
            ma per trattar del ben ch’i’ vi trovai,
            <br />
            dirò de l’altre cose ch’i’ v’ho scorte.
          </p>
        </StoryWrapper>
      </SelectionProvider>
    </div>
    <ReadEmptyStateWrapper>
      <ReadPromoContent>
        <Badge>Coming soon</Badge>
        <h1 style={{margin: '.5rem 0 1rem'}}>Read real {currentLanguage.name_eng} news and stories</h1>
        {/* <p>Practice reading (or listening!) to build your fluency</p> */}
        <ul>
          <li>Matched to your skill level</li>
          <li>Look up words and phrases on the fly</li>
          <li>Powered by GPT-4</li>
        </ul>
        <p>← Try selecting some words and see what happens!</p>
        <Link className="button" to="/signup">Join the waitlist</Link>
      </ReadPromoContent>
    </ReadEmptyStateWrapper>
  </TwoColumns>
}

export const ReadEmptyStateWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2rem;
`
export const ReadPromoContent = styled.div`
  max-width: 500px;
`
export const StoryWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  padding: 2rem;
  margin: 0 0 1rem 0;

  background: var(--bg);
  font-family: var(--font-family-serif);
  font-size: var(--l);
  border: 1px solid;

  p:first-child:first-letter {
    float: left;
    font-size: 3rem;
    line-height: 0.65;
    margin: 0.1em 0.1em 0.2em 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 100%;
    height: 100%;
    border: 1px solid;
    background: var(--bg);
    z-index: -1;
  }
  &::after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 100%;
    height: 100%;
    border: 1px solid;
    background: var(--bg);
    z-index: -2;
  }
`
