import moment from 'moment'
import { Link } from 'react-router-dom'

import { useLanguage } from '@/context/language'
import { useUser } from '@/context/user'
import { supabase, useSupabaseQuery } from '@/db/supabase'

export default () => {

  const { userId } = useUser()
  const { startingLanguage, currentLanguage } = useLanguage()

  const query = supabase
    .from('phrase_attempts')
    .select('*, phrase_id(*)')
    .eq('created_by', userId)
    .eq('language_id', currentLanguage.id)
  const [data, loading, error] = useSupabaseQuery(query, [userId], !userId)

  const attempts = data?.sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())

  return <div>
    {
      !userId ? <>
        <Link to="/signup">Create an account</Link>
        {' '}
        to start saving your practice history
      </> :
      error ? error.message :
      loading ? 'loading...' :
      (!attempts || attempts.length <= 0) ? `Try some flashcards to see your attempts` :
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Direction</th>
            <th>{currentLanguage.name_eng}</th>
            <th>{startingLanguage.name_eng}</th>
            <th>Prompt type</th>
            <th>Answer type</th>
            <th>Your guess</th>
            <th>Correct?</th>
          </tr>
        </thead>
        <tbody>
          {
            attempts.map(attempt => {
              return <tr key={attempt?.id}>
                <td>{moment(attempt.created_at).format('MM/DD/YYYY')}</td>
                <td>{attempt.direction === 'forward' ? `${startingLanguage.name_eng} to ${currentLanguage.name_eng}` : `${currentLanguage.name_eng} to ${startingLanguage.name_eng}`}</td>
                <td>
                  <Link to={`/${currentLanguage.slug_eng}/phrase/${attempt.phrase_id.random_slug}`}>
                    {attempt.phrase_id.content_l2}
                  </Link>
                </td>
                <td>{attempt.phrase_id.content_l1}</td>
                <td>{attempt.prompt_type}</td>
                <td>{attempt.answer_type}</td>
                <td>"{attempt.guess}"</td>
                <td>{attempt.is_correct ? '✅' : '❌'}</td>
              </tr>
            })
          }
        </tbody>
      </table>
    }
  </div>
}
