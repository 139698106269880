import { useEffect, useState } from 'react'

import { useLanguage } from '@/context/language'
import Typing from '@/styles/Typing'
import { getHint } from '@/utils/conversationHelpers'
import { logError } from '@/utils/error'

interface ConversationHintsProps {
  conversationMessages: any
  situation: any
  useHint: any
  disabled?: boolean
}

export default ({
  conversationMessages,
  situation,
  useHint,
  disabled,
}: ConversationHintsProps) => {

  const { startingLanguage, currentLanguage, currentUserLanguage } = useLanguage()

  const [hasRequestedHint, setHasRequestedHint] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hint, setHint] = useState(null)

  useEffect(() => {
    if (conversationMessages && conversationMessages.length > 0) {
      setHasRequestedHint(false)
      setHint(null)
    }
  }, [conversationMessages])

  const requestHint = async () => {
    setHasRequestedHint(true)
    setLoading(true)
    try {
      const hint = await getHint({ startingLanguage, currentLanguage, conversationMessages, currentUserLanguage, situation })
      setHint(hint)
    } catch (error) {
      logError('get a response to your message', error)
    }
    setLoading(false)
  }

  return <div style={{textAlign: 'center', fontSize: 'var(--s)', padding: '.5rem 0 0'}}>
    {
      disabled ? <div style={{opacity: '0'}}>-</div> :
      !hasRequestedHint ? <>or <button onClick={requestHint}>give me a hint</button></> :
      loading ? <Typing inline /> :
      !hint ? 'No hint available' :
      <>
        Try this!
        {' '}
        "<button onClick={() => useHint(hint)}>{hint}</button>"
        {' '}
        <button onClick={() => setHasRequestedHint(false)}>clear</button>
      </>
    }
  </div>
}
