import styled from 'styled-components/macro'

export default styled.div`
  border-radius: 50%;
  height: 12px;
  width: 12px;
  margin: 3px;
  transform: scale(1);
  background: hsla(0, 100%, 60%, 1);
  box-shadow: 0 0 0 0 hsla(0, 100%, 60%, 1);
  animation: pulse-red 2s infinite;

  @keyframes pulse-red {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 hsla(0, 100%, 60%, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px hsla(0, 100%, 60%, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 hsla(0, 100%, 60%, 0);
    }
  }
`
