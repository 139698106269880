import { supabase, useSupabaseQuery } from '@/db/supabase'

import { useUser } from '@/context/user'
import { MainWrapper } from '@/styles/Layout'
import ErrorComponent from '@/views/errors/ErrorComponent'

export default ({ wordString }) => {
  const { userId } = useUser()

  const query = supabase
    .from('mnemonics')
    .select(`
      *,
      all_votes:mnemonic_votes(*)
    `)
    .eq('created_by', userId)
  const [mnemonics, loading, error] = useSupabaseQuery(query, [wordString])

  if (loading) return 'loading...'
  if (error) return <ErrorComponent error={error} />
  if (!userId) return 'not logged in'
  if (!mnemonics || mnemonics.length <= 0) return 'no mnemonics'

  return <MainWrapper>
    <h1>Your mnemonics</h1>
    <table>
      <thead>
        <tr>
          <th>Remember method</th>
          <th>Votes</th>
          <th>Vote</th>
        </tr>
      </thead>
      <tbody>
        {mnemonics.map(mnemonic => <tr key={mnemonic.id}>
          <td>
            {mnemonic.target_phrase}
          </td>
          <td>
            {mnemonic.remember_method}
          </td>
          <td>
            {mnemonic.all_votes?.length} vote(s)
          </td>
        </tr>)}
      </tbody>
    </table>
  </MainWrapper>
}
