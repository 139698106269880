import styled from 'styled-components/macro'

import { getLabelAndIdFromUnsplashFileName } from '@/utils/unsplash'

export default ({
  situation,
}) => {

  const { creditName, creditUrl } = getLabelAndIdFromUnsplashFileName(situation?.background_file)

  if (!situation) return "Error: no conversation situation for conversation intro"

  return <ConversationBannerWrapper>
    <ConversationBannerImageWrapper style={{background: situation.background_color || 'rgba(60,60,60,.9)'}}>
      <ConversationBannerImage $image={situation.background_file} />
    </ConversationBannerImageWrapper>
    <ConversationBannerCredit>
      photo: <a href={creditUrl} target="_blank">{creditName}</a>
    </ConversationBannerCredit>
  </ConversationBannerWrapper>
}

const ConversationBannerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30vh;
  max-height: 140px;
`
const ConversationBannerImageWrapper = styled.div`

  /* expand outside the parent for some full-bleed action */
  position: absolute;
  left: -1.5rem;
  right: -1.5rem;
  top: -2rem;
  bottom: 0;

  /* center the child image, because it's going to be bigger still to account for the blurred edges */
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
`
const ConversationBannerImage = styled.img`
  width: 110%;
  height: 105%;
  background-image: ${({$image}) => `url("/images/conversation_backgrounds/${$image}")`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(3px);
`
const ConversationBannerCredit = styled.div`
  font-size: var(--xs);
  position: absolute;
  bottom: -1rem;
  right: 0.5rem;
  z-index: 100;
  color: var(--text);
  opacity: .3;
`
