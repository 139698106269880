import { useEffect, useId, useState } from 'react'

import { useLanguage } from '@/context/language'
import { useSoundFx } from '@/context/soundEffects'
import { useSpeechSynthesis } from '@/context/speechSynthesis'
import { useUser } from '@/context/user'
import useCorrectSpokenAnswer from '@/hooks/useCorrectSpokenAnswer'
import Icon from '@/styles/Icon'
import PulseRecorder from '@/styles/PulseRecorder'
import Tooltip from '@/styles/Tooltip'
import TranscriptPopup from '@/styles/TranscriptPopup'
import { getAltsWithContractions } from '@/utils/string'

export default ({ correctAnswer }) => {

  const speakId = useId()
  const { userProfile } = useUser()
  const { currentLanguage } = useLanguage()
  const {
    speechSynthesisIsSupported,
    speechSynthesisReady,
    speak,
    currentUtteranceId,
  } = useSpeechSynthesis()
  const { playSuccess, playTryAgain, repeatOn } = useSoundFx()

  const [ cardState, setCardState ] = useState('waiting')

  const correctAnswers = getAltsWithContractions(correctAnswer, currentLanguage)
  const isSpeakingLocal = currentUtteranceId === speakId

  const {
    speechRecognitionIsSupported,
    willShowMicPermissionsModal,
    setHasInteractedWithMic,
    startSpeechRecognition,
    stopSpeechRecognition,
    recognitionState,
    interimTranscript,
    finalTranscript,
    correctStatus,
    userCorrectAnswer,
  } = useCorrectSpokenAnswer(correctAnswers)

  useEffect( () => {
    if (!isSpeakingLocal) return // coming from another component, ignore

    if (correctStatus === 'correct') {
      stopSpeechRecognition()
      setCardState('correct')
      playSuccess()
      const shouldSpeak = speechSynthesisIsSupported && speechSynthesisReady && repeatOn
      shouldSpeak && speak(speakId, userCorrectAnswer, {
        gender: userProfile?.refer_to_as_gender,
      })
    }

    if (correctStatus === 'incorrect') {
      stopSpeechRecognition()
      setCardState('incorrect')
      playTryAgain()
      return
    }
  }, [isSpeakingLocal, correctStatus])

  const toggleSpeech = () => {
    if (isSpeakingLocal) {
      stopSpeechRecognition()
    } else if (willShowMicPermissionsModal) {
      setHasInteractedWithMic(true)
    } else {
      startSpeechRecognition(currentLanguage.ietf_bcp_47)
    }
  }

  const disabled = !speechRecognitionIsSupported || (recognitionState === 'listening' && !isSpeakingLocal)
  return <div style={{position: 'relative', display: 'inline-block'}}>
    <TranscriptPopup
      isOpen={isSpeakingLocal}
      popoverContent={<>{finalTranscript || interimTranscript ? <code><strong>{finalTranscript}</strong> {interimTranscript} </code> : `Say "${correctAnswer}"`}</>}
    >
      <Tooltip label={!speechRecognitionIsSupported ? "Speech recognition is not supported in your browser yet. Try Chrome?" : isSpeakingLocal ? "Stop recording" : "Practice pronunciation"}>
        <button
          className="button button-small button-tertiary"
          type="button"
          onClick={toggleSpeech}
          disabled={disabled}
        >
          {
            !speechSynthesisIsSupported ? <Icon name="mic" /> :
            isSpeakingLocal ? <PulseRecorder /> : 
            cardState === "waiting" ? <Icon name="mic" /> :
            cardState === "incorrect" ? '❌' :
            cardState === "correct" ? '✅' :
            <Icon name="mic" />
          }
        </button>
      </Tooltip>
    </TranscriptPopup>
  </div>
}
