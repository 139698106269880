import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { supabase, useSupabaseQuery } from '@/db/supabase'
import { Conversations } from '@/types/schema'
import { logError } from '@/utils/error'
import ConversationItem from '@/views/conversations/ConversationItem'

export default () => {

  const { conversationSlug } = useParams()

  const [conversation, setConversation] = useState<Conversations>()
  const [conversationLoading, setConversationLoading] = useState(false)
  const [conversationError, setConversationError] = useState(null)

  const [conversationMessages, setConversationMessages] = useState([])
  const [conversationMessagesLoading, setConversationMessagesLoading] = useState(false)
  const [conversationMessagesError, setConversationMessagesError] = useState(null)

  useEffect( () => {
    if (!conversationSlug) return
    const getConversation = async () => {
      try {
        console.log(`🏃 triggering db function get_conversations_by_slug`)
        setConversationLoading(true)
        const { data, error } = await supabase.rpc('get_conversations_by_slug', { slug_param: conversationSlug })
        if (error) throw error
        setConversationError(null)
        setConversationLoading(false)
        setConversation(data?.length ? data[0] : null)
      } catch (error) {
        setConversationError(error)
        setConversationLoading(false)
        setConversation(null)
        logError('load shared conversation', error)
      }
    }
    getConversation()
  }, [conversationSlug])

  useEffect( () => {
    if (!conversation) return
    const getConversationMessages = async () => {
      try {
        console.log(`🏃 triggering db function get_conversation_messages_by_conversation_id`)
        setConversationMessagesLoading(true)
        const { data, error } = await supabase.rpc('get_conversation_messages_by_conversation_id', { conversation_id_param: conversation.id })
        if (error) throw error
        setConversationMessagesError(null)
        setConversationMessagesLoading(false)
        setConversationMessages(data)
      } catch (error) {
        setConversationMessagesError(error)
        setConversationMessagesLoading(false)
        setConversationMessages([])
        logError('load shared conversation messages', error)
      }
    }
    getConversationMessages()
  }, [conversation])

  const situationQuery = supabase
    .from('conversation_situations')
    .select('*, character_id(*)')
    .eq('id', conversation?.situation_id)
    .single()
  const [situation, situationLoading, situationError] = useSupabaseQuery(situationQuery, [conversation], !conversation)

  if (conversationMessagesError) return <div>Error: {conversationMessagesError.message}</div>
  if (conversationError) return <div>Error: {conversationError.message}</div>
  if (situationError) return <div>Error: {situationError.message}</div>
  if (conversationMessagesLoading || conversationLoading || situationLoading) return <div>Loading...</div>

  return <ConversationItem
    conversation={conversation}
    conversationMessages={conversationMessages}
    situation={situation}
  />
}
