import styled from 'styled-components/macro'

export const ProgressBarWrapper = styled.div`
  height: 0.5rem;
  background-color: ${props => props.disabled ? 'var(--badge)' : 'var(--bg)'};
  overflow: hidden;
  position: relative;
  margin: 0.5rem 0;
  border-radius: 100px;
  border: ${props => props.disabled ? '1px solid #999' : '1px solid var(--text)'};
`
export const ProgressBar = styled.div`
  width: ${props => props.progress * 100}%;
  height: 100%;
  background-color: var(--text);
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.5s ease;
`
