import { useState } from 'react'
import styled from 'styled-components/macro'

import { useReferencePanel } from '@/context/reference'
import SpeakableInput from '@/styles/SpeakableInput'
import { Languages } from '@/types/schema'
// import {supabaseDictionaries} from '@/_util/supabaseClient'

interface SearchBoxParams {
  language: Languages
}

export default ({ language }: SearchBoxParams) => {
  const {
    queryText,
    setQuery,
    setReferenceIsOpen
  } = useReferencePanel()

  const [value, setValue] = useState(queryText)

  // TODO - full text search suggestions
  // const { currentLanguage } = useLanguage()
  // https://supabase.com/docs/guides/database/full-text-search
  // const { data, error } = await supabaseDictionaries
  //   .from(currentLanguage.iso639_1)
  //   .select()
  //   .textSearch('fts', `'${string}'`)
  // }

  const onSubmit = async () => {
    setQuery({text: value, language})
    setReferenceIsOpen(true)
  }

  return <SearchForm onSubmit={onSubmit}>
    <SpeakableInput
      lang={language.iso639_1 || ''}
      name="search"
      placeholder={`Type something in ${language.name_eng}...`}
      value={value}
      setValue={setValue}
      onSubmit={onSubmit}
      minRows={1}
      $fill
    />
  </SearchForm>
}

const SearchForm = styled.form`
  /* display: inline-block; */
  width: 100%;
`
