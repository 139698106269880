import { useState } from 'react'
import styled from 'styled-components/macro'
import { useDebounce } from 'use-debounce'

import { useLanguage } from '@/context/language'
import { supabase } from '@/db/supabase'
import { CheckboxItem } from '@/styles/RadioCheckbox'
import { logError } from '@/utils/error'
import Definable from '@/views/_components/Definable'
import SpeakWord from '@/views/_components/SpeakWord'
import MnemonicSuggested from '@/views/mnemonics/MnemonicSuggested'

export default ({
  children,
  ...rest
}) => {
  const [showMnemonics, setShowMnemonics] = useState(true)

  const unaccountedForParamsLength = Object.keys(rest).length - 1
  if (unaccountedForParamsLength > 0) console.warn('unaccountedForParams in ...rest:', rest)

  const contentString = children[0].props.children[0]
  const [contentStringDebounced] = useDebounce(contentString, 1000)
  const messages = contentStringDebounced.split('\n').map((message) => {
    if (!message.includes(': ')) return { speaker: null, content: message }
    const [speaker, content] = message.split(': ')
    return { speaker, content }
  })

  return <DialogWrapper>
    <div style={{ position: 'absolute', right: '1rem', top: '1rem', fontSize: 'var(--s)' }}>
      <CheckboxItem
        groupName='showMnemonics'
        optionId='showMnemonics'
        checked={showMnemonics}
        onChange={(_, value) => setShowMnemonics(value)}
      >
        mnemonics
      </CheckboxItem>
    </div>

    <DialogTable>
      <tbody>
        {messages.map((message, index) => {
          return <DialogRow
            key={index+'-'+message.content}
            message={message}
            altSpeaker={message.speaker !== messages[0].speaker}
            showMnemonics={showMnemonics}
          />
        })}
      </tbody>
    </DialogTable>
  </DialogWrapper>
}

const DialogRow = ({ message, altSpeaker, showMnemonics }) => {
  const { startingLanguage, currentLanguage } = useLanguage()

  const [showTranslation, setShowTranslation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [translation, setTranslation] = useState(null)

  const getTranslation = async () => {
    try {
      setLoading(true)
      console.log('🏃 triggering edge function deepl_translate')
      const source_lang = currentLanguage.iso639_1 === 'en' ? 'EN-US' : currentLanguage.iso639_1.toUpperCase()
      const target_lang = startingLanguage.iso639_1 === 'en' ? 'EN-US' : startingLanguage.iso639_1.toUpperCase()
      const { data, error } = await supabase.functions.invoke('deepl_translate', {
        body: {
          text: message.content,
          source_lang,
          target_lang,
        },
      })
      if (error) throw error
      setLoading(false)
      setTranslation(data?.translations[0] && data?.translations[0].text && data.translations[0].text.trim())
    } catch (error) {
      setLoading(false)
      logError('translate dialog row', error)
    }
  }

  return <tr>
    <th style={{whiteSpace: 'nowrap'}}>
      {message.speaker || ''}
    </th>
    <td>
      <Definable wordString={message.content} splitByWord />
      <SpeakWord wordString={message.content} altVoice={altSpeaker} />
      {showMnemonics && <MnemonicSuggested wordString={message.content} />}
      <div style={{fontSize: 'var(--s)'}}>
        {
          loading ? '...' :
          error ? `Error: ${error.message}` :
          (showTranslation && translation) ? <span>{translation} · </span>
          : null
        }
        {!loading && 
          <button onClick={ () => {
            if (!translation) getTranslation()
            setShowTranslation(!showTranslation)
          }}>
            {showTranslation ? 'hide' : 'translate'}
          </button>
        }
      </div>
    </td>
  </tr>
}

export const DialogWrapper = styled.div`
  position: relative;
  margin: 1rem 0;
  padding: 1rem;
  border: 1px solid;
  box-shadow: 1px 1px var(--shadow);
`
export const DialogTable = styled.table`
  margin: 0;

  th,
  td {
    border: none;
    padding: .5rem 1rem .5rem 0;

    &:first-child {
      width: 0; // shrink to fit content
    }
  }
`
