import styled from 'styled-components/macro'

export default ({ current, total}) => {
  const totalArray = Array.from(Array(total).keys())
  return <StepProgressWrapper>
    {totalArray.map((step, index) => {
      const isCurrent = index === current
      const isComplete = index <= current
      return <StepProgressItem key={index} $isCurrent={isCurrent} $isComplete={isComplete} />
    })}
  </StepProgressWrapper>
}
const StepProgressWrapper = styled.div`
  display: flex;
  gap: .25rem;
`
const StepProgressItem = styled.div`
  width: .5rem;
  height: .5rem;
  border-radius: 50%;
  background: ${({ $isComplete }) => $isComplete ? 'var(--text)' : 'var(--bg)'};
  border: 1px solid;
`
