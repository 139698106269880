import { Link } from 'react-router-dom'

import { useLanguage } from '@/context/language'
import { useUser } from '@/context/user'
import { TwoColumns } from '@/styles/Layout'
import LayoutLanguage from '@/views/_layout/LayoutLanguage'

export default ({children}) => {
  const { currentLanguage } = useLanguage()
  const { userAuthLoading, isAdmin } = useUser()

  if (userAuthLoading) return 'Loading...'

  if (!isAdmin) {
    return <LayoutLanguage>
      <p>
        Sorry, you have to be an admin to view this page.
      </p>
      <p>
        Are you expecting access?
        <br />
        <Link to={`/contact`}>Contact us</Link>
      </p>
    </LayoutLanguage>
  }

  return <LayoutLanguage>
    <TwoColumns cols="200px auto" gap="2">
      <nav>
        <strong>Admin dashboard</strong>
        <br />
        <Link to={`/${currentLanguage.slug_eng}/admin/situations`}>🎬 Situations</Link>
        <br />
        <Link to={`/${currentLanguage.slug_eng}/admin/characters`}>👨‍🌾 Characters</Link>
        <br />
        <Link to={`/${currentLanguage.slug_eng}/admin/lessons`}>🧑‍🏫 Lessons</Link>
        <br />
        <Link to={`/${currentLanguage.slug_eng}/admin/lesson-edits`}>📝 Lesson edits</Link>
        <br />
        <Link to={`/${currentLanguage.slug_eng}/admin/phrases`}>💬 Phrases</Link>
        <br />
        <Link to={`/${currentLanguage.slug_eng}/admin/phrase-issues`}>🚩 Issues</Link>
        <br />
        <Link to={`/${currentLanguage.slug_eng}/admin/languages`}>🈸 Languages</Link>
        <br />
        <Link to={`/${currentLanguage.slug_eng}/admin/topics`}>🍎 Topics</Link>
        <br />
        <Link to={`/${currentLanguage.slug_eng}/admin/users`}>👤 Users</Link>
      </nav>
      <main>
        {children}
      </main>
    </TwoColumns>
  </LayoutLanguage>
}
