import { useState } from 'react'
import ReactTextareaAutosize from 'react-textarea-autosize'

import { useLanguage } from '@/context/language'
import { supabase } from '@/db/supabase'
import Modal from '@/styles/Modal'
import { ConversationMessages, IssueTypes, Issues, Lessons, Media, Mnemonics, Phrases, QuotesOfTheDay, UserProfiles } from '@/types/schema'
import { logError } from '@/utils/error.js'

interface ReportIssueParams {
  profile?: UserProfiles
  phrase?: Phrases
  lesson?: Lessons
  media?: Media
  mnemonic?: Mnemonics
  conversationMessage?: ConversationMessages
  quote?: QuotesOfTheDay
}
export default ({
  profile,
  phrase,
  lesson,
  media,
  mnemonic,
  conversationMessage,
  quote,
}:ReportIssueParams) => {
  const { startingLanguage, currentLanguage } = useLanguage()

  const [issueReporterIsOpen, setIssueReporterIsOpen] = useState(false)
  const [comment, setComment] = useState('')
  const [type, setType] = useState<IssueTypes | ''>('')
  const [saving, setSaving] = useState(false)

  async function reportIssue( event ) {
    event.preventDefault()
    event.stopPropagation()

    if (!type) return alert('Please select the reason you\'re reporting this content')

    const issue:Issues = {
      language_id: String(currentLanguage.id),
      issue_type: type,
      comment: comment,
    }
    if (profile) issue.profile_id = String(profile.id)
    if (phrase) issue.phrase_id = String(phrase.id)
    if (lesson) issue.lesson_id = String(lesson.id)
    if (media) issue.media_id = String(media.id)
    if (mnemonic) issue.mnemonic_id = String(mnemonic.id)
    if (conversationMessage) issue.conversation_message_id = String(conversationMessage.id)
    if (quote) issue.quote_id = String(quote.id)

    try {
      setSaving(true)
      const { error } = await supabase
        .from('issues')
        .insert([issue])
      if (error) throw error
      alert("We got your issue report. Thank you for helping improve the app!")
      setIssueReporterIsOpen(false)
    } catch (error) {
      setSaving(false)
      logError('report issue', error)
    }
  }

  return <>
    <button type="button" onClick={() => setIssueReporterIsOpen(true)}>flag</button>

    <Modal
      title="Flag content"
      isOpen={issueReporterIsOpen}
      onClose={() => setIssueReporterIsOpen(false)}
      width='460px'
    >
      <p>If you notice content that shouldn't be here, please file an issue using this form and let us know. Thank you!</p>
      <form onSubmit={reportIssue}>

        <label htmlFor="type">Reason</label>
        <select
          value={type}
          onChange={e => setType(e.target.value)}
          // onChange={e => setType(e.target.value as IssueTypes)}
          required
        >
          <option>-- Choose reason --</option>
          <option value="language">Incorrect language (grammar, translation, usage, etc)</option>
          <option value="inappropriate">Offensive or inappropriate language</option>
          <option value="scam">Scams, spam, or junk</option>
          <option value="copyright">Copyright or intellectual property issue</option>
          <option value="other">Other</option>
        </select>

        {profile && <>
          <label>User</label>
          <p>{profile.username || 'no user name'}</p>
        </>}
        {phrase && <>
          <label>{startingLanguage.name_eng}</label>
          <p>"{phrase.content_l1}"</p>

          <label>{currentLanguage.name_eng || 'Other language'}</label>
          <p>"{phrase.content_l2}"</p>
        </>}
        {lesson && <>
          <label>Lesson</label>
          <p>{lesson.title_l1} ({lesson.title_l2})</p>
        </>}
        {media && <>
          <label>Media</label>
          <p>{media.url}</p>
        </>}
        {mnemonic && <>
          <label>Phrase</label>
          <p>"{mnemonic.target_phrase}"</p>

          <label>Mnemonic</label>
          <p>"{mnemonic.remember_method}"</p>
        </>}
        {conversationMessage && <>
          <label>Message</label>
          <p>{conversationMessage.content}</p>
        </>}
        {quote && <>
          <label>Quote</label>
          <p>{quote.quote}</p>
        </>}


        <label htmlFor="comment">Comment</label>
        <ReactTextareaAutosize
          id="comment"
          minRows={3}
          value={comment}
          placeholder="Tell us why you're flagging this content"
          onChange={e => setComment(e.target.value)}
        />

        <br />
        <button
          className="button"
          type="submit"
          disabled={saving}
        >
          {saving ? 'Reporting...' : 'Report issue'}
        </button>
      </form>
    </Modal>
  </>

}
