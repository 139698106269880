import PronunciationPaneItem from '@/views/reference/PronunciationPaneItem'

export default ({ wordString }) => {
  // TODO - replace with string function like in Definable
  const words = wordString
    .replaceAll('?', '')
    .replaceAll('!', '')
    .replaceAll(',', '')
    .split(' ')
  if (words.length <= 0) return null
  if (words.length === 1) return <PronunciationPaneItem wordString={wordString} />

  return words.map((word, i) => {
    return <>
      <div>{word}:</div>
      <PronunciationPaneItem wordString={word} key={word+i} />
      {i < words.length - 1 && <hr />}
    </>
  })
}
