import styled from 'styled-components/macro'

import { MainWrapper, TwoColumns } from '@/styles/Layout'
import LessonContent from '@/views/lessons/LessonContent'
import { Link } from 'react-router-dom'

const phraseEmbedMarkdown = `
Protolang lets you embed interactive words and phrases in your lessons, with pronunciation, definitions, and more.

By default, anything word or phrase you embed in a lesson will be automatically made available to users as flashcards to practice later.

These are defined using two colons and the letter "b" (for "block").

- Takes up the full width of the lesson
- Users can click to hear the phrase
- Users can click to practice speaking the phrase
- Users can click each word to open a dictionary entry

Examples:

\`::b{ ita="salute" eng="cheers" engContext="toast when drinking" literalEng="health" }\`

::b{ ita="salute" eng="cheers" engContext="toast when drinking" literalEng="health" }

\`::b{ ita="dire" eng="to say" engAlt="to tell" }\`

::b{ ita="dire" eng="to say" engAlt="to tell" }

\`::b{ ita="Che cosa?" itaAlt="Che?" itaAlt2="Cosa?" eng="What?" }\`

::b{ ita="Che cosa?" itaAlt="Che?" itaAlt2="Cosa?" eng="What?" }

You can also embed a phrase inline with paragraph text instead of taking up an entire line. This is useful for simply mentioning a word that users can look up if they choose.

Use only one semicolon and the letter "i" (for "inline") instead of "b".

- Appears inline with other text
- Users can click to hear the phrase
- Users can click each word to open a dictionary entry

Examples:

\`This is a paragraph that mentioned :i{ ita="Ciao" }, which is a casual Italian greeting.\`

This is a paragraph that mentioned :i{ ita="Ciao" }, which is a casual Italian greeting.

\`This paragraph is similar, but includes the definition: :i{ ita="Ciao" eng="Hi" }.\`

This paragraph is similar, but includes the definition: :i{ ita="Ciao" eng="Hi" }.

When you embed a word in a lesson, you can give it the following attributes.

| attribute         | explanation                        | example                   |
| ----------------- | ---------------------------------- | ------------------------- |
| \`eng\`           | primary meaning in English         | _"cheers"_                |
| \`ita\`           | primary meaning in Italian         | _"salute"_                |
| \`engAlt\`        | alternative meaning(s) in English  |                           |
| \`itaAlt\`        | alternative meaning(s) in Italian  |                           |
| \`engContext\`    | context notes in English           | _"a toast when drinking"_ |
| \`literalEng\`    | literal translation in English     | _"health"_                |
| \`hideByDefault\` | whether to hide this in flashcards | _"true"_                  |
`
const videoEmbedMarkdown = `
You can embed videos from YouTube or by using the \`:video\` directive.

For example:

\`:video{youtubeId="B_dw8I169go" }\`

Will give you this:

:video{youtubeId="B_dw8I169go" }
`

export default () => {
  return <MainWrapper>
    <h1>Get help</h1>

    <p>Our full support page is coming soon. For now, <Link to="/contact">contact us directly</Link> if you need more support!</p>

    <br />
    <hr />
    <br />

    <TwoColumns cols="2fr 7fr" gap="3">
      <div>
        <StickyFollower>
          <p><strong>For learners</strong></p>
          <a href="#log-in-trouble">Trouble signing up or logging in</a><br />

          <br />

          <p><strong>For lesson creators</strong></p>
          <a href="#creating-lessons">Creating lessons</a><br />
          <a href="#formatting-lessons">Formatting lessons</a><br />
          <a href="#embedding-lesson-words">Embedding words and phrases</a><br />
          <a href="#embedding-lesson-videos">Embedding videos</a><br />
        </StickyFollower>
      </div>

      <div>
        <h2 id="log-in-trouble">Trouble signing up or logging in</h2>

        <p>Having trouble creating or logging in to your account? Here are a few quick things to try.</p>

        <ol>
          <li>Do you have two or more email addresses? Make sure you're checking the same email account as the email you're typing in the box.</li>
          <li>If you're logging in with Google, make sure you're able to <a href="https://accounts.google.com/" target="_blank">log in to your Google account</a> before logging in to Protolang</li>
          <li>Try logging in with a different browser or device.</li>
        </ol>

        <p>You may notice that Protolang doesn't have passwords. Instead, we send a magic login link to your email (or you can sign in with Google). This is because passwords can be very frustrating to remember, and also a security issue. We may add passwords in the future, but for now these are the only ways to log in.</p>

        <p>If you try these and you're still having trouble, please <Link to="/contact">contact us</Link> and we'll help you out.</p>

        <br />
        <hr />
        <br />

        <h2 id="creating-lessons">Creating lessons</h2>

        <ol>
          <li>Create your account on the <Link to="/signup">signup page</Link> if you haven't already.</li>
          <li>Go to the <Link to="/languages">languages page</Link> and choose the language you want to write for. If the language you want to write for is not yet available, <Link to="/contact">contact us</Link> for early access.</li>
          <li>From the <Link to="/lessons">lessons page</Link>, click the "Add lesson" button.</li>
          <li>Name your lesson and click "Next".</li>
          <li>Write your lesson content. (Currently our lessons are written in a customized <a href="https://www.markdownguide.org/basic-syntax/" target="_blank">Markdown format</a>. There are detailed instructions on the lesson edit page.)</li>
        </ol>

        <br />
        <hr />
        <br />

        <h2 id="formatting-lessons">Formatting lessons</h2>

        <p>In Protolang, lesson authors write in a text format called Markdown. This lets lesson authors write using a simple format that's easy to read and share, but also add rich formatting and interactivity. We also use something called <a href="https://guides.github.com/features/mastering-markdown/" target="_blank">Github-flavored Markdown</a>, which add support for more fancy things like tables.</p>

        <p>For example, you just use two asterisk on either side or a word <code>**like this**</code> and it will make the text <strong>bold</strong>.</p>

        <p>You can also add things like:</p>

        <ul>
          <li>Italic and underlined text</li>
          <li>Headings</li>
          <li>Bulleted and numbered lists</li>
          <li>Links</li>
          <li>Images</li>
          <li>Tables</li>
          <li>Horizontal rules</li>
        </ul>

        <p><a href="https://guides.github.com/features/mastering-markdown/" target="_blank">Read this guide to writing in Markdown</a> to learn how.</p>

        <br />
        <hr />
        <br />

        <h2 id="embedding-lesson-words">Embedding words and phrases</h2>

        <p>You can embed a few language-related items into your lessons using something called Markdown directives <a href="https://talk.commonmark.org/t/generic-directives-plugins-syntax/444" target="_blank">[1]</a> <a href="https://github.com/remarkjs/remark-directive" target="_blank">[2]</a>.</p>

        <LessonContent content={phraseEmbedMarkdown} />

        <br />
        <hr />
        <br />

        <h2 id="embedding-lesson-videos">Embedding videos</h2>

        <LessonContent content={videoEmbedMarkdown} />
      </div>
    </TwoColumns>

  </MainWrapper>
}

const StickyFollower = styled.div`
  position: sticky;
  top: 2rem;
`

