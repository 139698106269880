import proficiency_levels from '@/consts/proficiency_levels'
import { supabase } from '@/db/supabase'
import { Conversations } from '@/types/schema'

const MAX_HISTORY = 20

export const createConversation = async ({ currentLanguage, proficiencyLevel, situation }) => {
  const newData:Conversations = {
    language_id: currentLanguage.id,
    situation_id: situation.id,
    proficiency_level: proficiencyLevel,
  }

  const { data, error } = await supabase
    .from('conversations')
    .insert([newData])
    .select()
  if (error) throw error
  return data[0]
}

export const saveUsersMessage = async ({ conversation, userId, message }) => {
  const { data, error: insertError } = await supabase
    .from('conversation_messages')
    .insert([{
      conversation_id: conversation.id,
      content: message,
      sender_type: 'user',
      sender_id: userId,
    }])
    .select()
  if (insertError) throw insertError
  return data[0]
}

export const punctuateText = async ({ currentLanguage, message }) => {
  const messages = [
    {
      role: 'system',
      content: `
        Please respond to every message by formatting that message with correct ${currentLanguage.name_eng} punctuation.
        Please only reply in ${currentLanguage.name_eng}.
        Do not wrap your answer in quotes or anything else.
        If there's nothing to format, just reply with the original text.

        Here are some examples:

        Their sentence: "ciao come stai"
        Your response: "Ciao, come stai?"

        Their sentence: "come"
        Your response: "Come"

        Here are some examples of how NOT to respond:

        Their sentence: "cacio e peppe"
        Your response: "Cacio e pepe (Please note the correct spelling of 'pepe' with only one 'p'.)" (Do not include the parenthetical note.)
      `,
    },
    {
      role: 'user',
      content: message,
    },
  ]

  console.log("Generated ai prompt", { messages })

  console.log('🏃 triggering edge function ai_prompt')
  const { data: punctuationReply, error: punctuationError } = await supabase.functions
    .invoke('ai_prompt', { body: { messages, max_tokens: 256 } })
  if (punctuationError) throw punctuationError

  let responseContent = punctuationReply.promptResponse.content.trim()

  // If the responseContent is wrapped in quotes, just remove them
  // https://stackoverflow.com/a/20196163/1061063
  if (responseContent.startsWith('"') && responseContent.endsWith('"')) {
    responseContent = responseContent.slice(1, -1)
  }

  console.log("💬 Got punctuation reply", responseContent)

  return responseContent
}

export const validateUsersMessage = async ({ startingLanguage, currentLanguage, conversation, message }) => {
  const messages = [
    {
      role: 'system',
      content: `
        You are an ${currentLanguage.name_eng} language teacher helping a beginner ${currentLanguage.name_eng} language student with their grammar.
        The student will send you a sentence, phrase, or word in ${currentLanguage.name_eng}.
        Please reply with a grammar suggestion if it has a grammar mistake.
        Do not count capitalization or spelling mistakes as grammar mistakes.
        Please only reply in ${startingLanguage.name_eng}. Never reply in ${currentLanguage.name_eng}.

        Here are some examples:

        Student: "Mio sono un uomo"
        Your response: {
          is_correct: false,
          grammar_suggestion: "The word for 'I' is 'io', not 'mio'."
        }

        Student: "Io sono un uomo"
        Your response: {
          is_correct: true,
        }
      `,
    },
    {
      role: 'user',
      content: message,
    },
  ]

  const functions = [
    {
      name: "validate_grammar",
      description: `Detects whether a written piece of ${currentLanguage.name_eng} has a grammar mistake`,
      parameters: {
        type: "object",
        properties: {
          is_correct: {
            type: "boolean",
            description: `True if the user's message is valid ${currentLanguage.name_eng}`,
          },
          grammar_suggestion: {
            type: "string",
            description: `A grammar suggestion if the user's message is not valid ${currentLanguage.name_eng}`,
          },
        },
        required: ["is_correct"]
      },
    },
  ]

  const function_call = { name: "validate_grammar" }

  console.log("Generated ai prompt", { messages, functions, function_call })

  console.log('🏃 triggering edge function ai_prompt')
  const { data: editorReply, error: editorError } = await supabase.functions
    .invoke('ai_prompt', { body: { messages, functions, function_call, max_tokens: 256 } })
  if (editorError) throw editorError

  const responseResultString = editorReply.promptResponse?.function_call?.arguments
  const responseResult = responseResultString && JSON.parse(responseResultString)
  console.log("💬 Got editor reply", responseResult)

  if (!responseResult.is_correct) {
    const editorResponseData = {
      conversation_id: conversation.id,
      content: responseResult.grammar_suggestion,
      sender_type: 'editor',
      sender_id: null,
    }
    const { error: editorWriteError } = await supabase
      .from('conversation_messages')
      .insert([editorResponseData])
    if (editorWriteError) throw editorWriteError
    return false
  }
  return true
}

export const getHint = async ({ startingLanguage, currentLanguage, conversationMessages, currentUserLanguage, situation }) => {
  const prompt = situation?.user_summary_eng + '\n\n' + situation?.user_goal_eng
  const level = proficiency_levels.find(level => level.value === currentUserLanguage?.proficiency_level) || proficiency_levels[0]

  const messageHistory = conversationMessages
    .slice(Math.max(conversationMessages.length - MAX_HISTORY, 1))
    .filter(message => message.sender_type === 'assistant' || message.sender_type === 'user')
    .map(message => {
      return {
        role: message.sender_type,
        content: message.content,
      }})
    .map(message => ({
      role: message.role === 'assistant' ? 'user' : message.role === 'user' ? 'assistant' : message.role,
      ...message,
    }))

  const messages = [
    {
      role: 'system',
      content: `
        You are an ${currentLanguage.name_eng} language student having a conversation with an ${currentLanguage.name_eng}.
        ${prompt || ''}
        Your proficiency is roughly at CEFR level ${level?.CEFR_level || 'A1'}.
        Please only respond in ${currentLanguage.name_eng}, not in ${startingLanguage.name_eng}.
        Do not include "Teacher: ", "Customer: ", or any other prefix in your message. Only respond with a single sentence at a time.
      `,
    },
    ...messageHistory,
  ]

  console.log("Generated ai prompt", { messages })

  console.log('🏃 triggering edge function ai_prompt')
  const { data: responseData, error: responseError } = await supabase.functions
    .invoke('ai_prompt', { body: { messages } })
  if (responseError) throw responseError
  return responseData.promptResponse.content
}

export const getResponse = async ({
  startingLanguage,
  currentLanguage,
  conversation,
  conversationMessages = [],
  situation,
  currentUserLanguage,
  userGender,
}) => {
  const attemptResponse = async () => {
    const prompt = situation?.ai_prompt
    const level = proficiency_levels.find(level => level.value === currentUserLanguage?.proficiency_level) || proficiency_levels[0]

    const numMessagesToInclude = Math.min(MAX_HISTORY - conversationMessages.length, conversationMessages.length)
    const messageHistory = conversationMessages
      .slice(-1 * numMessagesToInclude)
      .filter(message => message.sender_type === 'assistant' || message.sender_type === 'user')
      .map(message => {
        return {
          role: message.sender_type,
          content: message.content,
        }})

    const character = situation?.character_id
    // TODO - const characterName = character.name_alts[currentLanguage.slug_eng] || character.name_default

    const messages = [
      {
        role: 'system',
        content: `
          You are ${character?.name_default}, an ${currentLanguage.name_eng} ${character?.gender}, ${character?.age} years old.
          You are ${character?.personality}.

          You are having a conversation with someone, an adult ${userGender || `person`}.
          You notice that their ${currentLanguage.name_eng} language ability is roughly equivalent to the CEFR level ${level?.CEFR_level || 'A1'}, so you try to kindly speak at that level.

          ${prompt || ''}
          Please only respond in ${currentLanguage.name_eng}, not in ${startingLanguage.name_eng}.
          Do not include "Teacher: ", "Customer: ", or any other prefix in your message. Only respond with a single sentence at a time.

          Here are some good examples:

          Their sentence: "Ciao, come stai?"
          Your response: "Sto bene, grazie. E tu?"

          Here are some examples of how NOT to respond:

          - "Buongiorno! Posso aiutarti a trovare la tua Ferrari perfetta? (Good morning! Can I help you find your perfect Ferrari?)" (should not include the translation)
          - "Turista: Scusa, dov'è il bagno più vicino? Emilio: Certo, vai dritto per questa strada e poi gira a sinistra al semaforo." (should not include two speakers, only one speaker)
        `,
      },
      ...messageHistory,
    ]

    console.log("Generated ai prompt", { messages })

    console.log('🏃 triggering edge function ai_prompt')
    const { data: responseData, error: responseError } = await supabase.functions
      .invoke('ai_prompt', { body: { messages } })
    if (responseError) throw responseError

    let responseContent = responseData.promptResponse.content.trim()

    // If the responseContent is a dialog with multiple participants, throw an error
    // Look for '[anyword]: "' at the beginning of the responseContent
    if (responseContent.match(/^[A-Za-z]+: /)) {
      throw new Error('ResponseContent includes speaker and colon (likely includes multiple participants)')
    }

    // If the responseContent contains parentheses, throw an error
    // Usually this means the responseContent contains an english translation
    // Ie, "Ciao, come stai? (Hi, how are you?)"
    if (responseContent.includes("(") && responseContent.includes(")")) {
      throw new Error('ResponseContent includes parentheses (likely includes a translation)')
    }

    // If the responseContent is wrapped in quotes, just remove them
    // https://stackoverflow.com/a/20196163/1061063
    if (responseContent.startsWith('"') && responseContent.endsWith('"')) {
      responseContent = responseContent.slice(1, -1)
    }

    const conversationReplyData = {
      conversation_id: conversation.id,
      content: responseContent,
      sender_type: 'assistant',
      sender_id: null,
    }
    const { error: conversationReplyError } = await supabase
      .from('conversation_messages')
      .insert([conversationReplyData])
    if (conversationReplyError) throw conversationReplyError
  }

  // just try it a couple times in case of error
  // https://stackoverflow.com/a/13239999/1061063
  let numTries = 0
  let maxTries = 2
  while(true) {
    try {
      await attemptResponse()
      return
    } catch (error) {
      // handle exception
      if (++numTries >= maxTries) throw error
    }
  }

}
