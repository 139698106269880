import Content from '@/consts/content'
import { useUser } from '@/context/user'
import { supabase, useSupabaseQuery } from '@/db/supabase'
import { createContext, useContext, useState } from 'react'

import type { Languages, UserLanguages } from '@/types/schema'

interface LanguageHookProps {
  loading: boolean
  error: Error
  userLanguages: UserLanguages[]
  startingLanguage: Languages
  currentLanguage: Languages
  currentUserLanguage: UserLanguages
  preferContentInL2: boolean
  setCurrentLanguageSlug: React.Dispatch<React.SetStateAction<string>>
  contentStrings: any
}

const Context = createContext({
  loading: true,
  error: null,
  userLanguages: [],
  startingLanguage: {},
  currentLanguage: {},
  currentUserLanguage: {},
  preferContentInL2: false,
  setCurrentLanguageSlug: (slug: string) => {},
  contentStrings: {},
} as LanguageHookProps)

const STARTING_LANGUAGE_SLUG = 'english' // TODO - could be different, later

export default ({ children }) => {
  const { userId } = useUser()

  const [ currentLanguageSlug, setCurrentLanguageSlug ] = useState()

  const SLQuery = supabase
    .from('languages')
    .select()
    .eq('slug_eng', STARTING_LANGUAGE_SLUG)
    .single()
  const [startingLanguage, SLLoading, SLError] = useSupabaseQuery(SLQuery)

  const CLQuery = supabase
    .from('languages')
    .select()
    .eq('slug_eng', currentLanguageSlug)
    .single()
  const [currentLanguage, CLLoading, CLError] = useSupabaseQuery(CLQuery, [currentLanguageSlug], !currentLanguageSlug)

  const ULQuery = supabase
    .from('user_languages')
    .select('*, language_id(*)')
    .eq('created_by', userId)
  const [userLanguages, ULLoading, ULError] = useSupabaseQuery(ULQuery, [userId], !userId)

  const loading = SLLoading || CLLoading || (ULLoading && userId) // if user is not logged in, ULLoading will "pause" and load forever
  const error = CLError || ULError || SLError

  const currentUserLanguage = !userLanguages ? {} : userLanguages.find(ul => ul.language_id.id === currentLanguage?.id)
  const preferContentInL2 = currentUserLanguage?.proficiency_level && currentUserLanguage?.proficiency_level >= 6

  const exposed = {
    loading,
    error,
    userLanguages: userLanguages || [],
    startingLanguage: startingLanguage || {},
    currentLanguage: currentLanguage || {},
    currentUserLanguage,
    preferContentInL2,
    setCurrentLanguageSlug,
    contentStrings: Content[currentLanguage?.slug_eng],
  }

  return <Context.Provider value={exposed}>
    {children}
  </Context.Provider>
}

export const useLanguage = () => useContext(Context)
