import moment from 'moment'
import { Link } from 'react-router-dom'

import { useUser } from '@/context/user'
import { supabase, useSupabaseRealtimeQuery } from '@/db/supabase'
import { ProgressBar, ProgressBarWrapper } from '@/styles/ProgressBar'

const DAILY_ATTEMPT_GOAL = 20

export default () => {

  const { userId } = useUser()

  const query = supabase
    .from('phrase_attempts')
    .select()
    .eq('created_by', userId)
    // TODO - filter by language
    // TODO - only return a count
  const [attempts, loading, error] = useSupabaseRealtimeQuery(
    query,
    [userId],
    !userId,
    {},
    {
      event: 'INSERT',
      schema: 'public',
      table: 'phrase_attempts',
      filter: `created_by=eq.${userId}`,
    },
  )

  // https://stackoverflow.com/a/47893042/1061063
  // TODO - do this in the query!
  const attemptsToday = attempts?.filter(attempt => !moment(attempt.created_at).isBefore(moment().startOf('day')))
  const attemptsTodayNum = attemptsToday?.length
  const percentToday = attemptsTodayNum && (attemptsTodayNum / DAILY_ATTEMPT_GOAL)

  return <div>
    {
    error ? error.message :
    !userId ? <>
      0/{DAILY_ATTEMPT_GOAL} today
      <ProgressBarWrapper disabled>
        <ProgressBar progress={0} />
      </ProgressBarWrapper>
      <Link to="/signup">Create an account</Link> to see your daily activity
    </> :
    loading ? <>
      .../{DAILY_ATTEMPT_GOAL} today
      <ProgressBarWrapper disabled>
        <ProgressBar progress={0} />
      </ProgressBarWrapper>
    </> :
    <>
      {attemptsTodayNum}/{DAILY_ATTEMPT_GOAL} today
      <ProgressBarWrapper disabled={!percentToday}>
        <ProgressBar progress={percentToday} />
      </ProgressBarWrapper>
    </>
    }
  </div>
}
