import moment from 'moment'
import { Link } from 'react-router-dom'

import { useLanguage } from '@/context/language'
import { supabase, useSupabaseQuery } from '@/db/supabase'
import AdminId from '@/styles/AdminId'
import SupabaseAdminLink from '@/views/admin/SupabaseAdminLink'
import ErrorPage from '@/views/errors/ErrorPage'

export default () => {
  const { currentLanguage } = useLanguage()

  const query = supabase
    .from('lesson_edits')
    .select('*, lesson_id(*), created_by(*)')
    .eq('language_id', currentLanguage.id)

  const [lessonEdits, loading, error] = useSupabaseQuery(query, [currentLanguage.id])

  if (error) return <ErrorPage error={error} />
  if (loading) return <div>loading...</div>
  if (!lessonEdits || lessonEdits.length <= 0) return <div>no lesson edits</div>

  return <>
    <h1>Lesson Edits</h1>
    {lessonEdits.length} lesson edit{lessonEdits.length === 1 ? '' : 's'}

    <hr />

    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Lesson ID</th>
          <th>Lesson</th>
          <th>Lesson L2</th>
          <th>Author</th>
          <th>Created</th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          lessonEdits.map(lessonEdit => {
            return <tr key={lessonEdit.id}>
              <td>
                <AdminId>{lessonEdit.id}</AdminId>
              </td>
              <td>
                <AdminId>{lessonEdit.lesson_id?.id}</AdminId>
              </td>
              <td>
                <Link to={`/${currentLanguage.slug_eng}/lessons/${lessonEdit.lesson_id?.random_slug}`}>{lessonEdit.lesson_id?.title_l1}</Link>
              </td>
              <td>
                {lessonEdit.lesson_id?.title_l2 || ''}
              </td>
              <td>
                { lessonEdit.created_by?.username ?
                  <Link to={`/u/${lessonEdit.created_by.username}`}>{lessonEdit.created_by.username}</Link> :
                  '❌ no username'
                }
              </td>
              <td>
                {moment(lessonEdit.created_at).fromNow()}
              </td>
              <td>
                <Link to={`/${currentLanguage.slug_eng}/lessons/${lessonEdit.lesson_id?.random_slug}/history/${lessonEdit.id}`}>View edit</Link>
              </td>
              <td>
                <Link to={`/${currentLanguage.slug_eng}/lessons/${lessonEdit.lesson_id?.random_slug}/edit`}>New edit</Link>
              </td>
              <td>
                <Link to={`/${currentLanguage.slug_eng}/lessons/${lessonEdit.lesson_id?.random_slug}/history`}>Full history</Link>
              </td>
              <td>
                <SupabaseAdminLink table="lesson_edits" id={lessonEdit.id} />
              </td>
            </tr>
          })
        }
      </tbody>
    </table>

  </>
}
