import styled from 'styled-components/macro'

import { useLanguage } from '@/context/language'
import useMediaQuery from '@/hooks/useMediaQuery'
import { AvatarCharacter } from '@/styles/Avatars'
import { Link } from 'react-router-dom'

export default ({ situation, small }) => {
  const { currentLanguage } = useLanguage()
  const isDesktop = useMediaQuery('(min-width: 800px)')
  if (!situation.published_at) return null

  return <Link
    to={`/${currentLanguage.slug_eng}/conversation/new/${situation.random_slug}`}
    style={{textDecoration: 'none'}}
  >
    <ConversationSituationWrapper
      $small={small}
      data-testid="conversation-situation-list-item"
      $mobile={!isDesktop}
    >
      <ConversationSituationImage src={`/images/conversation_backgrounds/${situation.background_file}`} alt={situation?.name_eng} />
      <ConversationSituationLayout $small={small}>
        <ConversationSituationHeader $small={small}>
          <h2>{situation.name_eng}</h2>
          <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem', fontSize: 'var(--s)'}}>
            <AvatarCharacter $width="2.5rem">
              <img src={`/images/conversation_avatars/victoruler/${situation?.character_id?.avatar_file}`} alt={situation?.character_id?.name} />
            </AvatarCharacter>
            {!small && <div>
              with {situation.character_id?.name_default}
            </div>}
          </div>
        </ConversationSituationHeader>
        {!small && isDesktop && <span className={`button button-full-width`}>
          See details →
        </span>}
      </ConversationSituationLayout>
      <ConversationOptionFade $color={situation.background_color || 'rgba(60,60,60,.9)'} />
    </ConversationSituationWrapper>
  </Link>
}

const ConversationSituationWrapper = styled.div`
  position: relative;
  /* width: 14.5rem; */
  height: ${p => p.$small ? '5rem' : p.$mobile ? '12rem' : '22rem'};
  padding: ${p => p.$small ? '0.5rem' : '1.5rem'};
  border: 0px solid;
  box-shadow: 2px 2px var(--shadow);
  text-decoration: none;
  overflow: hidden;
  transition: all .1s ease-out;
  cursor: pointer;
  border-radius: 8px;

  .button {
    border-color: black !important;
    background: white;
    box-shadow: 2px 2px var(--shadow);
    transition: all .1s ease-out;
  }
  &:hover {
    transform: translate(-1px, -1px);
    box-shadow: 3px 3px var(--shadow);
    .button {
      box-shadow: 3px 3px var(--shadow);
    }
  }
  &:active {
    transform: translate(1px, 1px);
    box-shadow: 1px 1px;
    .button {
      box-shadow: 0px 0px var(--shadow);
    }
  }
  @media (max-width: 600px) {
    width: 100%;
    max-height: 75vh;
    padding: 1rem;
  }
`
const ConversationSituationImage = styled.img`
  background: ${p => p.$image};
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`
const ConversationSituationLayout = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 200;
  justify-content: ${p => p.$small && 'center'};
`
const ConversationSituationHeader = styled.div`
  color: white;
  font-weight: 500;
  text-shadow: 1px 1px black;
  width: 100%;
  display: flex;
  flex-direction: ${p => p.$small ? 'row-reverse' : 'column'};
  gap: 0.75rem;
  align-items: ${p => p.$small ? 'center' : 'flex-start'};
  justify-content: ${p => p.$small ? 'flex-end' : 'flex-start'};
  h2 {
    font-size: ${p => p.$small ? '1rem' : '1.4rem'};
    font-weight: 700;
    margin: 0;
  }
`
const ConversationOptionFade = styled.div`
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 160px; // for smaller items
  background: ${({ $color }) => `linear-gradient(rgba(0,0,0,0) 0%, ${$color} 80%)`};
  z-index: 100;
`
