import Definable from '@/views/_components/Definable'
import * as Diff from 'diff'

export default ({
  correctAnswers,
  guess,
}) => {
  // TODO - pick answer thats closest to what they guessed
  const diff = Diff.diffWords(guess, correctAnswers[0])
  return <div style={{padding: '.5rem', background: 'var(--badge)', fontWeight: '500'}}>
    {diff.map((part, i) => {
      const value = part.value.trim()
      return <span key={i}>
        {
          (value === '?' || value === '!' || value === '.' || value === ',' || value === ';' || value === ':' || value === '...') ?
            value
          :
          part.added ?
            <span style={{color: 'green'}}>
              <Definable wordString={value} splitByWord />
            </span>
          :
          part.removed ?
            <span style={{color: 'red'}}>
              <Definable wordString={value} splitByWord strikethrough />
            </span>
          :
          part.value ?
            <Definable wordString={value} splitByWord />
          :
          null
        }
        {' '}
      </span>
    })}
  </div>
}
