import styled from 'styled-components/macro'

interface TypingIndicatorProps {
  inline?: boolean
}

export default ({ inline }: TypingIndicatorProps) => {
  return <TypingIndicatorWrapper $inline={inline}>
    <TypingDot />
    <TypingDot />
    <TypingDot />
  </TypingIndicatorWrapper>
}

const TypingIndicatorWrapper = styled.div`
  display: ${p => p.$inline ? 'inline-flex' : 'flex'};
  gap: .25rem;
  height: 1rem;
  align-items: center;
`
const TypingDot = styled.div`
  width: .33rem;
  height: .33rem;
  background: var(--text);
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1.5s infinite;

  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`