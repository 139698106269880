import moment from 'moment'
import { Link } from 'react-router-dom'

import { useLanguage } from '@/context/language'
import { useUser } from '@/context/user'
import { supabase, useSupabaseQuery } from '@/db/supabase'
import { Badge } from '@/styles/Badge'
import { MainWrapper } from '@/styles/Layout'

export default () => {
  const { currentLanguage } = useLanguage()
  const { userId } = useUser()

  const conversationsQuery = supabase
    .from('conversations')
    .select('*, situation_id(*)')
    .eq('language_id', currentLanguage.id)
    .eq('created_by', userId)
    .order('created_at', { ascending: false })
  const [conversations, conversationsLoading, conversationsError] = useSupabaseQuery(conversationsQuery, [currentLanguage.id, userId], (!userId || !currentLanguage.id))

  if (conversationsLoading) return <p>Loading...</p>
  if (conversationsError) return <p>Error: {conversationsError.message}</p>

  return <MainWrapper>
    <h1>Conversation history</h1>
    {!conversations?.length ? <p>No conversations yet</p> :
      <table>
        <tbody>
          {conversations?.map(conversation => {
            return <tr key={conversation.id}>
              <td>
                <Link key={conversation.id} to={`/${currentLanguage.slug_eng}/conversation/${conversation.random_slug}`}>
                  {conversation.situation_id?.name_eng}
                </Link>
              </td>
              <td>
                {(conversation.proficiency_level || conversation.proficiency_level === 0) ? <Badge>Level {conversation.proficiency_level}</Badge> : ''}
              </td>
              <td>
                {moment(conversation.created_at).fromNow()}
              </td>
            </tr>
          })}
        </tbody>
      </table>
    }
  </MainWrapper>
}
