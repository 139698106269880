import type { Languages } from '@/types/schema'
import { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import { useLanguage } from '@/context/language'
import { useUser } from '@/context/user'
import { supabase, useSupabaseRealtimeQuery } from '@/db/supabase'
import { logError } from '@/utils/error'

const MAX_SAVABLE_SEARCH_LENGTH = 3
const SEARCH_HISTORY_MAX_ITEMS = 10

interface ReferenceHookProps {
  queryText: string
  queryLanguage: Languages
  setQuery: (query: { text: string, language: Languages }) => void
  referenceIsOpen: boolean
  setReferenceIsOpen: (value: boolean) => void
  searchHistory: any[]
  removeSearchHistoryItem: (id: number) => void
  clearEntireSearchHistory: () => void
}

const Context = createContext({
  queryText: '',
  queryLanguage: null,
  setQuery: (query: { text: string, language: Languages }) => {},
  referenceIsOpen: false,
  setReferenceIsOpen: (value: boolean) => {},
  searchHistory: [],
  removeSearchHistoryItem: (id: number) => {},
  clearEntireSearchHistory: () => {},
} as ReferenceHookProps)

export default ({ children }) => {

  const { currentLanguage } = useLanguage()
  const { userId } = useUser()
  const location = useLocation()

  const [query, setQuery] = useState({ text: '', language: currentLanguage })
  const [referenceIsOpen, setReferenceIsOpen] = useState(false)

  const [queryDebounced] = useDebounce(query, 2000)

  const searchHistoryQuery = supabase
    .from('search_history_items')
    .select('*, query_language_id(*)')
    .eq('created_by', userId)
    .eq('language_id', currentLanguage?.id)
    .order('created_at', { ascending: false })
    .limit(SEARCH_HISTORY_MAX_ITEMS)
  const [searchHistory] = useSupabaseRealtimeQuery(
    searchHistoryQuery,
    [userId, currentLanguage?.id],
    (!userId || !currentLanguage?.id),
    {},
    {
      event: '*',
      schema: 'public',
      table: 'search_history_items',
      filter: `created_by=eq.${userId}`,
    },
  )

  useEffect(() => {
    const saveQueryToHistory = async () => {
      if (!userId) return
      if (!queryDebounced.text || queryDebounced.text.length < MAX_SAVABLE_SEARCH_LENGTH) return
      if (searchHistory[0]?.content === queryDebounced.text) {
        console.log('🔎 not saving to search history; matches most recent search', queryDebounced)
        return
      }
      try {
        console.log('🔎 saving to search history', queryDebounced)
        const { error } = await supabase
          .from('search_history_items')
          .insert([{
            language_id: currentLanguage?.id,
            query_language_id: queryDebounced.language?.id,
            content: queryDebounced.text,
          }])
        if (error) throw error
      } catch (error) {
        logError('save your search', error, true)
      }
    }
    saveQueryToHistory()
  }, [queryDebounced.text])

  useEffect(() => {
    setReferenceIsOpen(false)
  }, [location])

  useEffect(() => {
    console.log('📙 language change, reference query is now in', currentLanguage?.name_eng)
    setQuery({ ...query, language: currentLanguage })
  }, [currentLanguage?.name_eng])

  async function removeSearchHistoryItem(id: number) {
    if (!userId) return
    if (!id) return
    try {
      const { error } = await supabase
        .from('search_history_items')
        .delete()
        .eq('id', id)
        if (error) throw error
    } catch (error) {
      logError('delete this search history item', error)
    }
  }

  async function clearEntireSearchHistory() {
    if (!userId) return
    try {
      const { error } = await supabase
        .from('search_history_items')
        .delete()
        .eq('created_by', userId)
        if (error) throw error
    } catch (error) {
      logError('clear your search history', error)
    }
  }

  const exposed = {
    queryText: query.text,
    queryLanguage: query.language,
    setQuery,
    referenceIsOpen,
    setReferenceIsOpen,
    searchHistory,
    removeSearchHistoryItem,
    clearEntireSearchHistory,
  }

  return <Context.Provider value={exposed}>
    {children}
  </Context.Provider>
}

export const useReferencePanel = () => useContext(Context)
