import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ReactTextareaAutosize from 'react-textarea-autosize'

import { useUser } from '@/context/user'
import { supabase, useSupabaseQuery } from '@/db/supabase'
import { CheckboxGroup, RadioGroup } from '@/styles/RadioCheckbox'
import { logError } from '@/utils/error.js'

export default () => {
  const { userId, userProfile } = useUser()

  const [ avatarUrl, setAvatarUrl ] = useState('')
  const [ username, setUsername ] = useState('')
  const [ firstName, setFirstName ] = useState('')
  const [ bio, setBio ] = useState('')
  const [ genderPreference, setGenderPreference ] = useState('female')
  const [ languagesYouSpeak, setLanguagesYouSpeak ] = useState([])
  const [ loading, setLoading ] = useState(false)

  useEffect(() => setAvatarUrl(userProfile?.avatar_url || ''), [userProfile?.avatar_url])
  useEffect(() => setUsername(userProfile?.username || ''), [userProfile?.username])
  useEffect(() => setFirstName(userProfile?.first_name || ''), [userProfile?.first_name])
  useEffect(() => setGenderPreference(userProfile?.refer_to_as_gender || ''), [userProfile?.refer_to_as_gender])
  useEffect(() => setBio(userProfile?.bio || ''), [userProfile?.bio])

  const languagesQuery = supabase
    .from('languages')
    .select()
  const [languages, languagesLoading, languagesError] = useSupabaseQuery(languagesQuery)

  const languageOptionsSimple = !languages ? [] : languages.map(language => ({id: language.id, title: language.endonym, description: language.name_eng, }))
  const languageOptions = [...languageOptionsSimple, {id: 'other', title: 'Other'}]

  const onSubmit = async event => {
    event && event.preventDefault()
    if (!userId) return
    try {
      setLoading(true)

      const { error } = await supabase
        .from('user_profiles')
        .update({
          avatar_url: avatarUrl,
          username,
          first_name: firstName,
          bio,
          refer_to_as_gender: genderPreference,
          languages_spoken: languagesYouSpeak,
        })
        .eq('id', userId)
      if (error) throw error
      setLoading(false)
      alert('Saved!')
      location.reload()
    } catch (error) {
      setLoading(false)
      logError('update your profile', error)
    }
  }

  return <>
    <h1>Public profile</h1>

    {userProfile?.username && <>
      <Link to={`/u/${userProfile.username}`}>View live profile ↗</Link>
      <br />
    </>}

    <form onSubmit={onSubmit}>
      <label htmlFor="input_username">Username</label>
      <input
        id="input_username"
        type="text"
        value={username}
        placeholder="Username"
        onChange={(e) => setUsername(e.target.value)}
        required
      />

      <label htmlFor="input_avatar_url">Profile pic (optional)</label>
      <input
        id="input_avatar_url"
        type="text"
        value={avatarUrl}
        placeholder="https://example.com/avatar.png"
        onChange={(e) => setAvatarUrl(e.target.value)}
      />

      <label htmlFor="input_first_name">Your first name (optional)</label>
      <input
        id="input_first_name"
        type="text"
        value={firstName}
        placeholder="First name"
        onChange={(e) => setFirstName(e.target.value)}
      />

      <label htmlFor="input_bio">A little about you (optional)</label>
      <ReactTextareaAutosize
        id="input_bio"
        type="text"
        value={bio}
        placeholder="Share a little about yourself"
        onChange={(e) => setBio(e.target.value)}
      />

      <label>Refer to me as a...</label>
      <RadioGroup
        value={genderPreference}
        setValue={setGenderPreference}
        options={[
          {
            id: "female",
            title: "Woman",
            description: "Feminine",
          },
          {
            id: "male",
            title: "Man",
            description: "Masculine",
          },
        ]}
      />

      <label>What languages do you already speak? (optional)</label>
      <CheckboxGroup
        disabled={languagesLoading || languagesError}
        groupName="languagesYouSpeak"
        values={languagesYouSpeak}
        setValues={setLanguagesYouSpeak}
        options={languageOptions}
      />
      <p>We'll use this to help you find useful cognates, and may contact you about helping us develop new courses (unsubscribe anytime).</p>

      <br />

      <button type="submit" className="button" disabled={loading}>{loading ? 'Saving...' : 'Save'}</button>
    </form>
  </>
}
