import { Link } from 'react-router-dom'

import { useLanguage } from '@/context/language'
import { supabase, useSupabaseQuery } from '@/db/supabase'
import ErrorComponent from '@/views/errors/ErrorComponent'
import SaveButton from '@/views/saved/SaveButton'

export default ({ wordString }) => {

  const { currentLanguage } = useLanguage()

  const query = supabase
    .from('phrases')
    .select()
    .eq('language_id', currentLanguage.id)
    .ilike('content_l2', `%${wordString}%`)
    .order('id', { ascending: true })
  const [phrases, loading, error] = useSupabaseQuery(query, [wordString], !wordString)

  if (error) return <ErrorComponent error={error} />
  if (loading) return '...'
  if (!wordString || wordString.length <= 0) return '(none)' // incorrect param, ignore (prob loading)
  if (!phrases || phrases.length <= 0) return '(none)' // no phrases found, no need to show anything

  return <ul>
    {phrases.map( phrase => {
      return <li key={phrase.id}>
        <Link to={`/${currentLanguage.slug_eng}/phrase/${phrase.random_slug}`}>{phrase.content_l2}</Link>
        <SaveButton wordString={phrase.content_l2} />
      </li>
    })}
  </ul>
}
