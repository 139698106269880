import { useState } from 'react'
import AutosizeInput from 'react-18-input-autosize'
import styled from 'styled-components/macro'

import { supabase } from '@/db/supabase'
import { logError } from '@/utils/error'

export default ({ id, collection, attribute, type, value: defaultValue }) => {
  const [value, setValue] = useState(defaultValue)
  const [isEditing, setIsEditing] = useState(false)

  const onSubmit = async event => {
    try {
      event && event.preventDefault()
      console.log('saving admin value to db: ', { id, collection, attribute, type, value })
      const update = { [attribute]: value }
      console.log('update', update)
      const { error } = await supabase.from(collection).update(update).eq('id', id)
      if (error) throw error
      setIsEditing(false)
      // location.reload()
    } catch (error) {
      logError('edit admin value', error)
    }
  }

  // TODO - type long text and use ResizeTextarea
  return (
    <div>
      {isEditing ? (
        <form onSubmit={onSubmit}>
          {type === 'text' ? (
            <InputWrapper value={value} onChange={({ target }) => setValue(target.value)} />
          ) : type === 'number' ? (
            <InputWrapper value={value} type="number" onChange={({ target }) => setValue(parseInt(target.value))} />
          ) : type === 'boolean' ? (
            <input type="checkbox" checked={value} onChange={({ target }) => setValue(target.checked)} />
          ) : (
            '[No edit type]'
          )}
          <input type="submit" style={{ display: 'none' }} />
        </form>
      ) : (
        <EditTarget onClick={() => setIsEditing(true)}>
          {type === 'text'
            ? value
            : type === 'number'
            ? value
            : type === 'boolean'
            ? value
              ? '✔️'
              : '-'
            : '[No edit type]'}
        </EditTarget>
      )}
    </div>
  )
}

const EditTarget = styled.span`
  cursor: pointer;
  &:hover {
    background: var(--badge);
  }
`
const InputWrapper = styled(AutosizeInput)`
  input {
    padding: 2px;
    margin: -2px;
  }
`
