import { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import styled from 'styled-components/macro'

import { useUser } from '@/context/user'
import OrBar from '@/styles/OrBar'
import { logError } from '@/utils/error.js'
import { Helmet } from 'react-helmet-async'

export default () => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const { userId, signInWithOtp, signInWithGoogle } = useUser()

  const onSubmit = async event => {
    event.preventDefault()
    try {
      setLoading(true)
      await signInWithOtp(email)
    } catch (error) {
      logError('sign up', error)
    } finally {
      setLoading(false)
    }
  }

  if (userId) {
    return <Navigate to="/" />
  }

  // TODO
  // remove these page styles and use left panel wrapper, right panel wrapper,
  // etc (when we have something to show on the right panel for new signups)
  return <LoginLayoutWrapper>
    <Helmet>
      <title>Sign up | Protolang</title>
    </Helmet>
    <br />
    <LoginLayoutInner>
      <h1>Create your account</h1>
      {/* <p>We're excited to have you!</p> */}
      <br />
      <button
        className="button button-primary button-full-width"
        onClick={signInWithGoogle}
        disabled={loading}
      >
        <img src="/images/social_icons/google.svg" alt="Google" style={{height: '1rem', marginRight: '.25rem'}} />
        Sign up with Google
      </button>
      <OrBar />
      <form onSubmit={onSubmit}>
        <div className="input-button-set">
          <input
            type="email"
            placeholder="Your email"
            value={email}
            disabled={loading}
            onChange={e => setEmail(e.target.value)}
            required
          />
          <button
            className="button"
            disabled={loading}
            type="submit"
          >
            {loading ? "Signing up..." : "Sign up"}
          </button>
        </div>
      </form>
      <p style={{fontSize: 'var(--s)'}}>By signing up, you agree to our <Link to="/terms">Terms of Service</Link></p>
      <br />
      <p style={{textAlign: 'center', marginBottom: '-.5rem'}}><Link to="/support#log-in-trouble">Trouble signing up?</Link></p>
    </LoginLayoutInner>
    <br />
    <p style={{textAlign: 'center'}}>Already have an account? <Link to="/login">Log in</Link></p>
  </LoginLayoutWrapper>
}

const LoginLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 3rem;
`
const LoginLayoutInner = styled.div`
  width: 100%;
  max-width: 26rem;
  padding: 2rem;
  border: 1px solid;
  box-shadow: 2px 2px var(--shadow);

  @media (max-width: 600px) {
    padding: 0;
    border: none;
    box-shadow: none;

    input {
      width: auto;
    }
  }
`
