import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { OneCol } from '@/styles/Layout'
import { Helmet } from 'react-helmet-async'

export default () => {
  return <OneCol>

    <Helmet>
      <title>Blog | Protolang</title>
    </Helmet>

    <h1>Blog</h1>
    <p style={{fontSize: 'var(--s)'}}>1 article</p>

    <hr />

    <PostListItem to="/blog/why-online-language-learning-is-broken">
      <h2>Why online language learning is broken →</h2>
      <p style={{fontSize: 'var(--s)'}}>
        By Sam Pierce Lolla
        {' · '}
        May 1, 2023
      </p>
    </PostListItem>
  </OneCol>
}

const PostListItem = styled(Link)`
  text-decoration: none;

  &:hover h2 {
    text-decoration: underline;
  }
`
