import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

// header/footer layout
export const HeaderFooterLayoutWrapper = styled.div`
  display: grid;
  grid-template-rows: max-content auto max-content;
  min-height: 100vh;
  /*
    prevent horizontal scrollbars in some browsers...
    but breaks scrolling when admin makes the page big,
    and also position: sticky doesn't work with this on
  */
  /* overflow-x: hidden; */
`
export const Header = styled.header`
  display: grid;
  grid-template-columns: auto max-content;
  padding: 0 var(--gutter);
  /* I MISS THIS KIND OF */
  /* border-bottom: 1px solid; */
  margin: 0;
  height: 3rem;
  z-index: 100;
  background: var(--bg);

  & > * {
    display: flex;
    align-items: center;
  }
  @media (max-width: 800px) {
    padding: 0 1rem;
  }
`
export const MainNavLink = styled(NavLink)`
  padding: var(--leading) var(--leading);
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  &:hover {
    background: var(--hover);
  }
`
export const MainNavFakeLink = styled.button`
  padding: var(--leading) var(--leading);
  display: inline-flex;
  align-items: center;
  line-height: 1.5;
  text-decoration: none;
  &:hover {
    background: var(--hover);
  }
`
export const SecondaryNavLink = styled(NavLink)`
  padding: .5rem .25rem;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
export const SecondaryNavFakeLink = styled.button`
  padding: .5rem .25rem;
  display: inline-flex;
  align-items: center;
  line-height: 1.5;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
export const Main = styled.main`
  padding: calc(var(--leading) * 3) var(--gutter);
  display: flex;
  flex-direction: column;
`
export const MainWrapper = styled.main`
  margin: 0 auto;
  max-width: 1260px;
  height: ${p => (p.fullHeight ? `100%` : `auto`)};
`
export const Footer = styled.footer`
  padding: 2rem var(--gutter) var(--leading) var(--gutter);
  padding-bottom: ${p => p.$isMobile && '5rem'};

  // try not to go underneath the reference panel on small desktop screens
  max-width: ${p => p.$isMobile ? '100%' : 'calc(100% - 24rem)'};
`

// section layouts
export const OneCol = styled.div`
  max-width: ${p => p.$maxWidth || '50rem' };
  margin: ${p => p.$cool && '2rem auto' };
`
export const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: ${p => p.cols || `1fr 1fr`};
  grid-column-gap: ${p => (p.gap ? `${p.gap}rem` : p.gapForce ? p.gapForce : `10vw`)};
  grid-row-gap: ${p => (p.gap ? `${p.gap}rem` : p.gapForce ? p.gapForce : `10vw`)};
  flex: ${p => (p.tall ? `1` : `auto`)};
  margin: 0 auto;
  max-width: ${p => (p.$maxWidth || `none`)};
  /* height: ${p => (p.tall ? `100%` : `auto`)}; */
  /* min-height: ${p => (p.$tall ? `calc(100vh - 20rem)` : `auto`)}; */
  /* align-items: ${p => (p.$tall && `center`)}; */

  @media (max-width: 800px) {
    grid-template-columns: auto;
    grid-row-gap: 2rem;
  }
`
