import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { useUser } from '@/context/user'
import useMediaQuery from '@/hooks/useMediaQuery'
import { logError } from '@/utils/error.js'

export default ({ vertical, sayContinue }: {vertical?: boolean, sayContinue?: boolean}) => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const { userId, signInWithOtp, signInWithGoogle } = useUser()
  const isMobile = useMediaQuery('(max-width: 600px)')

  const onSubmit = async event => {
    event.preventDefault()
    try {
      setLoading(true)
      await signInWithOtp(email)
    } catch (error) {
      logError('sign up', error)
    } finally {
      setLoading(false)
    }
  }

  if (userId) {
    return <div style={{background: "var(--badge)", padding: "1rem", fontWeight: '500'}} data-testid="user-is-logged-in">
      ✓ You're on the waitlist!
    </div>
  }

  return <div>
    <HorizontalForm onSubmit={onSubmit} $vertical={isMobile || vertical}>
      <button
        type="button"
        className="button button-primary"
        onClick={signInWithGoogle}
        disabled={loading}
      >
        <img src="/images/social_icons/google.svg" alt="Google" style={{height: '1rem', marginRight: '.25rem'}} />
        {sayContinue ? "Continue with Google" : "Join waitlist with Google" }
      </button>
      <span className="or-separator">or</span>
      <div className="input-button-set">
        <input
          type="email"
          placeholder="Join with email"
          value={email}
          disabled={loading}
          onChange={e => setEmail(e.target.value)}
          required
        />
        <button
          className="button"
          disabled={loading}
          type="submit"
        >
          {loading ? "..." : "→"}
        </button>
      </div>
    </HorizontalForm>
    <p style={{fontSize: 'var(--s)'}}>By signing up, you agree to our <Link to="/terms">Terms of Service</Link></p>
  </div>
}

const HorizontalForm = styled.form`
  display: flex;
  flex-direction: ${props => props.$vertical ? 'column' : 'row'};
  gap: .5rem;
  align-items: ${props => props.$vertical ? 'stretch' : 'center'};
  margin: 0 0 .5rem;

  .input-button-set {
    margin: 0;
    max-width: ${props => props.$vertical ? '100%' : '260px'};
  }

  input {
    flex: 1;
    width: auto;
  }

  .or-separator {
    display: ${props => props.$vertical ? 'none' : 'block'};
  }
`
