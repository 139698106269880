import { useToast } from '@/context/toast'
import { useCopyToClipboard } from '@/hooks/useCopyText'
import { logError } from '@/utils/error'
import styled from 'styled-components/macro'

interface ShareLinksProps {
  title: string
  url: string
  align?: 'start' | 'center' | 'end'
}

export default ({
  title,
  url,
  align = 'start',
}: ShareLinksProps) => {
  if (!title) throw new Error('ShareLinks requires a title prop')
  if (!url) throw new Error('ShareLinks requires a url prop')

  return <ShareBoxWrapper $align={align}>
    <SocialLinks title={title} url={url} />
  </ShareBoxWrapper>
}

export const SocialLinks = ({ title, url }) => {
  if (!title) throw new Error('ShareLinks requires a title prop')
  if (!url) throw new Error('ShareLinks requires a url prop')
  const encodedTitle = encodeURIComponent(title)
  const encodedUrl = encodeURI(url)
  const copyLink = useCopyToClipboard()
  const { setToast } = useToast()

  const services = [
    {
      name: 'Twitter',
      icon: 'twitter',
      url: `http://twitter.com/share?text=${encodedTitle}&url=${encodedUrl}`,
    },
    {
      name: 'Reddit',
      icon: 'reddit',
      url: `http://www.reddit.com/submit?url=${encodedUrl}&title=${encodedTitle}`,
    },
    {
      name: 'Facebook',
      icon: 'facebook',
      url: `http://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&title=${encodedTitle}`,
    },
    {
      name: 'LinkedIn',
      icon: 'linkedin',
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`,
    },
    {
      name: 'Gmail',
      icon: 'gmail',
      url: `https://mail.google.com/mail/?view=cm&fs=1&su=${encodedTitle}&body=${encodedUrl}`,
    },
    // {
    //   name: 'Email',
    //   icon: 'noun-email-113149',
    //   url: `mailto:?subject=${encodedTitle}&body=${encodedUrl}`,
    // },
  ]
  return <SocialLinksWrapper>
    <SocialLinkItem
      key="copy-link"
      as="button"
      rel="noopener noreferrer"
      onClick={async () => {
        const wasCopied = await copyLink(url)
        if (wasCopied) {
          setToast('Link copied')
        } else {
          logError('copy the link to this page', new Error('Failed to copy link'))
        }
      }}
    >
      <img src={`/images/social_icons/noun-link-1755198.svg`} />
    </SocialLinkItem>
    {services.map(service => (
      <SocialLinkItem
        key={service.name}
        href={service.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={`/images/social_icons/${service.icon}.svg`} />
      </SocialLinkItem>
    ))}
  </SocialLinksWrapper>
}

const ShareBoxWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: ${props => props.$align || 'center'};

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const SocialLinksWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`
const SocialLinkItem = styled.a`
  opacity: .85;

  img {
    display: block;
    width: 2rem;
    height: 2rem;
  }

  &:hover {
    opacity: 1;
    transform: scale(1.05);
  }
  &:active {
    transform: scale(1);
  }
`
