// it would be nice if this actually worked someday
// but right now you still have to swap the table number id into the url
// https://github.com/orgs/supabase/discussions/15124

export default ({ table, id }) => {
  const projectId = import.meta.env.VITE_SUPABASE_PROJECT_ID
  return <a
    href={`https://app.supabase.com/project/${projectId}/editor/${table}?filter=id%3Aeq%${id}`}
    target="_blank"
    style={{opacity: 0.5}}
  >
    edit
  </a>
}
