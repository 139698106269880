import moment from 'moment'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useLanguage } from '@/context/language.js'
import { useUser } from '@/context/user'
import { supabase, useSupabaseQuery } from '@/db/supabase'
import Modal from '@/styles/Modal'
import { CheckboxGroup } from '@/styles/RadioCheckbox.js'
import { logError } from '@/utils/error.js'

export default () => {
  const { userId } = useUser()
  const { currentLanguage, currentUserLanguage } = useLanguage()

  const oldGoalDate = currentUserLanguage?.goal_date
  const oldTopics = currentUserLanguage?.goal_topics
  const oldGoal = currentUserLanguage?.goal

  const [newTopics, setTopics] = useState(oldTopics || [])
  const [newGoalDate, setGoalDate] = useState(oldGoalDate)
  const [newGoal, setGoal] = useState(oldGoal)
  const [isEditing, setIsEditing] = useState(false)
  const [saving, setSaving] = useState(false)

  const topicsQuery = supabase
    .from('topics')
    // .eq('user_selectable', true)
    .select()
  const [topicsList, topicsLoading, topicsError] = useSupabaseQuery(topicsQuery)

  let topicOptions = []
  if (topicsList) {
    topicOptions = topicsList.filter(topic => topic.user_selectable)
      .map(topic => ({
        id: topic.name_slug,
        title: topic.title_eng,
        description: topic.description_eng,
      }))
  }

  useEffect(() => {
    setGoalDate(oldGoalDate)
    setTopics(oldTopics || [])
    setGoal(oldGoal)
  }, [oldGoalDate, oldTopics])

  const onSubmit = async event => {
    event && event.preventDefault()
    if (!userId) return
    try {
      setSaving(true)
      const updates = {
        goal_topics: newTopics,
        goal: newGoal,
      }
      if (newGoalDate) {
        updates.goal_date = moment(newGoalDate).format()
      }
      const { error } = await supabase
        .from('user_languages')
        .update(updates)
        .eq('id', currentUserLanguage.id)
      if (error) throw error
      location.reload()
    } catch (error) {
      logError('update language goals', error)
    } finally {
      setSaving(false)
      setIsEditing(false)
    }
  }

  const disabledForm = topicsLoading || topicsError || saving || !userId

  return <>
    {userId && <button type="button" style={{fontSize: 'var(--s)'}} onClick={() => setIsEditing(true)}>edit</button>}

    <Modal
      title={`Choose your goals for ${currentUserLanguage?.name_eng || 'this language'}`}
      isOpen={isEditing}
      onClose={() => setIsEditing(false)}
    >

      <form onSubmit={onSubmit}>

        {!userId && <p style={{fontWeight: 'bold', color: 'red'}}>You must be <Link to={`/login?redirectTo=${encodeURIComponent(window.location.pathname)}`}>logged in</Link> to edit your goals.</p>}

        {/* <label>Word count goal</label>
        <RadioGroup
          value={newGoal}
          setValue={setGoal}
          options={[
            {
              id: "playing_around",
              title: "100 words",
              description: "Enough to impress your friends and use some basic polite phrases",
            },
            {
              id: "some",
              title: "1,000 words",
              description: `Enough to have basic conversations in ${currentLanguage.name_eng}`,
            },
            {
              id: "fluency",
              title: "10,000 words",
              description: "The same as a fluent speaker",
            },
          ]}
        /> */}

        <label htmlFor="topics">Areas of interest</label>
        <CheckboxGroup
          groupName="topics"
          values={newTopics}
          setValues={setTopics}
          options={topicOptions}
          disabled={disabledForm}
        />

        {/* <label htmlFor="topics">End date</label>
        <div className="input-button-set">
          <input
            id="goalDate"
            type="date"
            value={newGoalDate ? moment(newGoalDate).format('yyyy-MM-DD') : ''}
            onChange={(e) => setGoalDate(e.target.value)}
            disabled={disabledForm}
          />
          {newGoalDate &&
            <button className="button" type="button" disabled={disabledForm} onClick={() => {
              setGoalDate(null)
            }}>Clear</button>
          }
        </div> */}

        <hr />

        <button
          className="button"
          type="submit"
          disabled={disabledForm}
        >
          {saving ? 'Saving...' : 'Save goals'}
        </button>
      </form>

    </Modal>
  </>
}

