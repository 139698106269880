import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { useState } from 'react'
import yaml from 'yaml'

import { supabase, useSupabaseQuery } from '@/db/supabase'

const mnemonicsReadmeContent = `# Protolang mnemonics

This is a collection of mnemonics from [Protolang.com](https://protolang.com).

Generated ${new Date().toString()}

License information coming soon.
`

export default () => {

  // ready, working, done
  const [downloadingState, setDownloadingState] = useState('ready')

  const languagesQuery = supabase
    .from('languages')
    .select()
  const [languages, languagesLoading, languagesError] = useSupabaseQuery(languagesQuery)

  const downloadAll = async () => {

    setDownloadingState('working')

    // get mnemonics
    console.log('⬇️ getting mnemonics')
    const { data: mnemonics, error } = await supabase
      .from('mnemonics')
      .select()
      .order('language_id, id', { ascending: true })

    if (error) {
      alert('Sorry, could not download mnemonics right now. Try again later?')
      console.error(error)
      return
    }

    // generate object of markdown files
    console.log('⬇️ generating mnemonicsFiles')
    const mnemonicsFiles = mnemonics.map((mnemonic, index) => {
      const language = languages.find(lang => lang.id === mnemonic.language_id)
      const languageSlug = language.slug_eng
      const { target_phrase, remember_method } = mnemonic
      let metadata = { language: languageSlug, target_phrase, remember_method }
      // TODO - link attr like in phrases?
      if (mnemonic.attributions) {
        metadata.attributions = mnemonic.attributions
      }
      const yamlContent = yaml.stringify(metadata, {sortMapEntries: true})
      const indexPadded = index.toString().padStart(6, '0')
      return {
        name: `${indexPadded} - ${mnemonic.target_phrase || '[No target phrase]'}.yaml`,
        content: yamlContent,
      }
    })

    // TODO - append CC license here
    mnemonicsFiles.push({
      name: 'README.md',
      content: mnemonicsReadmeContent,
    })

    // make zip file
    console.log('⬇️ zipping mnemonicsFiles', mnemonicsFiles)
    const zip = new JSZip()
    mnemonicsFiles.forEach( (file, index) => zip.file(file.name, file.content))
    const zipFile = await zip.generateAsync({ type: 'blob' })

    // save file
    console.log('⬇️ saving mnemonicsFiles')
    saveAs(zipFile, `Protolang mnemonics.zip`)

    setDownloadingState('done')
  }

  if (languagesLoading) return <p>Loading...</p>
  if (languagesError) return <p>Error: {languagesError.message}</p>

  return <button disabled={downloadingState === 'working' || downloadingState === 'done'} onClick={downloadAll}>
    {
      downloadingState === 'ready' ? 'Download all mnemonics' :
      downloadingState === 'working' ? 'Downloading...' :
      downloadingState === 'done' ? 'Done!' :
      'Error'
    }
  </button>
}
