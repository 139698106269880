import styled from 'styled-components/macro'

import { useLanguage } from '@/context/language'
import Popover from '@/styles/Popover'

// TODO - keep popup open so you can add more characters?
// if so may need something like this to keep cursor position
// https://stackoverflow.com/a/57709964/1061063

export default ({
  trigger,
  inputElement,
}) => {
  const { currentLanguage } = useLanguage()

  const accentLetters = currentLanguage.accent_letters

  // https://stackoverflow.com/a/34278578/1061063
  const addText = (text: string) => {
    const [start, end] = [inputElement.selectionStart, inputElement.selectionEnd]
    inputElement.setRangeText(text, start, end, 'select')
    inputElement.focus()
  }

  return <>
    <Popover trigger={trigger} align="center">
      <VirtualKeyboardWrapper>
        {accentLetters.map(letter => {
          return (
            <button
              key={letter}
              className="button button-tertiary"
              onClick={() => addText(letter)}
            >
              {letter}
            </button>
          )
        })}
      </VirtualKeyboardWrapper>
    </Popover>
  </>
}

const VirtualKeyboardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: -.5rem;
`
