import { useCopyToClipboard } from '@/hooks/useCopyText'
import { logError } from '@/utils/error'
import { useState } from 'react'


export default ({ children }) => {
  const copyId = useCopyToClipboard()
  const [wasCopied, setWasCopied] = useState(false)

  if (typeof children !== 'string') throw new Error('AdminId children be a string')
  return <div
    style={{
      fontFamily: 'var(--font-family-mono)',
      transform: wasCopied ? 'scale(.95)' : 'none',
      cursor: 'pointer',
    }}
    onClick={async () => {
      const wasCopied = await copyId(children)
      if (wasCopied) {
        setWasCopied(true)
      } else {
        logError('copy this admin id', new Error('Failed to copy admin id link'))
      }
    }}
  >
    {children.slice(0, 8)}
  </div>
}
