import { useForm, ValidationError } from '@formspree/react'
import { useState } from 'react'

import { useUser } from '@/context/user'
import { SecondaryNavFakeLink, TwoColumns } from '@/styles/Layout'
import Modal from '@/styles/Modal'

// https://formspree.io/forms/xnqyjjgl/integration
// https://formspr.ee/react-help
export default () => {

  const { userAuth } = useUser()

  const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState(false)
  const [state, handleSubmit] = useForm("xnqyjjgl")

  return <>
    <SecondaryNavFakeLink onClick={() => setUpgradeModalIsOpen(true)}>Upgrade</SecondaryNavFakeLink>

    <Modal
      icon="rocket"
      title="Coming soon: Protolang Pro"
      isOpen={upgradeModalIsOpen}
      onClose={() => setUpgradeModalIsOpen(false)}
    >
      <p>Premium accounts are coming with some exciting new capabilities:</p>

      <ul>
        <li>Unlimited A.I. conversations</li>
        {/* <li>Polished, professional audio</li> */}
        <li>Advanced grammar correction</li>
        {/* <li>Live audio transcription</li> */}
        <li>Analytics to track your strengths and weaknesses</li>
      </ul>

      {/* <p>Because these features require paid data sources, they will be offered for a monthly or yearly subscription.</p> */}

      {/* <p><strong>Free lessons and flashcards are not going away.</strong> This is an optional upgrade for folks who want to support the project and get some extra polish.</p> */}

      <p>If you want to be notified when Pro features are released, please sign up below.</p>

      <p>Thank you for your support!</p>

      {state.errors && state.errors.length > 0 && <p>{state.errors[0]?.message}</p>}

      <form onSubmit={handleSubmit}>
        <input
          type="hidden"
          id="form"
          name="form"
          value="interested-in-upgrade"
        />
        <TwoColumns cols="auto max-content" gap=".5">
          <div>
            <label htmlFor="email">
              Your email
            </label>
            <input
              id="email"
              type="email" 
              name="email"
              placeholder="your@email.com"
              defaultValue={userAuth?.email || ''}
              required
              style={{width: '100%', margin: 0}}
            />
            <ValidationError 
              prefix="Email" 
              field="email"
              errors={state.errors}
            />
          </div>
          <button
            className="button"
            type="submit"
            disabled={state.submitting || state.succeeded}
            style={{alignSelf: 'end'}}
          >
            Join waitlist
          </button>
          {state.succeeded && <p style={{color: 'var(--bg)', background: 'var(--text)', fontWeight: 'bold', padding: '.5rem 1rem'}}>Thank you! You're on the waitlist—I'll let you know when we launch.</p> }
        </TwoColumns>

      </form>

    </Modal>
  </>
}
