import styled from 'styled-components/macro'

export const PanelsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  width: 100%;
  flex: 1;

  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 800px) {
    grid-template-columns: auto;
  }
`
export const LeftPanelWrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    padding: 0;
  }
`
export const LeftPanelInner = styled.div`
  max-width: 24rem;
  margin: 0 auto;
`
export const RightPanelWrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: #eee; */
  border-left: 1px solid;

  @media (max-width: 800px) {
    padding: 0;
    border: none;
  }
`
export const RightPanelInner = styled.div`
  max-width: 24rem;
  margin: 0 auto;
`
export const MoveOnChange = styled.div`
  opacity: 1;
  transition: all .3s ease-in-out;

  &[data-hidden="true"] {
    opacity: .5;
    transform: translateY(1px);
    transition: all 0s ease-in-out;
  }
`
