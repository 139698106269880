import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

import { MainWrapper, OneCol } from '@/styles/Layout'
import LoomVideo from '@/styles/LoomVideo'

export default () => {
  return <MainWrapper>
    <Helmet>
      <title>About | Protolang</title>
    </Helmet>
    <OneCol>

      <h1>About</h1>

      <p>
        Hi! Thanks for checking out Protolang :-)
      </p>

      <p>
        Protolang is a free language learning website.
        {' '}
        My goal is to help motivated language learners get to practical proficiency in their new language as quickly as possible.
      </p>

      <p>
        Questions/comments/feedback? <Link to="/contact">Contact me</Link>.
      </p>

      <p>
        - Sam
      </p>

      <LoomVideo id="ae393b1448524717bce13e1e13fa653c" />

      {/* <p>We believe that:</p>

      <ul>
        <li>
          Everyone deserves to learn and interact with their community, digitally and in person, in their native language
        </li>
        <li>
          Diversity of language creates diversity of thought, and diversity of thought creates stronger communities
        </li>
        <li>
          The world would be a better place if more people spoke the languages of other cultures
        </li>
        <li>
          We should preserve endangered languages for future generations
        </li>
        <li>
          Big challenges (like learning new languages) require powerful tools and supportive communities
        </li>
        <li>
          Learning things online should be free for anyone in the world
        </li>
        <li>
          Self-directed learning tools are key to the future of education
        </li>
        <li>
          Free content and open content create the best learning experiences
        </li>
        <li>
          Small, dispersed groups of passionate people can build amazing things
        </li>
      </ul> */}

      {/* <p>Protolang is run by <a href="https://directedworks.com/">Directed Works</a>.</p> */}

    </OneCol>
  </MainWrapper>
}
