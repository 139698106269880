import { useEffect, useState } from 'react'

import { useLanguage } from '@/context/language'
import useCorrectSpokenAnswer from '@/hooks/useCorrectSpokenAnswer'
import Icon from '@/styles/Icon'
import PulseRecorder from '@/styles/PulseRecorder'
import { getAltsWithContractions } from '@/utils/string'

export default ({
  direction,
  correctAnswers,
  submitAnswer,
  disabled,
  id,
}) => {

  const { startingLanguage, currentLanguage } = useLanguage()
  const recognitionLanguage = (!direction || direction === 'forward') ? currentLanguage : startingLanguage
  const correctAnswersWithAlts = correctAnswers.map(a => getAltsWithContractions(a, recognitionLanguage)).flat()
  const {
    speechRecognitionIsSupported,
    willShowMicPermissionsModal,
    setHasInteractedWithMic,
    startSpeechRecognition,
    stopSpeechRecognition,
    clearSpeechRecognition,
    recognitionState,
    interimTranscript,
    finalTranscript,
    correctStatus,
    userCorrectAnswer,
  } = useCorrectSpokenAnswer(correctAnswersWithAlts)

  const [ isListening, setIsListening ] = useState(false)

  if (!speechRecognitionIsSupported) {
    return `Your browser doesn't support speech recognition yet. Try Chrome?`
  }

  useEffect(() => {
    // clear it out when card reloads
    setIsListening(false)
    stopSpeechRecognition()
    clearSpeechRecognition()

    // stop listening and reset when closed
    return () => {
      setIsListening(false)
      clearSpeechRecognition()
      stopSpeechRecognition()
    }
  }, [id])

  useEffect(() => {
    if (isListening && recognitionState === 'waiting') {
      setIsListening(false)
    }
  }, [recognitionState])

  // test new transcripts
  useEffect( () => {
    if (!isListening) return // coming from another component, ignore

    if (correctStatus === 'correct') {
      setIsListening(false)
      stopSpeechRecognition()
      submitAnswer(userCorrectAnswer)
    }

    if (correctStatus === 'incorrect') {
      setIsListening(false)
      stopSpeechRecognition()
      submitAnswer(finalTranscript)
      return
    }
  }, [isListening, correctStatus])

  const toggleSpeech = () => {
    if (isListening) {
      setIsListening(false)
      stopSpeechRecognition()
    } else if (willShowMicPermissionsModal) {
      setHasInteractedWithMic(true)
    } else {
      setIsListening(true)
      startSpeechRecognition(recognitionLanguage.ietf_bcp_47)
    }
  }
  return <>
    {!disabled &&
      <button className="button button-full-width" onClick={toggleSpeech} disabled={disabled} style={{fontSize: '20px'}}>
        { isListening ? <><PulseRecorder /> Listening...</> : <><Icon name="mic" /> Click to speak</> }
      </button>
    }
    <code><strong>{finalTranscript}</strong> {interimTranscript}</code>
  </>
}
