import styled from 'styled-components/macro'

export const AvatarUser = styled.img`
  display: block;
  width: ${p => p.$width || '2.5rem'};
  height: ${p => p.$width || '2.5rem'};
  border-radius: 50%;
  border: 2px solid var(--text);
`

export const AvatarCharacter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  box-shadow: 1px 1px black;
  border-radius: 100%;
  width: ${p => p.$width || '3rem'};
  height: ${p => p.$width || '3rem'};
  background: white;
  overflow: hidden;

  img {
    width: 110%;
    height: 110%;
    margin-top: 25%;
  }
`
