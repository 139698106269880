import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { useLanguage } from '@/context/language'
import { useUser } from '@/context/user'
import ProgressAvatar from '@/views/_components/ProgressAvatar'
import Vocabulary from '@/views/dashboard/Vocabulary'

export default () => {
  const { userId, userProfile } = useUser()
  const { currentLanguage } = useLanguage()

  if (!userId) return null

  return <>
    <DashboardProfileSummaryWrapper to={`/${currentLanguage.slug_eng}/analytics`}>
      <ProgressAvatar src={userProfile?.avatar_url} />
      <div style={{lineHeight: '1.2'}}>
        {userProfile?.first_name || userProfile?.username || 'New user'}
        <div style={{fontSize: 'var(--s)'}}>
          <Vocabulary />
        </div>
      </div>
    </DashboardProfileSummaryWrapper>
  </>
}

const DashboardProfileSummaryWrapper = styled(Link)`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  text-decoration: none;
`
