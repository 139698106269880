import { Footer } from '@/styles/Layout'
import { FakeLink, Link } from '@/styles/Links'

import { useUser } from '@/context/user'
import useMediaQuery from '@/hooks/useMediaQuery'
import ToggleAlphaAccess from '@/views/_layout/components/ToggleAlphaAccess'

export default ({ minimal }:{ minimal?: boolean }) => {
  const isDesktop = useMediaQuery('(min-width: 800px)')
  const { userId } = useUser()

  return <Footer $isMobile={!isDesktop}>
    {/* <p style={{color: 'red', fontWeight: 'bold'}}>This site is in early alpha testing. YOUR PROGRESS WILL NOT BE SAVED.</p> */}
    {!minimal && <p>
      <Link to="/">Home</Link>
      {' · '}
      <Link to="/about">About</Link>
      {' · '}
      <Link to="/languages">Languages</Link>
      {' · '}
      {/* <Link to="/blog">Blog</Link>
      {' · '} */}
      {/* <Link to="/open-content">Open content</Link>
      {' · '} */}
      <Link to="/contribute">Contribute</Link>
      {' · '}
      <Link to="/contact">Contact</Link>
      {/* {' · '} */}
      {/* <Link to="/support">Support</Link> */}
    </p>}

    <p style={{fontSize: 'var(--s)', textAlign: minimal ? 'center' : 'left'}}>
      &copy; 2022{new Date().getFullYear()>2022 && ("-"+new Date().getFullYear())}
      {' '}
      <Link $plain as="a" href="https://directedworks.com/" target="_blank">Directed Works LLC</Link>
      {' · '}
      <Link $plain to="/conduct">Conduct</Link>
      {' · '}
      { userId &&
        <>
          <ToggleAlphaAccess>
            <FakeLink $plain>Join alpha</FakeLink>
          </ToggleAlphaAccess>
          {' · '}
        </>
      }
      <Link $plain to="/privacy">Privacy</Link>
      {' · '}
      <Link $plain to="/terms">Terms</Link>
      {' · '}
      <Link $plain to="/dmca">DMCA</Link>
    </p>
  </Footer>
}
