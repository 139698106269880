import { Navigate, Route, Routes as RouterRoutes } from 'react-router-dom'

import { useUser } from '@/context/user'
import redirects from '@/routes/redirects'
import routes from '@/routes/routes'
import ErrorBoundary from '@/views/_components/ErrorBoundary'
import LayoutSimple from '@/views/_layout/LayoutSimple'
import PageNotFound from '@/views/errors/PageNotFound'

export default () => {
  const { userId, userAuthLoading } = useUser()

  return <ErrorBoundary>
    <RouterRoutes>
      {routes.map(route => {
        return <Route
          key={route.path}
          path={route.path}
          element={
            <route.layout>
                {
                  // if you must be logged in for this route
                  (route.private_only && !userAuthLoading && !userId) ?
                  <Navigate to={`/login?redirectTo=${encodeURIComponent(window.location.pathname)}`} replace={true} /> :
                  <route.component />
                }
              </route.layout>
            }
        />
      })}

      {redirects.map(redirect => {
        return <Route
          key={redirect.path}
          path={redirect.path}
          element={ <Navigate to={redirect.target} /> }
        />
      })}

      <Route
        key="*"
        path="*"
        element={
          <LayoutSimple>
            <PageNotFound />
          </LayoutSimple>
        }
      />

    </RouterRoutes>
  </ErrorBoundary>
}
