import { createClient } from '@supabase/supabase-js'
import { useEffect, useState } from 'react'

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

export const supabaseDictionaries = createClient(supabaseUrl, supabaseAnonKey, {
  db: { schema: 'dictionaries' }
})

// https://supabase.com/docs/reference/javascript/select
// https://supabase.com/docs/reference/javascript/using-filters
// https://supabase.com/docs/reference/javascript/using-modifiers
// TODO - are dependencies and pause the same thing? Why run w/o dependencies?
// TODO - let modules who call this function pass in a type for the data returned,
//        and replace "any" with that type. <foo> syntax?
export function useSupabaseQuery(query, dependencies?: any[], pause?: boolean, options?: any):[any, boolean, Error] {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    // TODO - does this fire too much?
    const runQuery = async () => {
      let myQuery = query

      if (options?.page || options?.numResults || options?.page === 0 || options?.numResults === 0) {
        const page = options?.page || 0
        const numResults = options?.numResults || 10
        const { from, to } = getPagination(page, numResults - 1)

        // Will range break if we're not using bigint id?
        // Should we use greater than [sort method] instead? ie return all greater than last item, limit pageMax?
        myQuery = query.range(from, to)
      }

      const { data, error } = await myQuery
      setLoading(false)
      setData(data)
      setError(error)
    }
    !pause && runQuery()
    return () => {
      setData(null)
      setLoading(true)
      setError(null)
    }
  }, dependencies || [])

  return [
    data,
    loading,
    error,
  ]
}

// https://supabase.com/docs/reference/javascript/select
// https://supabase.com/docs/reference/javascript/using-filters
// https://supabase.com/docs/reference/javascript/using-modifiers
// TODO - are dependencies and pause the same thing? Why run w/o dependencies?
// TODO - let modules who call this function pass in a type for the data returned,
//        and replace "any" with that type. <foo> syntax?
export function useSupabaseRealtimeQuery(query, dependencies: any[], pause: boolean, options: any, realtimeOptions: any):[any, boolean, Error] {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const [hasRunAtLeastOnce, setHasRunAtLeastOnce] = useState(false)

  // TODO - does this fire too much?
  const runQuery = async () => {
    let myQuery = query

    if (options?.page || options?.numResults || options?.page === 0 || options?.numResults === 0) {
      const page = options?.page || 0
      const numResults = options?.numResults || 10
      const { from, to } = getPagination(page, numResults - 1)

      // Will range break if we're not using bigint id?
      // Should we use greater than [sort method] instead? ie return all greater than last item, limit pageMax?
      myQuery = query.range(from, to)
    }

    const { data, error } = await myQuery
    setLoading(false)
    setData(data)
    setError(error)
    setHasRunAtLeastOnce(true)
  }

  // re-run whenever there are dependency changes
  useEffect(() => {
    !pause && runQuery()
    return () => {
      setData(null)
      setLoading(true)
      setError(null)
    }
  }, dependencies || [])

  // rerun whenever there's a realtime update
  // but don't set loading to true or anything, that will cause jitters
  // TODO - don't run the whole query again either, just add the new stuff to "data"
  useEffect(() => {
    if (!hasRunAtLeastOnce) return
    const randomNumber = Math.floor(Math.random() * 1000)
    const channelName = `channel-${realtimeOptions?.table}-${randomNumber}`
    const channel = supabase
      .channel(channelName)
      .on('postgres_changes', realtimeOptions, !pause && runQuery)
      .subscribe()
    return () => {
      channel.unsubscribe()
    }
  }, [hasRunAtLeastOnce])

  return [
    data,
    loading,
    error,
  ]
}

// https://github.com/orgs/supabase/discussions/1223#discussioncomment-641447
export const getPagination = (page: number, size: number) => {
  const limit = size ? +size : 3;
  const from = page ? page * limit : 0;
  const to = page ? from + size : size;
  return { from, to };
};
