import { useState } from 'react'
import ReactTextareaAutosize from 'react-textarea-autosize'

import { useLanguage } from '@/context/language'
import { useUser } from '@/context/user'
import { supabase } from '@/db/supabase'
import Icon from '@/styles/Icon'
import Modal from '@/styles/Modal'
import { Phrases } from '@/types/schema'
import { logError } from '@/utils/error.js'
import TranslateHelper from '@/views/_components/TranslateHelper.js'

export default ({ phrase, float }:{ phrase: Phrases, float?: boolean }) => {

  const { userId } = useUser()
  const { startingLanguage, currentLanguage } = useLanguage()

  const [phraseEditorIsOpen, setPhraseEditorIsOpen] = useState(false)
  const [contentL2, setContentL2] = useState(phrase.content_l2 || '')
  const [contentL1, setContentL1] = useState(phrase.content_l1 || '')
  const [L2Alts, setL2Alts] = useState(phrase.content_l2_alts || [])
  const [L1Alts, setL1Alts] = useState(phrase.content_l1_alts || [])
  const [context, setContext] = useState(phrase.context_l1 || '')
  const [saving, setSaving] = useState(false)

  // ********************************************************************************* //
  // ********************************************************************************* //
  // KEEP THIS IN SYNC WITH THE PHRASE NEW AND IMPORT FUNCTIONS THAT DO THE SAME THING //
  // ********************************************************************************* //
  // ********************************************************************************* //

  const slugifyPhraseContent = ({contentL1, L1Alts, contentL2, L2Alts}) => {
    const L1_alts_lower = L1Alts ? L1Alts.map(alt => alt.toLowerCase()) : []
    const L2_alts_lower = L2Alts ? L2Alts.map(alt => alt.toLowerCase()) : []
    return {
      slugsL1: [ contentL1.toLowerCase(), ...L1_alts_lower ],
      slugsL2: [ contentL2.toLowerCase(), ...L2_alts_lower ],
    }
  }
  
  async function submit( event ) {
    event.preventDefault()
    try {
      setSaving(true)
      const { slugsL1, slugsL2 } = slugifyPhraseContent({ contentL1, L1Alts, contentL2, L2Alts })
      const { error } = await supabase
        .from('phrases')
        .update({
          content_l2: contentL2,
          content_l1: contentL1,
          content_l2_alts: L2Alts,
          content_l1_alts: L1Alts,
          context_l1: context,
          slugs_l1: slugsL1,
          slugs_l2: slugsL2,
        })
        .eq('id', phrase.id)
      if (error) throw error
      setPhraseEditorIsOpen(false)
    } catch (error) {
      setSaving(false)
      logError('edit phrase', error)
    }
  }

  const updateAlt = (lang, { index, value }) => {
    const originalArray = lang === 'lang_L2' ? L2Alts : L1Alts
    const updateFunction = lang === 'lang_L2' ? setL2Alts : setL1Alts
    const newArray = [...originalArray]
    newArray[index] = value
    updateFunction(newArray)
  }

  const withoutArticlesL2 = contentL2
    .replace("l'", '')
    .replace('il ', '')
    .replace('lo ', '')
    .replace('la ', '')
    .replace('le ', '')
    .replace('un ', '')
    .replace('uno ', '')
    .replace('una ', '')
    .replace('gli ', '')
    .replace('i ', '')

  const withoutArticleL1 = contentL1
    .replace('the ', '')
    .replace('a ', '')

  if (!userId) return null

  return <>
    <button type="button" onClick={() => setPhraseEditorIsOpen(true)} style={{float: float ? 'right' : 'none'}}>edit</button>

    <Modal
      title="Edit phrase"
      isOpen={phraseEditorIsOpen}
      onClose={() => setPhraseEditorIsOpen(false)}
      width='460px'
    >
      <form onSubmit={submit} key={String(phrase.id)}>
        <label htmlFor="contentL2">{currentLanguage.name_eng}</label>
        <ReactTextareaAutosize
          style={{height: 50}}
          id="contentL2"
          value={contentL2}
          placeholder=""
          minRows={1}
          onChange={e => setContentL2(e.target.value)}
          required
        />
        <TranslateHelper contentL1={contentL1} onNewTranslation={setContentL2} />

        {L2Alts?.map((alt, index) => {
          return <div key={index} style={{display: 'flex'}}>
            <ReactTextareaAutosize
              style={{height: 50}}
              value={alt}
              minRows={1}
              onChange={e => updateAlt('lang_L2', { index, value: e.target.value})}
            />
            {/* https://stackoverflow.com/a/47024021 */}
            <button type="button" className="button button-tertiary" onClick={() => setL2Alts([...L2Alts.slice(0, index), ...L2Alts.slice(index + 1)])}>
              <Icon name="delete" />
            </button>
          </div>
        })}
        <button type="button" className="button button-small" onClick={() => setL2Alts([...L2Alts, ''])}>Add alternate</button>
        <button type="button" className="button button-small" onClick={() => setL2Alts([...L2Alts, withoutArticlesL2 ])}>Add w/o articles</button>

        <p style={{fontSize: 'var(--s)'}}>No need to add alternatives for contractions ("I'll" vs "I will") or pronouns ("he", "she", "it", "you", "you all"); these are handled by the system automatically.</p>

        <hr />

        <label htmlFor="contentL1">{startingLanguage.name_eng}</label>
        <ReactTextareaAutosize
          style={{height: 50}}
          id="contentL1"
          value={contentL1}
          placeholder=""
          minRows={1}
          onChange={e => setContentL1(e.target.value)}
          required
        />
        <TranslateHelper contentL2={contentL2} onNewTranslation={setContentL1} />

        {L1Alts?.map((alt, index) => {
          return <div key={index} style={{display: 'flex'}}>
            <ReactTextareaAutosize
              style={{height: 50}}
              value={alt}
              minRows={1}
              onChange={e => updateAlt('lang_L1', { index, value: e.target.value})}
            />
            {/* https://stackoverflow.com/a/47024021 */}
            <button type="button" className="button button-tertiary" onClick={() => setL1Alts([...L1Alts.slice(0, index), ...L1Alts.slice(index + 1)])}>
              <Icon name="delete" />
            </button>
          </div>
        })}
        <button type="button" className="button button-small" onClick={() => setL1Alts([...L1Alts, ''])}>Add alternate</button>
        <button type="button" className="button button-small" onClick={() => setL1Alts([...L1Alts, withoutArticleL1 ])}>Add w/o article</button>

        <p style={{fontSize: 'var(--s)'}}>Please add definite pronouns to nouns (i.e., use "il conto", not "conto"). The system will still automatically accept "conto" when students run a flashcard.</p>

        <hr />

        <label htmlFor="context">Context</label>
        <p style={{fontSize: 'var(--s)'}}>When is this used? Any other notes? (Use {startingLanguage.name_eng})</p>
        <ReactTextareaAutosize
          style={{height: 50}}
          id="context"
          value={context}
          placeholder=""
          minRows={1}
          onChange={e => setContext(e.target.value)}
        />

        <hr />

        <button className="button" type="submit" disabled={saving}>
          {saving ? 'Saving...' : 'Save phrase'}
        </button>
      </form>
    </Modal>
  </>
}
