import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { useState } from 'react'
import yaml from 'yaml'

import { supabase } from '@/db/supabase'

const charactersReadmeContent = `# Protolang characters

This is a collection of characters that you can chat with in [Protolang.com](https://protolang.com).

Generated ${new Date().toString()}

License information coming soon.
`

export default () => {

  // ready, working, done
  const [downloadingState, setDownloadingState] = useState('ready')

  const downloadAll = async () => {

    setDownloadingState('working')

    // get characters
    console.log('⬇️ getting characters')
    const { data: characters, error } = await supabase
      .from('conversation_characters')
      .select('*')
      .order('age, id', { ascending: true })

    if (error) {
      alert('Sorry, could not download characters right now. Try again later?')
      console.error(error)
      return
    }

    // generate object of markdown files
    console.log('⬇️ generating characterFiles')
    const characterFiles = characters.map((character, index) => {
      const { name_slug, name_default, age, gender, avatar_file, personality } = character
      let metadata = { name_slug, name_default, age, gender, avatar_file, personality }
      const yamlContent = yaml.stringify(metadata, {sortMapEntries: true})
      const indexPadded = index.toString().padStart(6, '0')
      return {
        name: `${indexPadded} - ${character.name_default || '[No name]'}.yaml`,
        content: yamlContent,
      }
    })

    // TODO - append CC license here
    characterFiles.push({
      name: 'README.md',
      content: charactersReadmeContent,
    })

    // make zip file
    console.log('⬇️ zipping characterFiles', characterFiles)
    const zip = new JSZip()
    characterFiles.forEach( (file, index) => zip.file(file.name, file.content))
    const zipFile = await zip.generateAsync({ type: 'blob' })

    // save file
    console.log('⬇️ saving characterFiles')
    saveAs(zipFile, `Protolang characters.zip`)

    setDownloadingState('done')
  }

  return <button disabled={downloadingState === 'working' || downloadingState === 'done'} onClick={downloadAll}>
    {
      downloadingState === 'ready' ? 'Download all characters' :
      downloadingState === 'working' ? 'Downloading...' :
      downloadingState === 'done' ? 'Done!' :
      'Error'
    }
  </button>
}
