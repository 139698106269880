import * as Popover from '@radix-ui/react-popover'
import styled from 'styled-components/macro'

import useMediaQuery from '@/hooks/useMediaQuery'

interface PopoverProps {
  trigger: React.ReactNode
  children: React.ReactNode
  align?: 'start' | 'center' | 'end'
  minWidth?: string
}

export default ({
  trigger,
  children,
  align = 'start',
  minWidth = 'auto',
}: PopoverProps) => {
  const isDesktop = useMediaQuery('(min-width: 800px)')
  return <Popover.Root>
    <Popover.Trigger asChild={true}>{trigger}</Popover.Trigger>
    <Popover.Portal>
      <DropdownContentWrapper
        align={align}
        style={{ minWidth }}
        $isMobile={!isDesktop}
      >
        {/* <Popover.Arrow /> */}
        {/* <Popover.Close>✖️</Popover.Close> */}
        {children}
      </DropdownContentWrapper>
    </Popover.Portal>
  </Popover.Root>
}

const DropdownContentWrapper = styled(Popover.Content)`
  position: relative;
  z-index: 500;
  background: var(--bg);
  padding: 1rem;
  border: 1px solid;
  box-shadow: 2px 2px var(--shadow);
  width: ${p => p.$isMobile && '100vw'};
  box-shadow: ${p => p.$isMobile && '0 4px var(--shadow)'};
`
