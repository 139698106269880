import { BreadcrumbItem, BreadcrumbSeparator, BreadcrumbWrapper } from '@/styles/Breadcrumbs'
import * as Diff from 'diff'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'

import { supabase, useSupabaseQuery } from '@/db/supabase'
// import LessonContent from '@/views/lessons/LessonContent'
import { useLanguage } from '@/context/language'
import { TwoColumns } from '@/styles/Layout'

export default () => {
  const { lessonSlug, editId } = useParams()
  const { currentLanguage } = useLanguage()

  const random_slug = lessonSlug.split('-').pop()

  const lessonQuery = supabase
    .from('lessons')
    .select()
    .eq('random_slug', random_slug)
    .single()
  const [lesson, lessonLoading, lessonError] = useSupabaseQuery(lessonQuery, [random_slug])

  const lessonEditQuery = supabase
    .from('lesson_edits')
    .select('*, created_by(*)')
    .eq('id', editId)
    .single()
  const [lessonEdit, lessonEditLoading, lessonEditError] = useSupabaseQuery(lessonEditQuery)

  const prevLessonEditQuery = supabase
    .from('lesson_edits')
    .select()
    .eq('lesson_id', lesson?.id)
    .lt('created_at', lessonEdit?.created_at)
    .order('created_at', { ascending: false })
    .limit(1)
    .single()
  const [prevLessonEdit, prevLessonEditLoading, prevLessonEditError] = useSupabaseQuery(prevLessonEditQuery, [lessonEdit], (!lessonEdit || !lesson))

  if (lessonError) return "Error: lessonError - " + lessonError.message
  if (lessonEditError) return "Error: lessonEditError - " + lessonEditError.message
  if (lessonEditLoading || lessonLoading || prevLessonEditLoading) return 'loading...'

  let diffContent, diffVocab
  if (lessonEdit && prevLessonEdit) {
    diffContent = Diff.diffLines(prevLessonEdit.content_l1, lessonEdit.content_l1) 
    diffVocab = Diff.diffLines(prevLessonEdit.vocab_l1, lessonEdit.vocab_l1)
  }

  return <>
    <BreadcrumbWrapper>
      <BreadcrumbItem to={`/${currentLanguage.slug_eng}`}>Home</BreadcrumbItem>
      <BreadcrumbSeparator />
      <BreadcrumbItem to={`/${currentLanguage.slug_eng}/lessons/${random_slug}`}>{lesson?.title_l1}</BreadcrumbItem>
      <BreadcrumbSeparator />
      <BreadcrumbItem to={`/${currentLanguage.slug_eng}/lessons/${random_slug}/history`}>History</BreadcrumbItem>
      <BreadcrumbSeparator />
      {lessonEdit?.id}
    </BreadcrumbWrapper>

    <h1>Edit #{lessonEdit?.id}</h1>
    Created {moment(lessonEdit?.created_at).format("MMMM Do, YYYY")}
    {' '}
    by
    {' '}
    { lessonEdit.created_by?.username ?
      <Link to={`/u/${lessonEdit.created_by.username}`}>{lessonEdit.created_by.username}</Link> :
      'anon'
    }

    <hr />

    <TwoColumns cols="1fr 1fr">
      <div style={{fontFamily: 'monospace', whiteSpace: 'break-spaces'}}>
        {diffContent?.map((part, i) => <div key={i}>
          {
            part.added ? '' :
            part.removed ? <span style={{fontWeight: 'bold', color: 'red', background: 'hsla(0, 80%, 60%, .1)'}}>{part.value}</span> :
            part.value
          }
        </div>)}
        <hr />
        {diffVocab?.map((part, i) => <div key={i}>
          {
            part.added ? '' :
            part.removed ? <span style={{fontWeight: 'bold', color: 'red', background: 'hsla(0, 80%, 60%, .1)'}}>{part.value}</span> :
            part.value
          }
        </div>)}
        {/* {prevLessonEdit?.content_l1 || ''} */}
      </div>
      <div style={{fontFamily: 'monospace', whiteSpace: 'break-spaces'}}>
        {diffContent?.map((part, i) => <div key={i}>
          {
            part.added ? <span style={{fontWeight: 'bold', color: 'green', background: 'hsla(120, 60%, 60%, .1)'}}>{part.value}</span> :
            part.removed ? '' :
            part.value
          }
        </div>)}
        {/* ERRORS */}
        {/* <hr />
        {diffVocab?.map((part, i) => <div key={i}>
          {
            part.added ? <span style={{fontWeight: 'bold', color: 'green', background: 'hsla(120, 60%, 60%, .1)'}}>{part.value}</span> :
            part.removed ? '' :
            part.value
          }
        </div>)} */}

        {/* when this is the first edit */}
        {(!diffContent || !diffVocab) && lessonEdit?.content_l1 || ''}
      </div>
    </TwoColumns>

    <hr />

    {/* <TwoColumns cols="1fr 1fr">
      <div>
        <LessonContent content={prevLessonEdit?.content_l1 || ''} />
      </div>
      <div>
        <LessonContent content={lessonEdit?.content_l1 || ''} />
      </div>
    </TwoColumns> */}
  </>
}
