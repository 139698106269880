import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

export const LandingPageWrapper = styled.div`
  margin: 0 auto;
  padding: 3vh 3vw;
  max-width: 1400px;

  @media (max-width: 600px) {
    padding: 0;
  }
`

// HERO

export const HeroWrapper = styled.div`
  padding: 4rem 0 3rem;
  display: flex;
  flex-direction: ${p => p.$small ? 'column' : 'row'};
  gap: 2rem;
  align-items: ${p => p.$small ? 'flex-start' : 'center'};
  justify-content: space-between;
  border-bottom: ${p => p.$border ? '1px solid' : 'none'};

  h1 {
    font-size: ${p => p.$small ? '2.5rem' : '3rem'};
  }
  @media (max-width: 800px) {
    flex-direction: column;

    h1 {
      font-size: 2.5rem;
    }
  }
`

export const HeroImage = styled.picture`
  width: 40%;
  img {
    width: 100%;
  }
  @media (max-width: 1000px) {
    width: 100%;
    margin: 0 auto;
  }
  @media (max-width: 800px) {
    width: 100%;
    margin: 0 auto;
    max-width: 500px;
  }
`

// BENEFITS

export const BenefitBlock = styled.div`
  display: grid;
  align-items: center;
  /* grid-template-columns: 1fr; */
  /* grid-gap: 2rem; */
  padding: 2rem 0;
  /* min-height: 200px; */

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }

  &:nth-child(even) > div:first-child {
    /* order: 1; */
  }

  h2 {
    font-size: var(--xl);
    margin: 0 0 .5rem 0;
  }
`
export const BenefitBlockIllustration = styled.img`
  width: 100%;
  /* display: none; // temporarily hide illustrations */
  /* box-shadow: 2px 2px var(--shadow); */
`

// SCREENSHOT

export const ScreenshotImage = styled.img`
  width: 100%;
  display: block;
  margin: 0 0 2rem;
  /* border: 1px solid; */
  /* box-shadow: 3px 3px var(--shadow); */
`

// STEPS

export const StepBlockWrapper = styled.ul`
  // https://css-tricks.com/css-counters-custom-list-number-styling/
  counter-reset: big-list-counter;
  margin: 0;
  padding: 0;
`
export const StepBlock = styled.li`
  position: relative;
  margin: 2rem 0;
  padding: 0 0 0 2rem;
  list-style: none;
  counter-increment: big-list-counter;

  display: grid;
  align-items: center;
  grid-template-columns: 1fr; // temporarily hide illustrations

  &:before {
    content: counter(big-list-counter);
    font-family: var(--font-family-sans);
    position: absolute;
    top: 0;
    left: 0;
    font-size: var(--m);
    font-weight: 500;
    width: 1rem;
    height: 1rem;
    line-height: 1.2rem;
    text-align: center;
    /* border: 1px solid; */
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  h3 {
    font-size: var(--l);
    margin: 0 0 .5rem 0;
  }
  ul {
    font-size: var(--s);
    padding: 0 0 0 1rem;
  }
`

// FEATURES

export const FeatureBlocksWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  margin: 2rem 0 4rem;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`
export const FeatureBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: .5rem;

  h3 {
    /* font-size: var(--l); */
    margin: .5rem 0 .25rem;
  }
`
export const FeatureBlockIconWrapper = styled.div`
  /* background: var(--badge); */
  display: inline-block;
  padding: .25rem 0;
`

export const HomeBox = styled.div`
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem; */
  padding: 2rem;
  margin: 2rem 0 3rem;
  border: 1px solid;
  box-shadow: 2px 2px var(--shadow);

  h2, h3, h4 {
    margin: 0 0 1rem 0;
  }

  /* @media (max-width: 800px) {
    grid-template-columns: 1fr;
  } */
`

export const BlogLink = styled(Link)`
  font-size: var(--xl);
  display: block;
  text-decoration: none;
  font-weight: 500;
  margin: 0 0 1rem 0;

  &:hover {
    text-decoration: underline;
  }
`

// MISC

export const CtaWrapper = styled.div`
  position: sticky;
  padding-top: 4rem;
  top: 0;
`
