import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { useUser } from '@/context/user'
import { supabase, useSupabaseQuery } from '@/db/supabase'
import { MainWrapper, TwoColumns } from '@/styles/Layout'
import Flights from '@/views/dashboard/Flights'
import News from '@/views/dashboard/News'
import Quote from '@/views/dashboard/Quote'
// import Welcome from '@/views/dashboard/Welcome'
import Weather from '@/views/dashboard/Weather'
// import DashboardProfile from '@/views/dashboard/DashboardProfile'
// import LevelEdit from '@/views/dashboard/LevelEdit'
import proficiency_levels from '@/consts/proficiency_levels'
import { useLanguage } from '@/context/language'
import Icon from '@/styles/Icon'
import ConversationSituationsList from '@/views/dashboard/ConversationSituationsList'
import DashboardProfile from '@/views/dashboard/DashboardProfile'
import DashboardSituationsOnly from '@/views/dashboard/DashboardSituationsOnly'
import LessonList from '@/views/dashboard/LessonList'
import MyLessons from '@/views/dashboard/MyLessons'
import TopicFilters from '@/views/lessons/TopicFilters'
// import OnboardingStep from '@/styles/OnboardingStep'

export default () => {
  const { userId, isAlphaTester } = useUser()
  const { currentLanguage, currentUserLanguage, contentStrings } = useLanguage()

  const [searchString, setSearchString] = useState('')
  const [selectedTopics, setSelectedTopics] = useState([])

  const lessonsQuery = supabase
    .from('lessons')
    .select('*, current_edit_id(*)')
    .eq('language_id', currentLanguage.id)
    .order('sort_order',  { ascending: true })
  const [lessons, lessonsLoading, lessonsError] = useSupabaseQuery(lessonsQuery, [currentLanguage.id])

  const lessonProgressQuery = supabase
    .from('user_lesson_progress')
    .select()
    .eq('user_id', userId)
  const [lessonProgressData] = useSupabaseQuery(lessonProgressQuery, [userId], !userId)

  const level = proficiency_levels.find(level => level.id === currentUserLanguage?.proficiency_level) || proficiency_levels[0]

  const pronunciationLessons = lessons?.filter(l => l.current_edit_id?.topics?.includes('pronunciation'))
  const textFilteredLessons = lessons?.filter(l => searchString === '' || l.title_l1.toLowerCase().includes(searchString.toLowerCase()))
  const topicFilteredLessons = textFilteredLessons?.filter(l => selectedTopics.length === 0 || l.current_edit_id?.topics?.some(t => selectedTopics.includes(t)))

  const commonWordsLessons = lessons?.filter(l => l.current_edit_id?.topics?.includes('common'))
                              .filter(l => !lessonProgressData?.find(p => p.lesson_id === l.id && p.ratio_complete !== 1))
  const commonWordsLesson = commonWordsLessons?.length ? commonWordsLessons[0] : null

  if (!isAlphaTester) return <DashboardSituationsOnly />

  // const onboardingPopoverContent = (
  //   <>
  //     <h3>Practice what you've learned</h3>
  //   </>
  // )

  return <MainWrapper>
    <br />
    <TwoColumns cols="5fr 2fr" gap="4">
      <div>
        <h1>My lessons</h1>

        <MyLessons lessons={topicFilteredLessons} lessonProgressData={lessonProgressData} />

        <br />
        <h3>Missions</h3>
        <ConversationSituationsList proficiencyLevel={currentUserLanguage.proficiency_level} />

        <br />
        <hr />
        <br />

        <div style={{display: 'grid', gridTemplateColumns: 'auto max-content'}}>
          <h1>All lessons</h1>
          <input
            value={searchString}
            type="search"
            placeholder="Filter lessons"
            onChange={event => setSearchString(event.target.value) }
            style={{width: '100%'}}
          />
        </div>
        <TopicFilters selectedTopics={selectedTopics} setSelectedTopics={setSelectedTopics} />

        <LessonList lessons={topicFilteredLessons} lessonProgressData={lessonProgressData} />
      </div>
      <div>
        <DashboardProfile />

        {/* <OnboardingStep popoverContent={popoverContent} side={'left'} flagName="has_completed_reference_panel_onboarding"> */}
        <h4>Practice</h4>
        {commonWordsLesson && <SuggestedItem to={`/${currentLanguage.slug_eng}/practice/${commonWordsLesson.random_slug}`}>
          <SuggestedItemIconTitle>
            <Icon name="workspace_premium" inline />
            {commonWordsLesson.title_l1}
          </SuggestedItemIconTitle>
          <span>→</span>
        </SuggestedItem>}
        <SuggestedItem to={`/${currentLanguage.slug_eng}/practice/${pronunciationLessons?.length ? pronunciationLessons[0].random_slug : 'all'}`}>
          <SuggestedItemIconTitle>
            <Icon name="record_voice_over" inline />
            Pronunciation
          </SuggestedItemIconTitle>
          <span>→</span>
        </SuggestedItem>
        <SuggestedItem to={`/${currentLanguage.slug_eng}/practice/saved`}>
          <SuggestedItemIconTitle>
            <Icon name="bookmark" inline />
            Saved by you
          </SuggestedItemIconTitle>
          <span>→</span>
        </SuggestedItem>
        <SuggestedItem to={`/${currentLanguage.slug_eng}/practice/problem`}>
          <SuggestedItemIconTitle>
            <Icon name="warning" inline />
            Problem phrases
          </SuggestedItemIconTitle>
          <span>→</span>
        </SuggestedItem>
        <SuggestedItem to={`/${currentLanguage.slug_eng}/practice/learned`}>
          <SuggestedItemIconTitle>
            <Icon name="language" inline />
            Everything you've learned
          </SuggestedItemIconTitle>
          <span>→</span>
        </SuggestedItem>
        {/* </OnboardingStep> */}

        <br />

        <h4>Media</h4>
        {/* <SuggestedItem to={`/${currentLanguage.slug_eng}/tv`}>
          <SuggestedItemIconTitle>
          <Icon name="tv" />
          Watch {currentLanguage.name_eng} YouTube
          </SuggestedItemIconTitle>
          →
        </SuggestedItem> */}
        {contentStrings?.media_links?.music && <SuggestedItemAnchor href={contentStrings?.media_links?.music} target="_blank">
          <SuggestedItemIconTitle>
            <Icon name="queue_music" />
            Play music in {currentLanguage.name_eng}
          </SuggestedItemIconTitle>
          →
        </SuggestedItemAnchor>}
        {contentStrings?.media_links?.podcasts && <SuggestedItemAnchor href={contentStrings?.media_links?.podcasts} target="_blank">
          <SuggestedItemIconTitle>
            <Icon name="podcasts" />
            Top {currentLanguage.name_eng} podcasts
          </SuggestedItemIconTitle>
          →
        </SuggestedItemAnchor>}
        {contentStrings?.media_links?.radio && <SuggestedItemAnchor href={contentStrings?.media_links?.radio} target="_blank">
          <SuggestedItemIconTitle>
            <Icon name="radio" />
            Listen to live radio
          </SuggestedItemIconTitle>
          →
        </SuggestedItemAnchor>}
        {contentStrings?.media_links?.live_news && <SuggestedItemAnchor href={contentStrings?.media_links?.live_news} target="_blank">
          <SuggestedItemIconTitle>
            <Icon name="contact_emergency" />
            Watch live news
          </SuggestedItemIconTitle>
          →
        </SuggestedItemAnchor>}

        {/* <hr /> */}
        {/* <p>{lessons?.length || 0} lesson{lessons?.length !== 1 && 's'}</p> */}

        <br />
        <br />

        <Quote />
        <br />
        <News />
        <br />
        <Weather />
        <br />
        <h4>Travel</h4>
        <Flights />
      </div>
    </TwoColumns>
  </MainWrapper>
}

const SuggestedItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid;
  text-decoration: none;
  padding: .5rem 0;
`
const SuggestedItemAnchor = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid;
  text-decoration: none;
  padding: .5rem 0;
`
const SuggestedItemIconTitle = styled.div`
  display: flex;
  align-items: center;
  gap: .5rem;
`
