// Redirects
// URLs that should go to other routes, but have no page of their own

export default [

  // various legal URLs
  {
    path: `/legal`,
    target: `/terms`,
  },
  {
    path: `/terms-of-service`,
    target: `/terms`,
  },
  {
    path: `/terms_of_service`,
    target: `/terms`,
  },
  {
    path: `/tos`,
    target: `/terms`,
  },
  {
    path: `/privacy-policy`,
    target: `/privacy`,
  },
  {
    path: `/privacy_policy`,
    target: `/privacy`,
  },
  {
    path: `/DMCA`,
    target: `/dmca`,
  },
  {
    path: `/gdpr`,
    target: `/legal`,
  },
  {
    path: `/license`,
    target: `/open-content`,
  },
  {
    path: `/licenses`,
    target: `/open-content`,
  },
  {
    path: `/open-source`,
    target: `/open-content`,
  },
  
  // marketing pages
  {
    path: `/contributing`,
    target: `/contribute`,
  },
  {
    path: `/code-of-conduct`,
    target: `/conduct`,
  },
  {
    path: `/help`,
    target: `/support`,
  },

  // account
  {
    path: `/log-in`,
    target: `/login`,
  },
  {
    path: `/sign-in`,
    target: `/login`,
  },
  {
    path: `/signin`,
    target: `/login`,
  },
  {
    path: `/sign-up`,
    target: `/signup`,
  },

  // settings
  {
    path: `/settings`,
    target: `/settings/account`,
  },

  // urls w/o language param
  {
    path: `/lessons`,
    target: `/`,
  },
  {
    path: `/practice`,
    target: `/`,
  },
  {
    path: `/decks`,
    target: `/`,
  },
  {
    path: `/words`,
    target: `/`,
  },
  {
    path: `/dictionary`,
    target: `/`,
  },
  {
    path: `/profile`,
    target: `/`,
  },
  
  // developers
  {
    path: `/style-guide`,
    target: `/italian/styleguide`,
  },
  {
    path: `/style`,
    target: `/italian/styleguide`,
  },
  {
    path: `/styles`,
    target: `/italian/styleguide`,
  },
  {
    path: `/design-system`,
    target: `/italian/styleguide`,
  },
  {
    path: `/components`,
    target: `/italian/styleguide`,
  },
  {
    path: `/*/style-guide`,
    target: `/italian/styleguide`,
  },
  {
    path: `/*/style`,
    target: `/italian/styleguide`,
  },
  {
    path: `/*/styles`,
    target: `/italian/styleguide`,
  },
  {
    path: `/*/design-system`,
    target: `/italian/styleguide`,
  },
  {
    path: `/*/components`,
    target: `/italian/styleguide`,
  },
  {
    path: `/styleguide`,
    target: `/italian/styleguide`,
  },
  {
    path: `/voice-sandbox`,
    target: `/italian/voice-sandbox`,
  },
]
