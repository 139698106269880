import * as Popover from '@radix-ui/react-popover'
import styled from 'styled-components/macro'

export default ({
  isOpen,
  popoverContent,
  children,
}) => {
  return <Popover.Root open={isOpen} modal={true}>
    <Popover.Anchor>
      {children}
    </Popover.Anchor>
    <Popover.Portal>
      <TranscriptPopupContentWrapper align="start" side="top">
        {popoverContent}
      </TranscriptPopupContentWrapper>
    </Popover.Portal>
  </Popover.Root>
}

const TranscriptPopupContentWrapper = styled(Popover.Content)`
  padding: 0.5rem;
  background: var(--bg);
  box-shadow: 2px 2px var(--shadow);
  border: 1px solid;
  white-space: nowrap;
  z-index: 500;
`
