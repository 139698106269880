import { useParams } from 'react-router-dom'

import { useLanguage } from '@/context/language'
import { supabase, useSupabaseQuery } from '@/db/supabase'
import { BreadcrumbItem, BreadcrumbSeparator, BreadcrumbWrapper } from '@/styles/Breadcrumbs'
import ErrorComponent from '@/views/errors/ErrorComponent'
import AnswerPhrase from '@/views/practice/AnswerPhrase'

export default () => {
  const { phraseSlug } = useParams()
  const { currentLanguage } = useLanguage()

  const query = supabase
    .from('phrases')
    .select()
    .eq('random_slug', phraseSlug)
    .single()
  const [phrase, loading, error] = useSupabaseQuery(query, [phraseSlug])

  if (error) return <ErrorComponent error={error} />
  if (loading) return <div>Loading...</div>
  if (!phrase) return <div>No phrase found for slug {phraseSlug}</div>

  return <>
    <BreadcrumbWrapper>
      <BreadcrumbItem to={`/${currentLanguage.slug_eng}`}>Home</BreadcrumbItem>
      <BreadcrumbSeparator />
      {phrase?.content_l2}
    </BreadcrumbWrapper>

    <AnswerPhrase phrase={phrase} />
  </>
}
