import styled from 'styled-components/macro'
import { findSpans } from 'unicode-default-word-boundary'

import { useLanguage } from '@/context/language'
import { useReferencePanel } from '@/context/reference'
import { useSoundFx } from '@/context/soundEffects'
import Icon from '@/styles/Icon'

import { Languages } from '@/types/schema'

export const punctuationThatShouldNotBeTranslated = "!?.,;:'\"`´‘’“”…—–-_()[]{}¡¿"

interface DefinableProps {
  wordString: string
  language?: Languages
  strikethrough?: boolean
  $inline?: boolean
  splitByWord?: boolean
}

export default ({
  wordString,
  language,
  strikethrough,
  $inline,
  splitByWord,
}: DefinableProps) => {

  const { audioOnly } = useSoundFx()

  if (!wordString?.length) return null

  if (audioOnly) {
    const numberOfWaveformIcons = wordString.length > 100 ? 5 :
                                  wordString.length >  50 ? 4 :
                                  wordString.length >  25 ? 3 :
                                  wordString.length >  10 ? 2 :
                                                            1

    return <span style={{display: 'flex', flexWrap: 'wrap', padding: '2px 0'}}>
      {Array(numberOfWaveformIcons).fill('')
        .map((_, i) => {
          return <span key={i} style={{margin: '-2px'}}>
            <Icon name="graphic_eq" size="large" />
          </span>
        })}
    </span>
  }

  if (!splitByWord) {
    return <DefinableChunk
      wordString={wordString}
      language={language}
      strikethrough={strikethrough}
      $inline={$inline}
    />
  }

  const words = Array.from(findSpans(wordString))
  return words.map((word, i) => {
    if (!word.text) return null
    if (word.text === ' ') return ' '
    if (punctuationThatShouldNotBeTranslated.includes(word.text)) return word.text
    return <DefinableChunk
      key={i}
      wordString={word.text}
      language={language}
      strikethrough={strikethrough}
      $inline={$inline}
    />
  })
}

const DefinableChunk = ({ wordString, language, strikethrough, $inline }) => {
  const { startingLanguage, currentLanguage } = useLanguage()
  const { setQuery, setReferenceIsOpen } = useReferencePanel()

  const shouldHideUnderline = language?.id === startingLanguage.id

  return <>
    <Word
      style={{textDecoration: strikethrough && 'line-through'}}
      $shouldHideUnderline={shouldHideUnderline}
      $inline={$inline}
      onClick={() => {
        setQuery({text: wordString, language: language || currentLanguage })
        setReferenceIsOpen(true)
      }}
    >
      {wordString}
    </Word>
  </>
}

export const Word = styled.span`
  cursor: pointer;
  display: ${p => p.$inline ? 'inline' : 'inline-block'};
  /* font-weight: bold; */
  text-decoration-style: dotted;
  text-decoration-line: ${p => p.$shouldHideUnderline ? 'none' : 'underline'};
  text-decoration-color: #999;

  &:hover {
    text-decoration-line: underline;
    text-decoration-color: ${p => p.$shouldHideUnderline ? '#999' : 'var(--text)'};
  }
`
