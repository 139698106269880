import * as Accordion from '@radix-ui/react-accordion'
import styled from 'styled-components/macro'

import proficiency_levels from '@/consts/proficiency_levels'
import { useLanguage } from '@/context/language'
import ConversationSituationsList from '@/views/dashboard/ConversationSituationsList'
import LessonListItem from '@/views/lessons/LessonListItem'

export default ({ lessons, lessonProgressData }) => {
  const { currentLanguage } = useLanguage()
  const lessonAccordionDefaultValue = ['suggested', '0', '1', '2', '3', '4']

  return <LessonLevelsWrapper type="multiple" defaultValue={lessonAccordionDefaultValue} collapsible="true">
    {proficiency_levels.map(level => {
      const levelLessons = lessons?.filter(l => l.proficiency_level === level.id)
      if (levelLessons?.length === 0) return null
      return <LessonLevelWrapper key={level.id} value={level.id.toString()}>
        <LessonLevelHeader>
          <LessonLevelTrigger>
            <LessonLevelHeaderTitle>{level.title}</LessonLevelHeaderTitle>
            {levelLessons?.length !== 0 && <span style={{fontStyle: 'italic', fontSize: 'var(--s)'}}>{levelLessons?.length} lessons</span>}
          </LessonLevelTrigger>
        </LessonLevelHeader>
        <LessonLevelContentWrapper>
          <LessonLevelContent>
            {!levelLessons?.length && `No ${level.title} lessons in ${currentLanguage.name_eng} yet`}
            {levelLessons?.map(lesson => <LessonListItem
              key={lesson.random_slug}
              lesson={lesson}
              lessonProgressData={lessonProgressData}
            />)}
          </LessonLevelContent>
          <h3>Missions</h3>
          <ConversationSituationsList proficiencyLevel={level.id} small />
        </LessonLevelContentWrapper>
      </LessonLevelWrapper>
    })}
  </LessonLevelsWrapper>
}

const LessonLevelsWrapper = styled(Accordion.Root)`
`
const LessonLevelWrapper = styled(Accordion.Item)`
  margin: 0 0 3rem;
`
const LessonLevelHeader = styled(Accordion.Header)`
  margin: 0;
`
const LessonLevelTrigger = styled(Accordion.Trigger)`
  display: flex;
  width: 100%;
  padding: .5rem 0;
  border-bottom: 1px solid;
  text-decoration: none;
  align-items: baseline;
`
const LessonLevelHeaderTitle = styled.div`
  font-size: var(--l);
  font-weight: bold;
  margin-right: .5rem;
`
const LessonLevelContentWrapper = styled(Accordion.Content)`
  margin: 0 0 2rem;
  &[data-state='open'] {
    animation: slideDown 200ms ease-in-out;
    overflow: visible;
  }
  &[data-state='closed'] {
    animation: slideUp 200ms ease-in-out;
    overflow: hidden;
  }
`
const LessonLevelContent = styled(Accordion.Content)`
  margin-top: 1rem;
  /* display: flex; */
  /* flex-wrap: wrap; */
  column-gap: .5rem;
  row-gap: 1rem;

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`
