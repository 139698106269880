import moment from 'moment'
import { Link, useParams } from 'react-router-dom'

import { useLanguage } from '@/context/language'
import { useUser } from '@/context/user'
import { supabase, useSupabaseQuery } from '@/db/supabase'
import { BreadcrumbItem, BreadcrumbSeparator, BreadcrumbWrapper } from '@/styles/Breadcrumbs'
import { TwoColumns } from '@/styles/Layout'

export default () => {
  const { userId } = useUser()
  const { currentLanguage } = useLanguage()
  const { lessonSlug } = useParams()

  const random_slug = lessonSlug.split('-').pop()

  const lessonQuery = supabase
    .from('lessons')
    .select()
    .eq('random_slug', random_slug)
    .single()
  const [lesson, lessonLoading, lessonError] = useSupabaseQuery(lessonQuery, [random_slug])

  const lessonEditsQuery = supabase
    .from('lesson_edits')
    .select('*, created_by(*)')
    .eq('lesson_id', lesson?.id)
  const [lessonEdits, lessonEditsLoading, lessonEditsError] = useSupabaseQuery(lessonEditsQuery, [random_slug, lesson?.id], !lesson)

  if (lessonLoading || lessonEditsLoading) {
    return 'Loading...'
  }

  if (!lesson || lessonError || lessonEditsError) {
    return 'Error - could not load lesson ' + (lessonEditsError?.message || lessonError?.message)
  }

  return <>
    <BreadcrumbWrapper>
      <BreadcrumbItem to={`/${currentLanguage.slug_eng}`}>Home</BreadcrumbItem>
      <BreadcrumbSeparator />
      <BreadcrumbItem to={`/${currentLanguage.slug_eng}/lessons/${random_slug}`}>{lesson?.title_l1}</BreadcrumbItem>
      <BreadcrumbSeparator />
      History
    </BreadcrumbWrapper>

    <h1>History: {lesson.title_l1}</h1>
    {lesson?.title_l2 && <p>{lesson.title_l2}</p> }

    <hr />
    <TwoColumns cols="2fr 1fr">
      <div>
        {
          (!lessonEdits || lessonEdits.length) <= 0 ? `There's no content for this lesson yet` : 
          <table>
            <tbody>
              {lessonEdits?.map(lessonEdit => {
                return <tr key={lessonEdit.id}>
                  <td>
                    <Link to={`/${currentLanguage.slug_eng}/lessons/${random_slug}/history/${lessonEdit.id}`}>{lessonEdit.id}</Link>
                  </td>
                  <td>
                    { lessonEdit.created_by?.username ?
                      <Link to={`/u/${lessonEdit.created_by.username}`}>{lessonEdit.created_by.username}</Link> :
                      'anon'
                    }
                  </td>
                  <td>
                    {moment(lessonEdit.created_at).fromNow()}
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        }
      </div>
      {userId === lesson.created_by &&
        <div>
          <Link to={`/${currentLanguage.slug_eng}/lessons/${random_slug}/edit`}>Edit lesson</Link>
        </div>
      }
    </TwoColumns>
  </>

}
