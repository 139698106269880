import { Link } from 'react-router-dom'

import { useLanguage } from '@/context/language'
import { useUser } from '@/context/user'
import { Header, HeaderFooterLayoutWrapper, Main, SecondaryNavLink } from '@/styles/Layout'
import Footer from '@/views/_layout/components/Footer'
import Logo from '@/views/_layout/components/Logo'
import { useEffect } from 'react'

export default ({children}) => {

  const { currentLanguage, userLanguages } = useLanguage()
  const { userId } = useUser()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [window.location.href])

  const languageToLinkTo = JSON.stringify(currentLanguage) !== '{}' ? currentLanguage : userLanguages[0]?.language_id

  return <HeaderFooterLayoutWrapper>

    <Header>
      <nav>
        <Logo to="/" />
      </nav>
      <div>
        {userId ?
          <Link
            className="button button-small"
            to={languageToLinkTo ? `/${languageToLinkTo.slug_eng}` : `/settings`}
          >
            {languageToLinkTo ? `Back to ${languageToLinkTo.name_eng} →` : 'Your account →'}
          </Link>
          :
          <>
            <SecondaryNavLink to="/signup">Create account</SecondaryNavLink>
            {' / '}
            <SecondaryNavLink to="/login">Log in</SecondaryNavLink>
          </>
        }
      </div>
    </Header>

    <Main>
      {children}
    </Main>

    <Footer />

  </HeaderFooterLayoutWrapper>
}
