import moment from 'moment'
import { Link } from 'react-router-dom'

import { supabase, useSupabaseQuery } from '@/db/supabase'
import AdminId from '@/styles/AdminId'
import AdminEditCell from '@/views/_components/AdminEditCell'
import SupabaseAdminLink from '@/views/admin/SupabaseAdminLink'
import ErrorPage from '@/views/errors/ErrorPage'

export default () => {

  const query = supabase
    .from('topics')
    .select('*, created_by(*)')
    .order('id', { ascending: true })
  const [topics, loading, error] = useSupabaseQuery(query)

  if (error) return <ErrorPage error={error} />
  if (loading) return <div>loading...</div>
  if (!topics || topics.length <= 0) return <div>No topics</div>

  return <>
    <h1>Topics</h1>
    {topics.length} topic{topics.length === 1 ? '' : 's'}

    <hr />

    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Slug</th>
          <th>Title</th>
          <th>Description</th>
          <th>User selectable</th>
          <th>Created</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          topics.map(topic => {
            return <tr key={topic.id}>
              <td>
                <AdminId>{topic.id}</AdminId>
              </td>
              <td>
                <code>
                  <AdminEditCell id={topic.id} collection="topics" attribute="name_slug" type="text" value={topic.name_slug} />
                </code>
              </td>
              <td>
                <strong>
                  <AdminEditCell id={topic.id} collection="topics" attribute="title_eng" type="text" value={topic.title_eng} />
                </strong>
              </td>
              <td>
                <AdminEditCell id={topic.id} collection="topics" attribute="description_eng" type="text" value={topic.description_eng} />
              </td>
              <td>
                <AdminEditCell id={topic.id} collection="topics" attribute="user_selectable" type="boolean" value={topic.user_selectable} />
              </td>
              <td>
                { topic.created_by?.username ?
                  <Link to={`/u/${topic.created_by.username}`}>{topic.created_by.username}</Link> :
                  '❌ no username'
                }
              </td>
              <td>
                {moment(topic.created_at).fromNow()}
              </td>
              <td>
                <SupabaseAdminLink table="topics" id={topic.id} />
              </td>
            </tr>
          })
        }
      </tbody>
    </table>

  </>
}
