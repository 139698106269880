import { createContext, useContext, useState } from 'react'
import styled from 'styled-components/macro'

import { useUser } from '@/context/user'
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage'

const Context = createContext({
  isDebugging: false,
  toggleDebugMode: () => {},
})

export default ({ children }) => {
  const localDebugFlag = getLocalStorage('protolang-debug-mode-on') === true

  const { isAdmin } = useUser()
  const [isDebugging, setIsDebugging] = useState(localDebugFlag || false)

  const toggleDebugMode = () => {
    const newDebugFlag = !isDebugging
    setIsDebugging(newDebugFlag)
    setLocalStorage('protolang-debug-mode-on', newDebugFlag)
  }

  // so we can trigger it in the console?
  // window.toggleDebugMode = toggleDebugMode

  const exposed = {
    isDebugging: isDebugging && isAdmin,
    toggleDebugMode,
  }

  return <Context.Provider value={exposed}>
    {children}
  </Context.Provider>
}

export const useDebug = () => useContext(Context)

export const DebugDiv = styled.div`
  font-size: var(--xs);
  font-weight: 500;
  color: red;
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  opacity: 0.8;
  padding: 1px 3px;
  font-family: monospace;
`
