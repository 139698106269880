import styled from 'styled-components/macro'

import { useLanguage } from '@/context/language'
import { useSpeechSynthesis } from '@/context/speechSynthesis'
import { useUser } from '@/context/user'
import Definable from '@/views/_components/Definable'
import ReportIssue from '@/views/_components/ReportIssue'
import SpeakWord from '@/views/_components/SpeakWord'
import MnemonicSuggested from '@/views/mnemonics/MnemonicSuggested'
import PhraseEdit from '@/views/practice/PhraseEdit'

const STACK_SIZE = 3

export default ({
  phrase,
  cardQuestionType,
  cardAnswerType,
  direction,
  placeInLine,
  isDone,
  isRecentlyDone,
  practiceType,
}) => {

  const { isAdmin } = useUser()
  const { startingLanguage, currentLanguage } = useLanguage()
  const { speak } = useSpeechSynthesis()

  // similar to what we compute at CardDeck.jsx,
  // but that changes based on what's current
  // and this we use for this card even while it animates
  let question, questionLang
  if (practiceType === 'translation') {
    question =       direction === 'forward' ? phrase.content_l1 : phrase.content_l2
    questionLang = direction === 'forward' ? startingLanguage : currentLanguage
  } else if (practiceType === 'pronunciation') {
    question = phrase.content_l2
  }

  if (placeInLine > STACK_SIZE) return null
  const isLastCard = placeInLine === STACK_SIZE
  const SPEED_FLY_OUT = .4
  const DELAY_SHIFT_IN = .2
  const SPEED_SHIFT_IN = .3

  const pronunciationPrompt = `Speak in ${currentLanguage.name_eng}:`
  const translateCardPrompt = `${cardAnswerType === 'text' ? 'Type' : cardAnswerType === 'speech' ? 'Speak' : 'Give'} the ${direction === 'forward' ? currentLanguage.name_eng : startingLanguage.name_eng} for...`

  return <CardWrapper style={{
    visibility: (isDone && !isRecentlyDone) ? 'hidden' : 'visible',
    pointerEvents: isRecentlyDone ? 'none' : 'auto',
    transform: isRecentlyDone ? `translate(-50px, -100px) rotate(-15deg)` : `translate(${placeInLine * 4}px, ${placeInLine * 4}px)`,
    opacity: (isLastCard || isRecentlyDone) ? 0 : 1,
    zIndex: 200 - placeInLine,
    transition: isRecentlyDone ?
      `visibility 0s, opacity ${SPEED_FLY_OUT}s ease-out, transform ${SPEED_FLY_OUT}s linear` :
      `opacity ${SPEED_SHIFT_IN}s ${DELAY_SHIFT_IN}s ease-in-out, transform ${SPEED_SHIFT_IN}s ${DELAY_SHIFT_IN}s ease-in-out`,
  }}>

    {practiceType === 'translation' ? <p>{translateCardPrompt}</p> : <p>{pronunciationPrompt}</p>}

    <div style={{ position: 'absolute', top: '1rem', right: '1rem', fontSize: 'var(--s)'}}>
      <ReportIssue phrase={phrase} />
      {' '}
      {isAdmin && <PhraseEdit phrase={phrase} />}
    </div>

    {
      practiceType === 'pronunciation' && <>
        <div style={{fontSize: 'var(--l)'}}>
          {(cardQuestionType === 'text' || cardQuestionType === 'both') && <strong><Definable wordString={phrase.content_l2} /></strong>}
          {(cardQuestionType === 'audio' || cardQuestionType === 'both') && <>
            <SpeakWord wordString={phrase.content_l2} />
            <SpeakWord wordString={phrase.content_l2} rate={1}>🐇</SpeakWord>
            <SpeakWord wordString={phrase.content_l2} rate={.5}>🐢</SpeakWord>
          </>}
        </div>
        <div style={{fontSize: 'var(--s)'}}>
          {phrase.content_l2_alts && phrase.content_l2_alts.length > 0 && 'or '}
          {phrase.content_l2_alts?.join(', ') || ""}
        </div>
        <p>{phrase.content_l1}</p>
        <div style={{fontSize: 'var(--s)'}}>
          {phrase.content_l1_alts && phrase.content_l1_alts.length > 0 && 'or '}
          {phrase.content_l1_alts?.join(', ') || ""}
        </div>
        <MnemonicSuggested wordString={phrase.content_l2} />
      </>
    }
    {
      practiceType === 'translation' && <>
        {
          cardQuestionType === 'text' ? <h2>
            <Definable wordString={question} language={questionLang} />
          </h2> :
          cardQuestionType === 'audio' ? <SpeakWord wordString={question} /> :
          <h2>
            <Definable wordString={question} language={questionLang} />
            {direction !== 'forward' && <SpeakWord wordString={question} />}
          </h2>
        }
      </>
    }
  </CardWrapper>
}

export const CardWrapper = styled.div`
  border: 1px solid;
  background: var(--bg);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  position: absolute;
`
