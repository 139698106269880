// ROUTES
// This is a simple JS list of routes in the app
// Router.jsx consumes this and programmatically generates a list
// of react-router <Routes>

import LayoutLanguage from '@/views/_layout/LayoutLanguage'
import LayoutSimple from '@/views/_layout/LayoutSimple'
import Login from '@/views/account/Login'
import Logout from '@/views/account/Logout'
import Signup from '@/views/account/Signup'
import Styleguide from '@/views/admin/Styleguide'
import TestSignup from '@/views/admin/TestSignup'
import VoiceSandbox from '@/views/admin/VoiceSandbox'
import FeedbackForm from '@/views/feedback/FeedbackForm'
import LanguagePicker from '@/views/languages/LanguagePicker'
import Conduct from '@/views/legal/Conduct'
import Dmca from '@/views/legal/Dmca'
import Privacy from '@/views/legal/Privacy'
import Terms from '@/views/legal/Terms'
import LessonItem from '@/views/lessons/LessonItem'
import About from '@/views/marketing/About'
import Contact from '@/views/marketing/Contact'
import Contribute from '@/views/marketing/Contribute'
import Home from '@/views/marketing/Home'
import OpenContent from '@/views/marketing/OpenContent'
import PracticeContainer from '@/views/practice/PracticeContainer'
// import MediaItem from '@/views/media/MediaItem'
// import MediaNew from '@/views/media/MediaNew'
// import MediaEdit from '@/views/media/MediaEdit'
// import MediaList from '@/views/media/MediaList'
import WhyItsBroken from '@/blog_posts/WhyItsBroken'
import LayoutAdmin from '@/views/_layout/LayoutAdmin'
import LayoutAuth from '@/views/_layout/LayoutAuth'
import LayoutSettings from '@/views/_layout/LayoutSettings'
import AdminCharacters from '@/views/admin/AdminCharacters'
import AdminIssues from '@/views/admin/AdminIssues'
import AdminLanguages from '@/views/admin/AdminLanguages'
import AdminLessonEdits from '@/views/admin/AdminLessonEdits'
import AdminLessons from '@/views/admin/AdminLessons'
import AdminPhrases from '@/views/admin/AdminPhrases'
import AdminSituations from '@/views/admin/AdminSituations'
import AdminTopics from '@/views/admin/AdminTopics'
import AdminUsers from '@/views/admin/AdminUsers'
import AnalyticsList from '@/views/analytics/AnalyticsList'
import Blog from '@/views/blog/Blog'
import ConversationItemContainer from '@/views/conversations/ConversationItemContainer'
import Dashboard from '@/views/dashboard/Dashboard'
import LessonEdit from '@/views/lessons/LessonEdit'
import LessonHistory from '@/views/lessons/LessonHistory'
import LessonHistoryItem from '@/views/lessons/LessonHistoryItem'
import LessonNew from '@/views/lessons/LessonNew'
import RadioContainer from '@/views/media/RadioContainer'
import TvContainer from '@/views/media/TvContainer'
import UserMnemonicsList from '@/views/mnemonics/UserMnemonicsList'
import ConversationHistory from '@/views/practice/ConversationHistory'
import PhraseItem from '@/views/practice/PhraseItem'
import PracticeHistory from '@/views/practice/PracticeHistory'
import Profile from '@/views/profile/Profile'
import Read from '@/views/read/Read'
import SavedList from '@/views/saved/SavedList'
import AccountSettings from '@/views/settings/AccountSettings'
import BillingSettings from '@/views/settings/BillingSettings'
import LanguageGoals from '@/views/settings/LanguageGoals'
import ProfileSettings from '@/views/settings/ProfileSettings'
import Support from '@/views/support/Support'

export default [

  // Marketing pages
  {
    path: `/`,
    component: Home,
    layout: LayoutSimple,
  },
  {
    path: `/about`,
    component: About,
    layout: LayoutSimple,
  },
  {
    path: `/contact`,
    component: Contact,
    layout: LayoutSimple,
  },
  {
    path: `/contribute`,
    component: Contribute,
    layout: LayoutSimple,
  },
  {
    path: `/open-content`,
    component: OpenContent,
    layout: LayoutSimple,
  },
  {
    path: `/support`,
    component: Support,
    layout: LayoutSimple,
  },

  // Blog
  {
    path: `/blog`,
    component: Blog,
    layout: LayoutSimple,
  },
  {
    path: `/blog/:slug`,
    component: WhyItsBroken,
    layout: LayoutSimple,
  },

  // Legal stuff
  {
    path: `/terms`,
    component: Terms,
    layout: LayoutSimple,
  },
  {
    path: `/privacy`,
    component: Privacy,
    layout: LayoutSimple,
  },
  {
    path: `/dmca`,
    component: Dmca,
    layout: LayoutSimple,
  },
  {
    path: `/conduct`,
    component: Conduct,
    layout: LayoutSimple,
  },

  // Auth pages
  {
    path: `/signup`,
    component: Signup,
    layout: LayoutAuth,
  },
  {
    path: `/login`,
    component: Login,
    layout: LayoutAuth,
  },
  {
    path: `/logout`,
    component: Logout,
    layout: LayoutAuth,
  },

  // Onboarding
  {
    path: `/languages`,
    component: LanguagePicker,
    layout: LayoutSimple,
  },
  {
    path: `/feedback`,
    component: FeedbackForm,
    layout: LayoutSimple,
  },

  // Profiles
  {
    path: `/u/:username`,
    component: Profile,
    layout: LayoutSimple,
  },

  // Dashboard
  {
    path: `/:langSlug`,
    component: Dashboard,
    layout: LayoutLanguage,
  },

  // Analytics
  {
    path: `/:langSlug/analytics`,
    component: AnalyticsList,
    layout: LayoutLanguage,
  },

  // Media
  // {
  //   path: `/:langSlug/media`,
  //   component: MediaList,
  //   layout: LayoutLanguage,
  // },
  // {
  //   path: `/:langSlug/media/new`,
  //   component: MediaNew,
  //   layout: LayoutLanguage,
  // },
  // {
  //   path: `/:langSlug/media/:mediaId/edit`,
  //   component: MediaEdit,
  //   layout: LayoutLanguage,
  // },
  // {
  //   path: `/:langSlug/media/:mediaId`,
  //   component: MediaItem,
  //   layout: LayoutLanguage,
  // },
  {
    path: `/:langSlug/tv`,
    component: TvContainer,
    layout: LayoutLanguage,
  },
  {
    path: `/:langSlug/radio`,
    component: RadioContainer,
    layout: LayoutLanguage,
  },

  // Lessons
  {
    path: `/:langSlug/lessons/new`,
    component: LessonNew,
    layout: LayoutLanguage,
  },
  {
    path: `/:langSlug/lessons/:lessonSlug/edit`,
    component: LessonEdit,
    layout: LayoutLanguage,
  },
  {
    path: `/:langSlug/lessons/:lessonSlug/history`,
    component: LessonHistory,
    layout: LayoutLanguage,
  },
  {
    path: `/:langSlug/lessons/:lessonSlug/history/:editId`,
    component: LessonHistoryItem,
    layout: LayoutLanguage,
  },
  {
    path: `/:langSlug/lessons/:lessonSlug`,
    component: LessonItem,
    layout: LayoutLanguage,
  },

  // Practice
  {
    path: `/:langSlug/practice/:phraseSource`,
    component: PracticeContainer,
    layout: LayoutLanguage,
  },

  // Phrases
  {
    path: `/:langSlug/phrase/:phraseSlug`,
    component: PhraseItem,
    layout: LayoutLanguage,
  },

  // Conversations
  {
    path: `/:langSlug/conversation/new/:situationSlug`,
    component: ConversationItemContainer,
    layout: LayoutLanguage,
  },
  {
    path: `/:langSlug/conversation/:conversationSlug`,
    component: ConversationItemContainer,
    layout: LayoutLanguage,
  },

  // Read
  {
    path: `/:langSlug/read`,
    component: Read,
    layout: LayoutLanguage,
  },

  // Settings
  {
    path: `/settings/account`,
    component: AccountSettings,
    layout: LayoutSettings,
    private_only: true,
  },
  {
    path: `/settings/profile`,
    component: ProfileSettings,
    layout: LayoutSettings,
    private_only: true,
  },
  {
    path: `/settings/billing`,
    component: BillingSettings,
    layout: LayoutSettings,
    private_only: true,
  },

  // Language settings
  {
    path: `/:langSlug/goals`,
    component: LanguageGoals,
    layout: LayoutSettings,
    private_only: true,
  },
  {
    path: `/:langSlug/attempts`,
    component: PracticeHistory,
    layout: LayoutLanguage,
    private_only: true,
  },
  {
    path: `/:langSlug/conversations`,
    component: ConversationHistory,
    layout: LayoutLanguage,
    private_only: true,
  },
  {
    path: `/:langSlug/saved`,
    component: SavedList,
    layout: LayoutLanguage,
    private_only: true,
  },
  {
    path: `/:langSlug/mnemonics`,
    component: UserMnemonicsList,
    layout: LayoutLanguage,
    private_only: true,
  },
  
  // Admin
  {
    path: `/:langSlug/admin`,
    component: AdminLessons,
    layout: LayoutAdmin,
    private_only: true,
  },
  {
    path: `/:langSlug/admin/lessons`,
    component: AdminLessons,
    layout: LayoutAdmin,
    private_only: true,
  },
  {
    path: `/:langSlug/admin/lesson-edits`,
    component: AdminLessonEdits,
    layout: LayoutAdmin,
    private_only: true,
  },
  {
    path: `/:langSlug/admin/phrases`,
    component: AdminPhrases,
    layout: LayoutAdmin,
    private_only: true,
  },
  {
    path: `/:langSlug/admin/phrase-issues`,
    component: AdminIssues,
    layout: LayoutAdmin,
    private_only: true,
  },
  {
    path: `/:langSlug/admin/languages`,
    component: AdminLanguages,
    layout: LayoutAdmin,
    private_only: true,
  },
  {
    path: `/:langSlug/admin/users`,
    component: AdminUsers,
    layout: LayoutAdmin,
    private_only: true,
  },
  {
    path: `/:langSlug/admin/topics`,
    component: AdminTopics,
    layout: LayoutAdmin,
    private_only: true,
  },
  {
    path: `/:langSlug/admin/situations`,
    component: AdminSituations,
    layout: LayoutAdmin,
    private_only: true,
  },
  {
    path: `/:langSlug/admin/characters`,
    component: AdminCharacters,
    layout: LayoutAdmin,
    private_only: true,
  },

  // Testing
  {
    path: `/_test/signup`,
    component: TestSignup,
    layout: LayoutAuth,
  },

  // Developers
  {
    path: `/:langSlug/styleguide`,
    component: Styleguide,
    layout: LayoutLanguage,
  },
  {
    path: `/:langSlug/voice-sandbox`,
    component: VoiceSandbox,
    layout: LayoutLanguage,
  },
]
