import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'

import { useLanguage } from '@/context/language'
import { useReferencePanel } from '@/context/reference'
import Icon from '@/styles/Icon'

export default ({ children }) => {

  const { currentLanguage } = useLanguage()
  const { setQuery, setReferenceIsOpen } = useReferencePanel()

  const elementRef = useRef(null)

  const [selectedText, setSelectedText] = useState(null)
  const [boundingRectangle, setBoundingRectangle] = useState(null)

  const handleSelect = event => {
    // ignore clicks on the component itself so lookUpHighlight can fire
    if (elementRef.current && elementRef.current.contains(event.target)) {
      return
    }
    const selection = document.getSelection()
    if (!selection) return
    const selectedText = selection.toString()
    if (!selectedText?.trim().length) return
    setSelectedText(selectedText)
    setBoundingRectangle(selection.getRangeAt(0).getBoundingClientRect())
  }

  const clearSelection = () => {
    setSelectedText(null)
    setBoundingRectangle(null)

    // TODO - don't always clear the selection? This would happen on any scroll right now.
    // That seems like it could get annoying, but it's hard to know when we want this and when we don't.
    // Maybe only enable it for certain pages? Reading pages for ex?
    document.getSelection().removeAllRanges()
  }

  const lookUpHighlight = () => {
    if (!selectedText || !selectedText.length) return
    setReferenceIsOpen(true)
    setQuery({text: selectedText, language: currentLanguage})
    clearSelection()
  }

  useEffect( () => {
    document.body.addEventListener("pointerup", handleSelect)
    window.addEventListener("scroll", clearSelection)
    return () => {
      document.body.removeEventListener("pointerup", handleSelect)
      window.removeEventListener("scroll", clearSelection)
    }
  }, [])

  return <>
    {selectedText &&
      <HighlightOptionWrapper
        ref={elementRef}
        top={ boundingRectangle?.top}
        left={ boundingRectangle ? boundingRectangle.left + boundingRectangle.width / 2 : null }
        onClick={lookUpHighlight}
      >
        <Icon name="search" />
        Lookup
      </HighlightOptionWrapper>
    }
    {children}
  </>
}

const HighlightOptionWrapper = styled.div`
  position: fixed;
  top: ${props => `${props.top}px` || '5rem'};
  left: ${props => `${props.left}px` || '50%'};
  transform: translate(-50%, -100%);
  background: var(--bg);
  border: 1px solid;
  padding: 4px;
  z-index: 1000;
  box-shadow: 2px 2px var(--shadow);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  max-width: 10rem;

  &:hover {
    background: var(--hover);
  }
`
