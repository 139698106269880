import { useState } from 'react'
import styled from 'styled-components/macro'

import { useLanguage } from '@/context/language'
import { Badge } from '@/styles/Badge'
import { MainWrapper } from '@/styles/Layout'
import LoomVideo from '@/styles/LoomVideo'
import {
  HeroImage,
  HeroWrapper,
} from '@/styles/Marketing'
import Stamp from '@/styles/Stamp'
import ArrowCallout from '@/views/_components/ArrowCallout'
import HorizontalFilter from '@/views/_components/HorizontalFilter'
import JoinWaitlist from '@/views/account/JoinWaitlist'
import ConversationSituationsList from '@/views/dashboard/ConversationSituationsList'

export default () => {
  const { currentLanguage } = useLanguage()

  const [filteredTopic, setFilteredTopic] = useState(null)
  // const [hasScrolled, setHasScrolled] = useState(false)

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 1000) setHasScrolled(true)
  //   }
  //   window.addEventListener('scroll', handleScroll)
  //   return () => window.removeEventListener('scroll', handleScroll)
  // }, [])

  return <MainWrapper>
    <div className="full-bleed">
      <Stamp />
      <HeroWrapper $border>
        <div>
          <div style={{margin: '0 0 -1.5rem'}}>
            <Badge>FREE PREVIEW</Badge>
          </div>
          <h1 style={{maxWidth: '800px'}}>
            Learn {currentLanguage.name_eng} with realistic AI conversations
          </h1>
          <p style={{fontSize: 'var(--l)', maxWidth: '700px', margin: '0 0 1rem'}}>
            Test your skills with travel scenarios, grand adventures, and everything in between. Gain confidence, choose your own path, and unlock your next big adventure. 
          </p>
          <button className="button button-primary button-large" onClick={() => window.scrollTo({top: 800, behavior: 'smooth'})}>Try for free →</button>
          <p style={{fontSize: 'var(--s)', margin: '0.5rem 0 1rem'}}>Free preview · No credit card required</p>
          {/* <JoinWaitlist /> */}
        </div>

        {/* https://dev.to/ziratsu/switch-to-a-darker-image-when-on-dark-mode-2lkh */}
        <HeroImage>
          {/* <source srcSet="/images/hero_illustrations/simple_chat_dark.png" media="(prefers-color-scheme:dark)" /> */}
          <img src="/images/hero_illustrations/simple_chat.png" alt="Sample conversation" />
        </HeroImage>
      </HeroWrapper>
    </div>

    <HorizontalFilter
      items={[
        { name: 'Food', value: 'food' },
        { name: 'Travel', value: 'travel' },
        // disable history until we have at least one item at each skill level
        // { name: 'History', value: 'history' },
        { name: 'Random', value: 'random' },
      ]}
      value={filteredTopic}
      onChange={setFilteredTopic}
    />

    <h2>Easy</h2>
    <br />
    {/* <ArrowCallout text="Try me!" /> */}
    <ConversationSituationsList topic={filteredTopic} proficiencyLevel={0} />

    <br />
    <hr />
    <h2>Intermediate</h2>
    <br />
    <ConversationSituationsList topic={filteredTopic} proficiencyLevel={1} />

    <br />
    <hr />
    <h2>Difficult</h2>
    <br />
    <ConversationSituationsList topic={filteredTopic} proficiencyLevel={2} />

    <br />
    <hr />
    <h2>Expert</h2>
    <br />
    <ConversationSituationsList topic={filteredTopic} proficiencyLevel={3} />

    {/* <FixedSignupCTA hasScrolled={hasScrolled} /> */}
    <PreviewVideoCTA />
    <br />

  </MainWrapper>
}

// const FixedSignupCTA = ({ hasScrolled }) => {
//   const { userId } = useUser()
//   const isDesktop = useMediaQuery('(min-width: 1100px)')

//   return <FixedSignupCTAWrapper hide={userId || !isDesktop || !hasScrolled}>
//     <div>
//       <h2 style={{margin: '0 0 .5rem'}}>Coming soon! More languages, flashcards, and more</h2>
//       <p>
//         Reserve your spot now. We'll notify you when we launch.
//       </p>
//       <p style={{fontSize: 'small'}}>
//         Infrequent emails · Unsubscribe anytime
//       </p>
//     </div>
//     <div>
//       <br />
//       <JoinWaitlist />
//     </div>
//   </FixedSignupCTAWrapper>
// }

const PreviewVideoCTA = () => {
  return <PreviewVideoCTAWrapper>
    <div>
      <Badge $primary>Sneak preview</Badge>
      <h1 style={{margin: '.5rem 0 1rem', fontSize: 'var(--xxl)'}}>Coming soon: The immersive language app for self-motivated&nbsp;learners</h1>
      <p style={{fontSize: 'var(--l)', margin: '0 0 1rem'}}>
        {/* Learn English, French, Spanish, and many more. */}
        {/* {' '} */}
        {/* <Link to="/">Learn more →</Link> */}
        We're building something big! Protolang gives you <strong>total control over what you learn</strong>. Choose your own learning path, and start your next big adventure.
      </p>
      <JoinWaitlist />
    </div>
    <div>
      <ArrowCallout text="See how it works" />
      <LoomVideo id="ae393b1448524717bce13e1e13fa653c" />
    </div>
  </PreviewVideoCTAWrapper>
}

const FixedSignupCTAWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  transition: transform .5s ease-in-out;
  transform: translateY(${({hide}) => hide ? '100%' : '0'});

  padding: 1vw var(--gutter);
  background: var(--bg);
  border-top: 1px solid;
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  justify-content: center;
  gap: 5vw;
  z-index: 600;

  & > div > *:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    grid-template-columns: auto;
    gap: .5rem;
    padding: 1rem;
  }
`

const PreviewVideoCTAWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 2rem;
  margin: 6rem 0;

  & > div > *:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    grid-template-columns: auto;
    gap: 4rem;
    padding: 1rem;
  }
`
