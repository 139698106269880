import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'

export default ({ stations, currentStation, setStationIndex }) => {

  const radioRef = useRef(new Audio(currentStation?.url))

  console.log('📻 currentStation?.url', currentStation?.url)

  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    console.log('📻 from use effect currentStation?.url', currentStation?.url)
    if (!currentStation?.url) return
    radioRef.current = new Audio(currentStation?.url)
    radioRef.current.play()
    setIsPlaying(true)
    return () => {
      radioRef.current.pause()
      setIsPlaying(false)
    }
  }, [currentStation?.url])

  return <Room>
    <Radio>
      <Antenna />
      <RadioBody>
        <Speaker isPlaying={isPlaying} />
        <SidePanel>
          <StationToggleButtons>
            {stations.map((station, index) => (
              <button
                key={station.url}
                className="button"
                style={{ background: station.url === currentStation?.url && '#ccc' }}
                onClick={() => {
                  if (isPlaying && station.url === currentStation?.url) {
                    setStationIndex(null)
                  } else {
                    setStationIndex(index)
                  }
                }}
              >
                {station.name}
              </button>
            ))}
          </StationToggleButtons>
        </SidePanel>
      </RadioBody>
      <Feet>
        <Foot />
        <Foot />
      </Feet>
    </Radio>
    <Table />
  </Room>
}

const Room = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 100vh;
  padding: 3vw;
`
const Table = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  border-top: 1px solid;
  border-bottom: 1px solid;
`
const Radio = styled.div`
  max-width: 900px;
  padding: 5%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
`
const RadioBody = styled.div`
  background: var(--bg);
  border: 1px solid;
  border-radius: 30px;
  padding: 5%;
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 5%;
  z-index: 200;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`
const Speaker = styled.div`
  background: var(--bg);
  background-image: url(/images/ui_textures/dots.svg);
  background-size: 150px;
  border-radius: 1000px;
  aspect-ratio: 1/1;
  width: 100%;
  max-width: 30vw;
  justify-self: center;

  animation: ${p => p.isPlaying && ' 3s infinite alternate shake' };
  animation-delay: 3s;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;

`
const SidePanel = styled.div`
  display: grid;
  grid-template-rows: max-content auto;
  gap: 5%;
`
const StationToggleButtons = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: .5rem;
`
const Antenna = styled.div`
  width: 60%;
  height: 1px;
  background: var(--text);
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translateX(-50%) rotate(350deg);
  z-index: 100;

  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: var(--text);
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-50%);
  }
`
const Feet = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`
const Foot = styled.div`
  height: 4px;
  width: 3vw;
  background: var(--text);
  border-radius: 0 0 100px 100px;
  margin: 0 10%;
`
