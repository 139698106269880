import moment from 'moment'
import { Link } from 'react-router-dom'

import { supabase, useSupabaseQuery } from '@/db/supabase'
import AdminId from '@/styles/AdminId'
import SupabaseAdminLink from '@/views/admin/SupabaseAdminLink'
import ErrorPage from '@/views/errors/ErrorPage'

export default () => {

  const query = supabase
    .from('user_profiles')
    .select()
  const [userProfiles, loading, error] = useSupabaseQuery(query)

  if (error) return <ErrorPage error={error} />
  if (loading) return <div>loading...</div>
  if (!userProfiles || userProfiles.length <= 0) return <div>TODO - debug query and show users</div>

  return <>
    <h1>Users</h1>
    {userProfiles.length} user{userProfiles.length === 1 ? '' : 's'}

    <hr />

    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Username</th>
          <th>Created</th>
          <th>Updated</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          userProfiles.map(user => {
            return <tr key={user.id}>
              <td>
                <AdminId>{user.id}</AdminId>
              </td>
              <td>
                { user.username ?
                  <Link to={`/u/${user.username}`}>{user.username}</Link> :
                  <span style={{opacity: .5}}>none</span>
                }
              </td>
              <td>
                {moment(user.created_at).fromNow()}
              </td>
              <td>
                {user.updated_at && moment(user.updated_at).fromNow()}
              </td>
              <td>
                <SupabaseAdminLink table="user_profiles" id={user.id} />
              </td>
            </tr>
          })
        }
      </tbody>
    </table>

  </>
}
