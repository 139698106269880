import { useSoundFx } from '@/context/soundEffects'
import { CheckboxItem } from '@/styles/RadioCheckbox'

export default () => {
  const {
    setSoundFxOn,
    soundFxOn,
    setRepeatOn,
    repeatOn,
    audioOnly,
    setAudioOnly,
    playTapForce,
  } = useSoundFx()

  return <>
    <CheckboxItem
      groupName="repeatOn"
      optionId="repeatOn"
      checked={repeatOn}
      onChange={() => setRepeatOn(!repeatOn)}
    >
      Automatically repeat phrases
    </CheckboxItem>

    <CheckboxItem
      groupName="soundFxOn"
      optionId="soundFxOn"
      checked={soundFxOn}
      onChange={() => {
        const newState = !soundFxOn
        newState && playTapForce()
        setSoundFxOn(newState)
      }}
    >
      Sound effects
    </CheckboxItem>

    <CheckboxItem
      groupName="audioOnly"
      optionId="audioOnly"
      checked={audioOnly}
      onChange={() => setAudioOnly(!audioOnly)}
    >
      Hide text (listen and speak only)
    </CheckboxItem>
  </>
}
