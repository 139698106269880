import { BreadcrumbItem, BreadcrumbSeparator, BreadcrumbWrapper } from '@/styles/Breadcrumbs'
import moment from 'moment'
import { useEffect, useState } from 'react'
import ConfettiExplosion from 'react-confetti-explosion'
import { Link, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import Licenses from '@/consts/licenses'
import { useLanguage } from '@/context/language'
import { useUser } from '@/context/user'
import { supabase, useSupabaseQuery } from '@/db/supabase'
import { Badge } from '@/styles/Badge'
import { MainWrapper, TwoColumns } from '@/styles/Layout'
import { logError } from '@/utils/error'
import ReportIssue from '@/views/_components/ReportIssue'
import SpeakWord from '@/views/_components/SpeakWord'
import LessonContent from '@/views/lessons/LessonContent'
import CardDeck from '@/views/practice/CardDeck'
// import OrBar from '@/styles/OrBar'
import useSaveLesson from '@/hooks/useSaveLesson'
import LessonPhraseList from '@/views/lessons/LessonPhraseList'
import RelatedLessons from '@/views/lessons/RelatedLessons'
// import Icon from '@/styles/Icon'
import StepProgress from '@/styles/StepProgress'
// import PrevNextLesson from '@/views/lessons/PrevNextLesson'
import { AvatarUser } from '@/styles/Avatars'
import ErrorPage from '@/views/errors/ErrorPage'
import ProfileFollow from '@/views/profile/ProfileFollow'

export default () => {
  const { lessonSlug } = useParams()
  const { userId, userProfile } = useUser()
  const { currentLanguage } = useLanguage()

  const navigate = useNavigate()

  const random_slug = lessonSlug.split('-').pop()

  const [ currentStepIndex, setCurrentStepIndex ] = useState(0)
  const [ currentPhraseIndex, setCurrentPhraseIndex ] = useState(0)
  const [ phrases, setPhrases ] = useState([])
  const [ phrasesLoading, setPhrasesLoading ] = useState(false)

  const query = supabase
    .from('lessons')
    .select('*, current_edit_id(*, created_by(*)), created_by(*)')
    .eq('random_slug', random_slug)
    .single()
  const [lesson, loading, error] = useSupabaseQuery(query, [random_slug])

  const [ isSaved, isLoading, addSaved, removeSaved ] = useSaveLesson(lesson?.id)

  useEffect(() => {
    const addLessonPhrases = async () => {
      try {
        setPhrasesLoading(true)
        console.log(`🏃 triggering db function get_phrases_by_slugs`)
        const { data, error } = await supabase.rpc('get_phrases_by_slugs', {
          lang_id: currentLanguage.id,
          phrase_slugs_l2: lesson?.current_edit_id?.phrase_slugs_l2,
          phrase_slugs_l1: lesson?.current_edit_id?.phrase_slugs_l1,
        })
        if (error) throw error
        const newData = data || []
        setPhrases(newData)
        setPhrasesLoading(false)
      } catch (error) {
        setPhrasesLoading(false)
        logError('fetching phrases for lesson', error)
      }
    }
    if (lesson) addLessonPhrases()
  }, [lesson])

  const license = Licenses.find(license => license.id === lesson?.current_edit_id?.license)

  const lessonBlocks = lesson?.current_edit_id?.content_l1?.split('\n***\n') || []
  const numSteps = lessonBlocks.length + 3

  const congratulationsWord = (currentLanguage.iso639_1 === 'it' && userProfile?.refer_to_as_gender === 'male') ? 'Bravo' :
    (currentLanguage.iso639_1 === 'it') ? 'Brava' :
    (currentLanguage.iso639_1 === 'fr') ? 'Bon travail' :
    'Congratulations'

  return <MainWrapper key={lesson?.id}>
    <BreadcrumbWrapper>
      <BreadcrumbItem to={`/${currentLanguage.slug_eng}`}>Home</BreadcrumbItem>
      <BreadcrumbSeparator />
      {lesson?.title_l1}
    </BreadcrumbWrapper>

    {error && <ErrorPage error={error} />}
    {loading && 'loading...'}

    <br />

    <div style={{ margin: '8vh 0'}}>
    {
      currentStepIndex === 0 ? <>
        <TwoColumns cols="1fr 1fr">
          <div>

            <LessonTitleHeader>
              <div>
                <LessonTitleH1>{lesson?.title_l1}</LessonTitleH1>
                {lesson?.title_l2 && <div style={{fontStyle: 'italic'}}>
                  {lesson.title_l2}
                  <SpeakWord wordString={lesson?.title_l2} />
                </div> }
              </div>
              <div />
            </LessonTitleHeader>
            {/* <button
              className='button'
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                if (isLoading) return
                if (isSaved) {
                  removeSaved()
                } else {
                  addSaved()
                }
              }}
            >
              {isSaved ? 'Saved' : 'Save lesson'}
              <Icon name={isLoading ? "pending" : "add_circle"} filled={isSaved} />
            </button> */}

            <br />
            <p style={{fontSize: 'var(--l)'}}>{lesson?.description_l1}</p>

            {lesson?.current_edit_id?.topics?.length && <>
              <p>
                {lesson?.current_edit_id?.topics?.map(topic => <Badge key={topic}>{topic.charAt(0).toUpperCase() + topic.slice(1)}</Badge>)}
              </p>
            </>}

            <br />
            <div style={{display: 'flex', gap: '1rem'}}>
              <AvatarUser src={lesson?.created_by?.avatar_url || '/images/conversation_avatars/noun-profile-1194176.svg'} alt="" />
              <div>
                { lesson?.created_by?.username ? <Link to={`/u/${lesson.created_by.username}`}>{lesson.created_by.username}</Link> : 'Unknown author' }
                <div style={{fontSize: 'var(--s)'}}>
                  Last edit {moment(lesson?.current_edit_id?.created_at).format("MMMM Do, YYYY")}
                  {' · '}
                  <ReportIssue lesson={lesson} />
                </div>
                <ProfileFollow userProfile={lesson?.created_by} />
              </div>
            </div>

            <br />
            <button className="button button-large button-primary" onClick={() => setCurrentStepIndex(currentStepIndex + 1)}>
              Start lesson →
            </button>

            <br />
            <br />
            <p style={{fontSize: 'var(--s)'}}>
              License: {(license && license.url) ? <a href={license.url || '#'} target="_blank" rel="noreferrer">{license.id}</a> : 'No license'}
            </p>
            {
              lesson?.current_edit_id?.attributions && 
              <p style={{fontSize: 'var(--s)'}}>Uses content from {lesson.current_edit_id.attributions.join('; ')}</p>
            }

            { userId === lesson?.created_by?.id && 
              <div>
                <Link to={`/${currentLanguage.slug_eng}/lessons/${lesson?.random_slug}/history`}>History</Link>
                {' · '}
                <Link to={`/${currentLanguage.slug_eng}/lessons/${lesson?.random_slug}/edit`}>Edit lesson</Link>
              </div>
            }
          </div>
          <div>
            <p>In this lesson...</p>
            {/* <br /> */}
            <LessonPhraseList lesson={lesson} simple />
          </div>
        </TwoColumns>
      </> :
      currentStepIndex === numSteps - 2 ? <>
        <TwoColumns cols="5fr 2fr">
          <LessonBodyWrapper>
            <div>
              <h2>Review</h2>
              {!phrasesLoading && phrases.length > 0 && <>
                <CardDeck
                  phrases={phrases}
                  phraseSource={lesson?.random_slug}
                  currentPhraseIndex={currentPhraseIndex}
                  setCurrentPhraseIndex={setCurrentPhraseIndex}
                  />
              </>}
            </div>
            <LessonNavWrapper>
              <LessonNavInner>
                <div>
                  <button className="button button-tertiary" onClick={() => setCurrentStepIndex(currentStepIndex - 1)}>Back</button>
                </div>
                <div>
                  <StepProgress current={currentStepIndex} total={numSteps} />
                </div>
                <div>
                  <button className="button button-large" onClick={() => setCurrentStepIndex(currentStepIndex + 1)}>Next</button>
                </div>
              </LessonNavInner>
            </LessonNavWrapper>
          </LessonBodyWrapper>
          <div>
          </div>
        </TwoColumns>
      </> :
      currentStepIndex === numSteps - 1 ? <>
        <TwoColumns cols="1fr 1fr">
          <div>
            <h1>{congratulationsWord}!</h1>
            <div style={{position: 'fixed', top: '0', left: '50%' }}>
              <ConfettiExplosion
                duration={3000}
                colors={[ '#333', '#666', '#999', '#bbb' ]}
              />
            </div>
            {/* <PrevNextLesson lessonSortOrder={lesson?.sort_order} /> */}
            <p>
              Lesson complete
            </p>
            <br />
            <LessonPhraseList lesson={lesson} simple />
            <br />
            <br />
            <button className="button button-large" onClick={() => navigate(`/${currentLanguage.slug_eng}`)}>
              Close and finish
            </button>
          </div>
          <div>
            <h3>Try these next</h3>
            <RelatedLessons lesson={lesson} />
          </div>
        </TwoColumns>
      </> :
      <>
        <TwoColumns cols="5fr 2fr" key={currentStepIndex}>
          <LessonBodyWrapper>
            <div>
              <LessonContent content={lessonBlocks[currentStepIndex - 1]} />
            </div>
            <LessonNavWrapper>
              <LessonNavInner>
                <div>
                  <button className="button button-tertiary" onClick={() => setCurrentStepIndex(currentStepIndex - 1)}>Back</button>
                </div>
                <div>
                  <StepProgress current={currentStepIndex} total={numSteps} />
                </div>
                <div>
                  <button className="button button-large" onClick={() => setCurrentStepIndex(currentStepIndex + 1)}>Next</button>
                </div>
              </LessonNavInner>
            </LessonNavWrapper>
          </LessonBodyWrapper>
          <div>
          </div>
        </TwoColumns>
      </>
    }
    </div>
  </MainWrapper>
}
const LessonBodyWrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`
const LessonTitleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
`
const LessonTitleH1 = styled.h1`
  margin: 0 0 .5rem;
  font-size: 2.6rem;
`
const LessonNavWrapper = styled.div`
  position: sticky;
  bottom: 0;
`
const LessonNavInner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 1rem .25rem;
  margin: 0 -.25rem;
  background: var(--bg);
  border-top: 1px solid;

  & > div {
    display: flex;
    justify-content: center;

    &:first-child {
      justify-content: flex-start;
    }
    &:last-child {
      justify-content: flex-end;
    }
  }
`
