import { useUser } from '@/context/user'

import { supabase, useSupabaseQuery } from '@/db/supabase'

export default ({ phrase }) => {

  const { userId } = useUser()

  const query = supabase
    .from('user_phrase_scores')
    .select()
    .eq('phrase_id', phrase?.id)
    .eq('created_by', userId)
  const [phraseScores, loading, error] = useSupabaseQuery(query, [userId, phrase?.id], (!userId || !phrase))

  if (!userId) return `??`
  if (loading) return '...'
  if (error) return `(Error)`
  if (!phraseScores?.length) return `(No tries yet)`
  return <>{Math.floor(phraseScores[0].percent_correct * 100)}%</>
}
