import styled from 'styled-components/macro'

export const FormQuestion = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  /* grid-template-columns: 1fr 1fr; */
  grid-gap: .5rem;
  margin-bottom: 0 0 1rem;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`
export const FormHint = styled.div`
  background: var(--badge);
  padding: .5rem 1rem;
  margin-bottom: 1rem;

  /* TODO - animate in */
  /* transition: all .2s ease-in-out; */
`
